html {
  font-size: 1rem;
  /*for using REM units*/
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.7;
  color: #222;
}