::-webkit-scrollbar {
  width: 5px;
}

body::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(180, 180, 180, 0.3);
  box-shadow: inset 0 0 6px rgba(180, 180, 180, 0.3);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: linear-gradient(transparent, $blue);
  min-height: 80px;
}

::selection {
  color: $white;
  background: $light-blue;
}

html,
body {
  scroll-behavior: smooth;
  font-size: 10px;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  line-height: 1.5;
  box-sizing: border-box;
  overflow-x: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media (max-width: 767px) {
  html,
  body {
    font-size: 8px;
  }
}

@media (min-width: 1200px) {
  html,
  body {
    font-size: 12px;
  }
}
