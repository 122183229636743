@charset "UTF-8";

/*!
  Theme Name: Saaspik
  Theme URI: http://pixelsigns.com/saaspik
  Author: PixelSigns
  Author URI: http://www.pixelsigns.co/
  Description: Software, App, SaaS landing HTML Template
  Version: 1.0.0
  License: GNU General Public License v2 or later
  License URI: http://www.gnu.org/licenses/gpl-2.0.html
  Text Domain: saaspik
  Tags: game,business,coporate,startup
  */


/*--------------------------------------------------------------
  >>> TABLE OF CONTENTS:
  ----------------------------------------------------------------
  # Base
  # Sections
    ## Page Loader
    ## Header
    ## Banner
    ## Section Title
    ## Icon Box
    ## Editor
    ## Genera Informes
    ## Revolutionize
    ## Testimonial
    ## Pricing
    ## Team
    ## Faq's
    ## Logo Carousel
    ## Call To Action
    ## Funfact
    ## Newsletter
    ## Page Banner
    ## About
    ## Service
    ## Contact Us
    ## Portfolio
    ## Blog
    ## Widget
    ## Sign
    ## Error
    ## Footer

  --------------------------------------------------------------*/


/* Fool-proof @font-face */


/* Based on http://coding.smashingmagazine.com/2013/02/14/setting-weights-and-styles-at-font-face-declaration/ */


/* Front */

/* Default link styling */
.mega-link {
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    text-decoration: none; /* Remove underline */
    transition: background-color 0.3s ease, color 0.3s ease; 
    color: #333; /* Default text color */
  }
  
  /* Hover effect */
  .mega-link:hover {
    background-color: #aed1f47c; /* Light background color on hover */
    color: #04080cec;
    padding: 10px;
    border-radius: 10px;
    width: fit-content;
  }
  

  .dropdownOnActive{
    background-color: #aed1f47c; /* Light background color on hover */
    color: #04080cec;
    padding: 10px;
    border-radius: 10px;
    width: fit-content;
  }
  
  /* Dropdown title styles */
  .drop-title {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    white-space: nowrap; /* Prevent text wrapping */
  }
  

@-webkit-keyframes slide-down {
    0% {
        opacity: 1;
    }
    90% {
        opacity: 0;
    }
}

@keyframes slide-down {
    0% {
        opacity: 1;
    }
    90% {
        opacity: 0;
    }
}

.element {
    width: 100px;
    height: 100px;
    background: black;
    -webkit-animation: slide-down 5s 3;
    animation: slide-down 5s 3;
}

.visually-hidden {
    margin: -1px;
    padding: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    clip: rect(0, 0, 0, 0);
    position: absolute;
}

body {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    line-height: 28px;
    color: #797687;
    overflow-x: hidden;
}

svg {
    display: block;
}

a,
button,
input,
textarea,
button,
select {
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

button {
    cursor: pointer;
    outline: 0;
}

input,
textarea,
select,
.form-control {
    width: 100%;
    padding: 10px 12px;
    outline: 0;
}

.form-control {
    background: #fdfafa;
    padding: 15px 30px;
    border: 1px solid #efe7e7;
    margin-bottom: 30px;
    border-radius: 30px;
    height: 60px;
}

.form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #efe7e7;
    background: #fff;
    -webkit-box-shadow: 0px 20px 40px 0px rgba(79, 35, 35, 0.1);
    box-shadow: 0px 20px 40px 0px rgba(79, 35, 35, 0.1);
}

a,
a:hover {
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Open Sans', sans-serif;
    margin-top: 0;
    font-weight: 700;
    color: #2b2350;
}


/* img {
    max-width: 100%;
    height: auto;
} */

.pr {
    position: relative;
}

.pt-7 {
    padding-top: 70px;
}

.h-100 {
    height: 100%;
}

.container-wrap {
    max-width: 95%;
    margin: 0 auto;
    border-radius: 20px;
    position: relative;
}

.container-wrap.bg-color-one {
    background-color: #f6faf8;
}

.container-wrap.bg-color-two {
    background-color: #d9e6ff;
}

.container-wrap.bg-footer-color {
    background-color: #1d2146;
}

section {
    position: relative;
    margin: 0;
}

.mw-none {
    max-width: unset !important;
}

@media (min-width: 1200px) {
    .container {
        max-width: 1200px;
    }
}

@media (min-width: 991px) {
    .pr-85 {
        padding-right: 85px;
    }
    .pl-85 {
        padding-left: 85px;
    }
    .mt-185 {
        margin-top: 185px;
    }
}

@media (max-width: 991px) {
    .mt-185 {
        margin-top: 80px;
    }
}

.section-small {
    margin-bottom: 80px;
}

.section-small .title {
    font-size: 20px;
    font-weight: 500;
}

.mt-40 {
    margin-top: 40px;
}

.mt-60 {
    margin-top: 60px;
}

.mt-180 {
    margin-top: 177px;
}

@media (max-width: 768px) {
    .mt-180 {
        margin-top: 80px;
    }
}

@media (min-width: 768px) {
    .mt-50 {
        margin-top: 50px;
    }
    .mt-20 {
        margin-top: 20px;
    }
    .mt-70 {
        margin-top: 70px;
    }
}

.section-padding {
    padding: 100px 0;
}

.no-scroll {
    overflow-y: hidden !important;
}

.container-wide {
    max-width: 1700px;
    padding: 0 15px;
    margin: 0 auto;
}

.gutters-10>[class*='col-'] {
    padding: 0 5px;
}


/*
	Flaticon icon font: Flaticon
	Creation date: 13/07/2019 13:19
*/

@font-face {
    font-family: 'Flaticon';
    src: url('../fonts/Flaticond41d.eot?#iefix') format('embedded-opentype'), url('../fonts/Flaticon.woff') format('woff'), url('../fonts/Flaticon.ttf') format('truetype'), url('../fonts/Flaticon.svg#Flaticon') format('svg');
    font-weight: normal;
    font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    @font-face {
        font-family: 'Flaticon';
        src: url('../fonts/Flaticon.svg#Flaticon') format('svg');
    }
}

.fi:before {
    display: inline-block;
    font-family: 'Flaticon';
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    line-height: 1;
    text-decoration: inherit;
    text-rendering: optimizeLegibility;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    
}

.flaticon-google-play:before {
    content: '';
}

.flaticon-apple-logo:before {
    content: '';
}

@media (max-width: 991px) {
    .pix-order-two {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;
    }
    .pix-order-one {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
    }
}

@media (max-width: 767px) {
    .pix-order-two-md {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;
    }
}


/*--------------------------------------------------------------
  ##  Buttons
  --------------------------------------------------------------*/

.pix-btn {
    padding: 8px 30px;
    background: #fa7070;
    border-radius: 30px;
    display: inline-block;
    font-size: 15px;
    color: #fff;
    font-weight: 500;
    /* -webkit-box-shadow: 0px 20px 30px 0px rgba(176, 70, 70, 0.3);
    box-shadow: 0px 20px 30px 0px rgba(176, 70, 70, 0.3); */
}

.pix-btn i {
    font-size: 20px;
    margin-left: 3px;
    line-height: 1;
    vertical-align: middle;
}

.pix-btn.submit-btn {
    border: 0;
    padding: 11px 35px;
}

.pix-btn.submit-btn:focus {
    outline: 0;
}

.pix-btn.submit-btn i {
    display: none;
}

.pix-btn.submit-btn.clicked {
    min-width: 210px;
    min-height: 50px;
}

.pix-btn.submit-btn.clicked i {
    display: block;
}

.pix-btn.submit-btn.clicked .btn-text {
    display: none;
}

.pix-btn.btn-two {
    border-radius: 30px;
    background: #f97bc5;
    -webkit-box-shadow: 0px 20px 30px 0px rgba(235, 110, 183, 0.3);
    box-shadow: 0px 20px 30px 0px rgba(235, 110, 183, 0.3);
    border: 1px solid #f97bc5;
    font-weight: 500;
    font-size: 16px;
}

.pix-btn.btn-two i {
    display: inline-block;
    vertical-align: middle;
}

.pix-btn.btn-two:hover {
    background: transparent;
    color: #f97bc5;
    border-color: #f97bc5;
}

.pix-btn.btn-two.btn-outline {
    border: 2px solid rgba(249, 123, 197, 0.5);
    color: #f97bc5;
}

.pix-btn.btn-two.btn-outline:hover {
    background: #f97bc5;
    color: #fff;
    -webkit-box-shadow: 0px 20px 30px 0px rgba(235, 110, 183, 0.3);
    box-shadow: 0px 20px 30px 0px rgba(235, 110, 183, 0.3);
}

.pix-btn.btn-round-md {
    border-radius: 10px;
}

.pix-btn.btn-large {
    padding: 16px 44px;
    font-size: 16px;
}

.pix-btn.plus-icon {
    padding: 8px 64px 8px 41px;
    position: relative;
}

.pix-btn.plus-icon i {
    margin-left: 10px;
    font-size: 20px;
    display: inline-block;
    position: absolute;
    right: 32px;
    top: 11px;
}

.pix-btn.btn-big {
    padding: 16px 47px;
    font-size: 16px;
}

.pix-btn.color-two {
    background: #7052fb;
    -webkit-box-shadow: 0px 20px 30px 0px rgba(90, 70, 176, 0.3);
    box-shadow: 0px 20px 30px 0px rgba(90, 70, 176, 0.3);
}

.pix-btn.btn-grey {
    background: #f3f3f4;
    color: #322d49;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 4px;
    padding: 11px 40px;
}

.pix-btn.btn-grey:hover {
    background: #fd4d5c;
}

.pix-btn.btn-grey.color-three:hover {
    background: #12bf7c;
    -webkit-box-shadow: 0px 10px 20px 0px rgba(11, 176, 112, 0.3);
    box-shadow: 0px 10px 20px 0px rgba(11, 176, 112, 0.3);
}

.pix-btn:hover,
.pix-btn:focus {
    color: #fff;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0;
}

.pix-btn.btn-outline {
    border: 1px solid #fa7070;
    background: transparent;
    color: #fa7070;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.pix-btn.btn-outline:hover {
    background: #fa7070;
    color: #fff;
    -webkit-box-shadow: 0px 20px 30px 0px rgba(176, 70, 70, 0.3);
    box-shadow: 0px 20px 30px 0px rgba(176, 70, 70, 0.3);
}

.pix-btn.btn-outline-two {
    border: 1px solid #7052fb;
    background: transparent;
    color: #7052fb;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.pix-btn.btn-outline-two:hover {
    background: #7052fb;
    color: #fff;
    -webkit-box-shadow: 0px 20px 30px 0px rgba(90, 70, 176, 0.3);
    box-shadow: 0px 20px 30px 0px rgba(90, 70, 176, 0.3);
}

.pix-btn.btn-light {
    background: #fff;
    color: #fa7070;
    border: 2px solid #fff;
    padding: 14px 40px;
}

.pix-btn.btn-light:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0;
}

.pix-btn.btn-light.color-two {
    color: #7052fb;
}

.pix-btn.btn-light:hover {
    background: transparent;
    color: #fff;
    border-color: #fff;
}

.pix-btn.btn-light.btn-outline {
    background: transparent;
    color: #fff;
}

.pix-btn.btn-light.btn-outline:hover {
    color: #f97bc5;
    background: #fff;
}

.pix-btn.btn-three {
    background: #fd4d5c;
    border: 1px solid #fd4d5c;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.pix-btn.btn-three:hover {
    background: transparent;
    color: #fd4d5c;
}

.pix-btn.btn-round {
    border-radius: 4px;
}

.pix-btn.btn-round-lg {
    border-radius: 10px;
    padding: 9px 22px;
    border: 2px solid #7052fb;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.pix-btn.btn-round-lg:hover {
    background: transparent;
    color: #7052fb;
}

.pix-btn.btn-four {
    background: #12bf7c;
    border: 1px solid #12bf7c;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.pix-btn.btn-four:hover {
    background: transparent;
    color: #12bf7c;
}

.pix-btn.btn-round {
    border-radius: 4px;
}

.pix-btn.btn-five {
    background: #f98e47;
    border: 2px solid #f98e47;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.pix-btn.btn-five.btn-round {
    border-radius: 6px;
}

.pix-btn.btn-five:hover {
    color: #f98e47;
    background: transparent;
}

.pix-btn.btn-six {
    background: #14aeee;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 2px solid #14aeee;
    font-size: 16px;
    font-weight: 500;
    padding: 7px 34px;
}

.pix-btn.btn-six:hover {
    background: transparent;
    color: #14aeee;
}

.pix-btn.btn-six.btn-outline {
    background: transparent;
    color: #14aeee;
}

.pix-btn.btn-six.btn-outline:hover {
    -webkit-box-shadow: none;
    box-shadow: none;
    background: #14aeee;
    color: #fff;
}

.pix-btn.btn-six.btn-light {
    background: #fff;
    color: #14aeee;
    border-color: #fff;
}

.pix-btn.btn-six.btn-light:hover {
    background: transparent;
    color: #fff;
}

.pix-btn.btn-six.btn-outline {
    background: transparent;
    color: #fff;
}

.pix-btn.btn-six.btn-outline:hover {
    background: #fff;
    color: #14aeee;
}

.btn-underline {
    color: #797687;
    font-size: 15px;
    position: relative;
    padding-bottom: 3px;
    font-weight: 500;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    line-height: 15px;
    max-width: 140px;
    margin: 0 auto;
}

.btn-underline:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    bottom: 0;
    background: #797687;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.btn-underline i {
    display: inline-block;
    margin-left: 10px;
}

.btn-underline:hover {
    color: #fa7070;
}

.btn-underline:hover:before {
    background: #fa7070;
}

.play-btn {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #fff;
    font-weight: 600;
}

.play-btn i {
    height: 60px;
    width: 60px;
    text-align: center;
    line-height: 60px;
    color: #fa7070;
    background: #fff;
    border-radius: 50%;
    margin-right: 10px;
    font-size: 30px;
    text-shadow: 0px 10px 10px rgba(176, 70, 70, 0.4);
}

.play-btn:hover {
    color: #fff;
}

.play-btn.large i {
    height: 80px;
    width: 80px;
    line-height: 82px;
    color: #f97bc5;
    font-size: 40px;
}

.play-btn.play-btn-outline {
    background: transparent;
    color: #12bf7c;
}

.play-btn.play-btn-outline i {
    border: 2px solid #12bf7c;
    color: #12bf7c;
    text-shadow: 0px 6px 10px rgba(11, 176, 112, 0.4);
    height: 52px;
    width: 52px;
    line-height: 49px;
}

.play-btn-two {
    position: relative;
}

.play-btn-two i:before {
    position: relative;
    z-index: 222;
}

.play-btn-two i:after {
    position: absolute;
    content: '';
    height: 50px;
    width: 50px;
    background: #e6e7eb;
    border-radius: 50%;
    top: 5px;
    left: 5px;
    z-index: 1;
}

.app-btn {
    padding: 10px 20px;
    border-radius: 30px;
    color: #7052fb;
    display: inline-block;
    font-size: 18px;
    border: 2px solid #7052fb;
}

.app-btn i {
    color: #7052fb;
    font-size: 20px;
    vertical-align: -2px;
    margin-right: 4px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.app-btn:hover {
    color: #fff;
    background: #7052fb;
    -webkit-box-shadow: 0px 20px 30px 0px rgba(67, 37, 204, 0.2);
    box-shadow: 0px 20px 30px 0px rgba(67, 37, 204, 0.2);
}

.app-btn:hover i {
    color: #fff;
}

.app-btn.btn-active {
    background: transparent;
    color: #fff;
    -webkit-box-shadow: 0px 20px 30px 0px rgba(67, 37, 204, 0.2);
    box-shadow: 0px 20px 30px 0px rgba(67, 37, 204, 0.2);
    background: #7052fb;
}

.app-btn.btn-active i {
    color: #fff;
}

.app-btn.btn-active:hover {
    background: transparent;
    color: #7052fb;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.app-btn.btn-active:hover i {
    color: #7052fb;
}

.app-btn-two {
    background: #1a133b;
    color: #fff;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    padding: 10px 25px;
    font-size: 18px;
    border-radius: 6px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.app-btn-two.btn-light {
    background: #f3f3f4;
    color: #1a133b;
}

.app-btn-two.btn-light .top-text {
    color: #76747f;
}

.app-btn-two span {
    display: block;
}

.app-btn-two i {
    margin-right: 15px;
    font-size: 28px;
}

.app-btn-two .btn-text {
    font-weight: 500;
    line-height: 20px;
}

.app-btn-two .btn-text .text-top {
    font-size: 14px;
    font-weight: 400;
}

.app-btn-two:hover {
    background: #12bf7c;
    color: #fff;
}

.app-btn-two.app-travel {
    border-radius: 10px;
    padding: 11px 23px;
    background: #7052fb;
}

.app-btn-two.app-travel i {
    margin-right: 10px;
}

.app-btn-two.app-travel .btn-text .text-top {
    font-size: 12px;
    line-height: 1;
}

.app-btn-two.app-travel span {
    font-size: 16px;
}

.app-btn-two.app-travel:hover {
    background: #5b39fa;
}


/* Back To Top */

.return-to-top {
    position: fixed;
    bottom: -30px;
    right: 20px;
    width: 42px;
    height: 42px;
    line-height: 42px;
    text-align: center;
    cursor: pointer;
    z-index: 998;
    border-radius: 50%;
    opacity: 0;
    -webkit-transition: bottom 0.5s ease, opacity 0.5s ease;
    -o-transition: bottom 0.5s ease, opacity 0.5s ease;
    transition: bottom 0.5s ease, opacity 0.5s ease;
}

.return-to-top:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    opacity: 1;
    display: block;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    border-radius: inherit;
    -webkit-transition: opacity 0.6s ease, -webkit-transform 0.5s ease;
    transition: opacity 0.6s ease, -webkit-transform 0.5s ease;
    -o-transition: transform 0.5s ease, opacity 0.6s ease;
    transition: transform 0.5s ease, opacity 0.6s ease;
    transition: transform 0.5s ease, opacity 0.6s ease, -webkit-transform 0.5s ease;
}

.return-to-top:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    background: rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 0px 0px 0px transparent;
    box-shadow: 0px 0px 0px 0px transparent;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    border-radius: inherit;
}

.return-to-top>i {
    position: relative;
    overflow: hidden;
    font-size: 12px;
    width: inherit;
    height: inherit;
    line-height: inherit;
    display: block;
    color: transparent;
    text-shadow: 0px 0px #fff, 0px 50px #fff;
    -webkit-transition: text-shadow 0.2s ease;
    -o-transition: text-shadow 0.2s ease;
    transition: text-shadow 0.2s ease;
    z-index: 1;
}

.return-to-top:hover:after {
    -webkit-transform: scale(1.07);
    -ms-transform: scale(1.07);
    transform: scale(1.07);
    background: #fa7070;
    -webkit-box-shadow: 0px 10px 20px 8px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 10px 20px 8px rgba(0, 0, 0, 0.15);
}

.return-to-top:hover>i {
    text-shadow: 0px -50px #fff, 0px 0px #fff;
}

.return-to-top.back-top {
    bottom: 20px;
    opacity: 1;
}


/*--------------------------------------------------------------
  ##  List
  --------------------------------------------------------------*/

.list-items {
    margin: 0;
    padding: 0;
    list-style: none;
}

.list-items li {
    font-size: 16px;
    line-height: 36px;
    font-weight: 500;
    color: #2b2350;
    padding-left: 36px;
    position: relative;
}

.list-items li:before {
    content: 'N';
    font-family: eleganticons;
    position: absolute;
    left: 0;
    top: 0;
    color: #fa7070;
}

.list-items.color-two li:before {
    color: #7052fb;
}

.list-items.color-eight {
    margin-bottom: 30px;
}

.list-items.color-eight li {
    color: #14aeee;
}

.list-items.color-eight li:before {
    color: #14aeee;
}

.list-items.list-with-icon li:before {
    display: none;
}

.list-items.list-with-icon li i {
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    color: #7052fb;
}

.list-items.list-icon-arrow li {
    padding-left: 25px;
}

.list-items.list-icon-arrow li:before {
    content: '$';
    color: #12bf7c;
}


/*--------------------------------------------------------------
  ##  Froms
  --------------------------------------------------------------*/

input,
textarea,
select {
    background: #fdfafa;
    padding: 15px 30px;
    border: 1px solid #efe7e7;
    margin-bottom: 30px;
    border-radius: 30px;
}

input:focus,
textarea:focus,
select:focus {
    background: #fff;
    -webkit-box-shadow: 0px 20px 40px 0px rgba(79, 35, 35, 0.1);
    box-shadow: 0px 20px 40px 0px rgba(79, 35, 35, 0.1);
}

textarea {
    height: 200px;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-image: none;
}


/* Remove IE arrow */

select::-ms-expand {
    display: none;
}


/* Custom Select */

.pix-select {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

select {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    color: #000;
    cursor: pointer;
}


/* Arrow */

.pix-select:after {
    content: '3';
    font-family: eleganticons;
    position: absolute;
    top: 19%;
    right: 0;
    padding-right: 15px;
    cursor: pointer;
    pointer-events: none;
    -webkit-transition: 0.25s all ease;
    -o-transition: 0.25s all ease;
    transition: 0.25s all ease;
    font-size: 24px;
}


/* Transition */

.pix-select:hover:after {
    color: #fa7070;
}


/*--------------------------------------------------------------
##  Animation
--------------------------------------------------------------*/

@-webkit-keyframes zoom {
    from {
        -webkit-transform: scale(1) translate(0px);
    }
    to {
        -webkit-transform: scale(1.03) translate(0px);
    }
}

@keyframes zoom {
    from {
        -webkit-transform: scale(1) translate(0px);
    }
    to {
        -webkit-transform: scale(1.03) translate(0px);
    }
}

@-webkit-keyframes zoommd {
    0% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }
    100% {
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
    }
}

@keyframes zoommd {
    0% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }
    100% {
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
    }
}

@-webkit-keyframes zoomin {
    0% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
        opacity: 0;
    }
    50% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes zoomin {
    0% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
        opacity: 0;
    }
    50% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@-webkit-keyframes bounce {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(40px);
        transform: translateY(40px);
    }
}

@keyframes bounce {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(40px);
        transform: translateY(40px);
    }
}

@-webkit-keyframes movexy {
    0% {
        -webkit-transform: translateX(-10px) scale(0.9);
        transform: translateX(-10px) scale(0.9);
    }
    100% {
        -webkit-transform: translateX(30px) scale(1.3) translateY(10px);
        transform: translateX(30px) scale(1.3) translateY(10px);
    }
}

@keyframes movexy {
    0% {
        -webkit-transform: translateX(-10px) scale(0.9);
        transform: translateX(-10px) scale(0.9);
    }
    100% {
        -webkit-transform: translateX(30px) scale(1.3) translateY(10px);
        transform: translateX(30px) scale(1.3) translateY(10px);
    }
}

@-webkit-keyframes wave {
    0% {
        -webkit-transform: rotateZ(0deg) translate3d(0, 1%, 0) rotateZ(0deg);
        transform: rotateZ(0deg) translate3d(0, 1%, 0) rotateZ(0deg);
    }
    100% {
        -webkit-transform: rotateZ(360deg) translate3d(0, 1%, 0) rotateZ(-360deg);
        transform: rotateZ(360deg) translate3d(0, 1%, 0) rotateZ(-360deg);
    }
}

@keyframes wave {
    0% {
        -webkit-transform: rotateZ(0deg) translate3d(0, 1%, 0) rotateZ(0deg);
        transform: rotateZ(0deg) translate3d(0, 1%, 0) rotateZ(0deg);
    }
    100% {
        -webkit-transform: rotateZ(360deg) translate3d(0, 1%, 0) rotateZ(-360deg);
        transform: rotateZ(360deg) translate3d(0, 1%, 0) rotateZ(-360deg);
    }
}

@-webkit-keyframes pixFade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes pixFade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes pixFadeUp {
    0% {
        opacity: 0;
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes pixFadeUp {
    0% {
        opacity: 0;
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@-webkit-keyframes pixFadeDown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes pixFadeDown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@-webkit-keyframes pixFadeLeft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(20px);
        transform: translateX(20px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

@keyframes pixFadeLeft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(20px);
        transform: translateX(20px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

@-webkit-keyframes pixFadeRight {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-20px);
        transform: translateX(-20px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

@keyframes pixFadeRight {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-20px);
        transform: translateX(-20px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

@-webkit-keyframes pixZoomIn {
    0% {
        opacity: 0;
        -webkit-transform: scale(0.7);
        transform: scale(0.7);
    }
    100% {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes pixZoomIn {
    0% {
        opacity: 0;
        -webkit-transform: scale(0.7);
        transform: scale(0.7);
    }
    100% {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@-webkit-keyframes pixBounceIn {
    0% {
        -webkit-transform: scale(0.1);
        transform: scale(0.1);
        opacity: 0;
    }
    60% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes pixBounceIn {
    0% {
        -webkit-transform: scale(0.1);
        transform: scale(0.1);
        opacity: 0;
    }
    60% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.pixFade {
    -webkit-animation-name: pixFade;
    animation-name: pixFade;
}

.pixFadeUp {
    -webkit-animation-name: pixFadeUp;
    animation-name: pixFadeUp;
}

.pixFadeDown {
    -webkit-animation-name: pixFadeDown;
    animation-name: pixFadeDown;
}

.pixFadeLeft {
    -webkit-animation-name: pixFadeLeft;
    animation-name: pixFadeLeft;
}

.pixFadeRight {
    -webkit-animation-name: pixFadeRight;
    animation-name: pixFadeRight;
}

.pixZoomIn {
    -webkit-animation-name: pixZoomIn;
    animation-name: pixZoomIn;
}

.pixBounceIn {
    -webkit-animation-name: pixBounceIn;
    animation-name: pixBounceIn;
}

.zoomIn {
    -webkit-animation-name: zoomin;
    animation-name: zoomin;
}

@-webkit-keyframes jumping {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 0;
    }
    50% {
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 0;
    }
}

@keyframes jumping {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 0;
    }
    50% {
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 0;
    }
}

@-webkit-keyframes jump {
    0% {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
    }
    50% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
    }
    100% {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
    }
}

@keyframes jump {
    0% {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
    }
    50% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
    }
    100% {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
    }
}

@-webkit-keyframes stickySlideDown {
    from {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
    }
    to {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes stickySlideDown {
    from {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
    }
    to {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@-webkit-keyframes moveclouds {
    0% {
        -webkit-transform: translate3d(-10px, -10px, 0);
        transform: translate3d(-10px, -10px, 0);
        opacity: 1;
    }
    50% {
        -webkit-transform: translate3d(-20px, 20px, 0);
        transform: translate3d(-20px, 20px, 0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translate3d(30px, 0px, 0);
        transform: translate3d(30px, 0px, 0);
        opacity: 1;
    }
}

@keyframes moveclouds {
    0% {
        -webkit-transform: translate3d(-10px, -10px, 0);
        transform: translate3d(-10px, -10px, 0);
        opacity: 1;
    }
    50% {
        -webkit-transform: translate3d(-20px, 20px, 0);
        transform: translate3d(-20px, 20px, 0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translate3d(30px, 0px, 0);
        transform: translate3d(30px, 0px, 0);
        opacity: 1;
    }
}

@-webkit-keyframes pulse {
    0% {
        width: 82px;
        height: 82px;
        opacity: 1;
    }
    50% {
        opacity: 1;
    }
    100% {
        height: 150px;
        width: 150px;
        opacity: 0;
    }
}

@keyframes pulse {
    0% {
        width: 82px;
        height: 82px;
        opacity: 1;
    }
    50% {
        opacity: 1;
    }
    100% {
        height: 150px;
        width: 150px;
        opacity: 0;
    }
}

@-webkit-keyframes animationFramesLeft {
    0% {
        -webkit-transform: translate(0px, 0px) rotate(0deg);
        transform: translate(0px, 0px) rotate(0deg);
    }
    20% {
        -webkit-transform: translate(-73px, 1px) rotate(-36deg);
        transform: translate(-73px, 1px) rotate(-36deg);
    }
    40% {
        -webkit-transform: translate(-141px, -72px) rotate(-72deg);
        transform: translate(-141px, -72px) rotate(-72deg);
    }
    60% {
        -webkit-transform: translate(-83px, -122px) rotate(-108deg);
        transform: translate(-83px, -122px) rotate(-108deg);
    }
    80% {
        -webkit-transform: translate(40px, -72px) rotate(-144deg);
        transform: translate(40px, -72px) rotate(-144deg);
    }
    100% {
        -webkit-transform: translate(0px, 0px) rotate(0deg);
        transform: translate(0px, 0px) rotate(0deg);
    }
}

@keyframes animationFramesLeft {
    0% {
        -webkit-transform: translate(0px, 0px) rotate(0deg);
        transform: translate(0px, 0px) rotate(0deg);
    }
    20% {
        -webkit-transform: translate(-73px, 1px) rotate(-36deg);
        transform: translate(-73px, 1px) rotate(-36deg);
    }
    40% {
        -webkit-transform: translate(-141px, -72px) rotate(-72deg);
        transform: translate(-141px, -72px) rotate(-72deg);
    }
    60% {
        -webkit-transform: translate(-83px, -122px) rotate(-108deg);
        transform: translate(-83px, -122px) rotate(-108deg);
    }
    80% {
        -webkit-transform: translate(40px, -72px) rotate(-144deg);
        transform: translate(40px, -72px) rotate(-144deg);
    }
    100% {
        -webkit-transform: translate(0px, 0px) rotate(0deg);
        transform: translate(0px, 0px) rotate(0deg);
    }
}

@-webkit-keyframes animationFramesRight {
    0% {
        -webkit-transform: translate(0px, 0px) rotate(0deg);
        transform: translate(0px, 0px) rotate(0deg);
    }
    20% {
        -webkit-transform: translate(73px, 1px) rotate(36deg);
        transform: translate(73px, 1px) rotate(36deg);
    }
    40% {
        -webkit-transform: translate(141px, 72px) rotate(72deg);
        transform: translate(141px, 72px) rotate(72deg);
    }
    60% {
        -webkit-transform: translate(83px, 122px) rotate(108deg);
        transform: translate(83px, 122px) rotate(108deg);
    }
    80% {
        -webkit-transform: translate(-40px, 72px) rotate(144deg);
        transform: translate(-40px, 72px) rotate(144deg);
    }
    100% {
        -webkit-transform: translate(0px, 0px) rotate(0deg);
        transform: translate(0px, 0px) rotate(0deg);
    }
}

@keyframes animationFramesRight {
    0% {
        -webkit-transform: translate(0px, 0px) rotate(0deg);
        transform: translate(0px, 0px) rotate(0deg);
    }
    20% {
        -webkit-transform: translate(73px, 1px) rotate(36deg);
        transform: translate(73px, 1px) rotate(36deg);
    }
    40% {
        -webkit-transform: translate(141px, 72px) rotate(72deg);
        transform: translate(141px, 72px) rotate(72deg);
    }
    60% {
        -webkit-transform: translate(83px, 122px) rotate(108deg);
        transform: translate(83px, 122px) rotate(108deg);
    }
    80% {
        -webkit-transform: translate(-40px, 72px) rotate(144deg);
        transform: translate(-40px, 72px) rotate(144deg);
    }
    100% {
        -webkit-transform: translate(0px, 0px) rotate(0deg);
        transform: translate(0px, 0px) rotate(0deg);
    }
}

@keyframes wave {
    0% {
        -webkit-transform: rotateZ(0deg) translate3d(0, 3%, 0) rotateZ(0deg);
        transform: rotateZ(0deg) translate3d(0, 3%, 0) rotateZ(0deg);
    }
    100% {
        -webkit-transform: rotateZ(360deg) translate3d(0, 3%, 0) rotateZ(-360deg);
        transform: rotateZ(360deg) translate3d(0, 3%, 0) rotateZ(-360deg);
    }
}

@-webkit-keyframes waveRote {
    0% {
        -webkit-transform: rotateZ(0deg) rotate(0deg) translate3d(0, 1%, 0) rotateZ(0deg);
        transform: rotateZ(0deg) rotate(0deg) translate3d(0, 1%, 0) rotateZ(0deg);
    }
    100% {
        -webkit-transform: rotateZ(360deg) rotate(45deg) translate3d(0, 1%, 0) rotateZ(-360deg);
        transform: rotateZ(360deg) rotate(45deg) translate3d(0, 1%, 0) rotateZ(-360deg);
    }
}

@keyframes waveRote {
    0% {
        -webkit-transform: rotateZ(0deg) rotate(0deg) translate3d(0, 1%, 0) rotateZ(0deg);
        transform: rotateZ(0deg) rotate(0deg) translate3d(0, 1%, 0) rotateZ(0deg);
    }
    100% {
        -webkit-transform: rotateZ(360deg) rotate(45deg) translate3d(0, 1%, 0) rotateZ(-360deg);
        transform: rotateZ(360deg) rotate(45deg) translate3d(0, 1%, 0) rotateZ(-360deg);
    }
}

@-webkit-keyframes animateRight {
    49% {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
    }
    50% {
        opacity: 0;
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
    51% {
        opacity: 1;
    }
}

@keyframes animateRight {
    49% {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
    }
    50% {
        opacity: 0;
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
    51% {
        opacity: 1;
    }
}

@-webkit-keyframes animationFramesOne {
    0% {
        -webkit-transform: translate(0px, 0px) rotate(0deg);
        transform: translate(0px, 0px) rotate(0deg);
    }
    20% {
        -webkit-transform: translate(73px, -1px) rotate(36deg);
        transform: translate(73px, -1px) rotate(36deg);
    }
    40% {
        -webkit-transform: translate(141px, 72px) rotate(72deg);
        transform: translate(141px, 72px) rotate(72deg);
    }
    60% {
        -webkit-transform: translate(83px, 122px) rotate(108deg);
        transform: translate(83px, 122px) rotate(108deg);
    }
    80% {
        -webkit-transform: translate(-40px, 72px) rotate(144deg);
        transform: translate(-40px, 72px) rotate(144deg);
    }
    100% {
        -webkit-transform: translate(0px, 0px) rotate(0deg);
        transform: translate(0px, 0px) rotate(0deg);
    }
}

@keyframes animationFramesOne {
    0% {
        -webkit-transform: translate(0px, 0px) rotate(0deg);
        transform: translate(0px, 0px) rotate(0deg);
    }
    20% {
        -webkit-transform: translate(73px, -1px) rotate(36deg);
        transform: translate(73px, -1px) rotate(36deg);
    }
    40% {
        -webkit-transform: translate(141px, 72px) rotate(72deg);
        transform: translate(141px, 72px) rotate(72deg);
    }
    60% {
        -webkit-transform: translate(83px, 122px) rotate(108deg);
        transform: translate(83px, 122px) rotate(108deg);
    }
    80% {
        -webkit-transform: translate(-40px, 72px) rotate(144deg);
        transform: translate(-40px, 72px) rotate(144deg);
    }
    100% {
        -webkit-transform: translate(0px, 0px) rotate(0deg);
        transform: translate(0px, 0px) rotate(0deg);
    }
}

@-webkit-keyframes animationFramesTwo {
    0% {
        -webkit-transform: translate(0px, 0px) rotate(0deg) scale(1);
        transform: translate(0px, 0px) rotate(0deg) scale(1);
    }
    20% {
        -webkit-transform: translate(73px, -1px) rotate(36deg) scale(0.9);
        transform: translate(73px, -1px) rotate(36deg) scale(0.9);
    }
    40% {
        -webkit-transform: translate(141px, 72px) rotate(72deg) scale(1);
        transform: translate(141px, 72px) rotate(72deg) scale(1);
    }
    60% {
        -webkit-transform: translate(83px, 122px) rotate(108deg) scale(1.2);
        transform: translate(83px, 122px) rotate(108deg) scale(1.2);
    }
    80% {
        -webkit-transform: translate(-40px, 72px) rotate(144deg) scale(1.1);
        transform: translate(-40px, 72px) rotate(144deg) scale(1.1);
    }
    100% {
        -webkit-transform: translate(0px, 0px) rotate(0deg) scale(1);
        transform: translate(0px, 0px) rotate(0deg) scale(1);
    }
}

@keyframes animationFramesTwo {
    0% {
        -webkit-transform: translate(0px, 0px) rotate(0deg) scale(1);
        transform: translate(0px, 0px) rotate(0deg) scale(1);
    }
    20% {
        -webkit-transform: translate(73px, -1px) rotate(36deg) scale(0.9);
        transform: translate(73px, -1px) rotate(36deg) scale(0.9);
    }
    40% {
        -webkit-transform: translate(141px, 72px) rotate(72deg) scale(1);
        transform: translate(141px, 72px) rotate(72deg) scale(1);
    }
    60% {
        -webkit-transform: translate(83px, 122px) rotate(108deg) scale(1.2);
        transform: translate(83px, 122px) rotate(108deg) scale(1.2);
    }
    80% {
        -webkit-transform: translate(-40px, 72px) rotate(144deg) scale(1.1);
        transform: translate(-40px, 72px) rotate(144deg) scale(1.1);
    }
    100% {
        -webkit-transform: translate(0px, 0px) rotate(0deg) scale(1);
        transform: translate(0px, 0px) rotate(0deg) scale(1);
    }
}

@-webkit-keyframes animationFramesThree {
    0% {
        -webkit-transform: translate(165px, -179px);
        transform: translate(165px, -179px);
    }
    100% {
        -webkit-transform: translate(-346px, 617px);
        transform: translate(-346px, 617px);
    }
}

@keyframes animationFramesThree {
    0% {
        -webkit-transform: translate(165px, -179px);
        transform: translate(165px, -179px);
    }
    100% {
        -webkit-transform: translate(-346px, 617px);
        transform: translate(-346px, 617px);
    }
}

@-webkit-keyframes animationFramesFour {
    0% {
        -webkit-transform: translate(-300px, 151px) rotate(0deg);
        transform: translate(-300px, 151px) rotate(0deg);
    }
    100% {
        -webkit-transform: translate(251px, -200px) rotate(180deg);
        transform: translate(251px, -200px) rotate(180deg);
    }
}

@keyframes animationFramesFour {
    0% {
        -webkit-transform: translate(-300px, 151px) rotate(0deg);
        transform: translate(-300px, 151px) rotate(0deg);
    }
    100% {
        -webkit-transform: translate(251px, -200px) rotate(180deg);
        transform: translate(251px, -200px) rotate(180deg);
    }
}

@-webkit-keyframes animationFramesFive {
    0% {
        -webkit-transform: translate(61px, -99px) rotate(0deg);
        transform: translate(61px, -99px) rotate(0deg);
    }
    21% {
        -webkit-transform: translate(4px, -190px) rotate(38deg);
        transform: translate(4px, -190px) rotate(38deg);
    }
    41% {
        -webkit-transform: translate(-139px, -200px) rotate(74deg);
        transform: translate(-139px, -200px) rotate(74deg);
    }
    60% {
        -webkit-transform: translate(-263px, -164px) rotate(108deg);
        transform: translate(-263px, -164px) rotate(108deg);
    }
    80% {
        -webkit-transform: translate(-195px, -49px) rotate(144deg);
        transform: translate(-195px, -49px) rotate(144deg);
    }
    100% {
        -webkit-transform: translate(-1px, 0px) rotate(180deg);
        transform: translate(-1px, 0px) rotate(180deg);
    }
}

@keyframes animationFramesFive {
    0% {
        -webkit-transform: translate(61px, -99px) rotate(0deg);
        transform: translate(61px, -99px) rotate(0deg);
    }
    21% {
        -webkit-transform: translate(4px, -190px) rotate(38deg);
        transform: translate(4px, -190px) rotate(38deg);
    }
    41% {
        -webkit-transform: translate(-139px, -200px) rotate(74deg);
        transform: translate(-139px, -200px) rotate(74deg);
    }
    60% {
        -webkit-transform: translate(-263px, -164px) rotate(108deg);
        transform: translate(-263px, -164px) rotate(108deg);
    }
    80% {
        -webkit-transform: translate(-195px, -49px) rotate(144deg);
        transform: translate(-195px, -49px) rotate(144deg);
    }
    100% {
        -webkit-transform: translate(-1px, 0px) rotate(180deg);
        transform: translate(-1px, 0px) rotate(180deg);
    }
}


/*--------------------------------------------------------------
  ##  Page Loader
  --------------------------------------------------------------*/

.page-loader {
    background: #fff;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99999999;
}

.page-loader .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.page-loader svg {
    display: none;
}

.blobs {
    -webkit-filter: url(#goo);
    filter: url(#goo);
    width: 300px;
    height: 300px;
    position: relative;
    overflow: hidden;
    border-radius: 70px;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.blobs .blob-center {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    position: absolute;
    background: #fa7070;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 30px;
    -webkit-transform-origin: left top;
    -ms-transform-origin: left top;
    transform-origin: left top;
    -webkit-transform: scale(0.9) translate(-50%, -50%);
    -ms-transform: scale(0.9) translate(-50%, -50%);
    transform: scale(0.9) translate(-50%, -50%);
    -webkit-animation: blob-grow linear 3.4s infinite;
    animation: blob-grow linear 3.4s infinite;
    border-radius: 50%;
    -webkit-box-shadow: 0 -10px 40px -5px #fa7070;
    box-shadow: 0 -10px 40px -5px #fa7070;
}

.blob {
    position: absolute;
    background: #fa7070;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    -webkit-animation: blobs ease-out 3.4s infinite;
    animation: blobs ease-out 3.4s infinite;
    -webkit-transform: scale(0.9) translate(-50%, -50%);
    -ms-transform: scale(0.9) translate(-50%, -50%);
    transform: scale(0.9) translate(-50%, -50%);
    -webkit-transform-origin: center top;
    -ms-transform-origin: center top;
    transform-origin: center top;
    opacity: 0;
}

.blob:nth-child(1) {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
}

.blob:nth-child(2) {
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
}

.blob:nth-child(3) {
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
}

.blob:nth-child(4) {
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
}

.blob:nth-child(5) {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
}

@-webkit-keyframes blobs {
    0% {
        opacity: 0;
        -webkit-transform: scale(0) translate(calc(-330px - 50%), -50%);
        transform: scale(0) translate(calc(-330px - 50%), -50%);
    }
    1% {
        opacity: 1;
    }
    35%,
    65% {
        opacity: 1;
        -webkit-transform: scale(0.9) translate(-50%, -50%);
        transform: scale(0.9) translate(-50%, -50%);
    }
    99% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        -webkit-transform: scale(0) translate(calc(330px - 50%), -50%);
        transform: scale(0) translate(calc(330px - 50%), -50%);
    }
}

@keyframes blobs {
    0% {
        opacity: 0;
        -webkit-transform: scale(0) translate(calc(-330px - 50%), -50%);
        transform: scale(0) translate(calc(-330px - 50%), -50%);
    }
    1% {
        opacity: 1;
    }
    35%,
    65% {
        opacity: 1;
        -webkit-transform: scale(0.9) translate(-50%, -50%);
        transform: scale(0.9) translate(-50%, -50%);
    }
    99% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        -webkit-transform: scale(0) translate(calc(330px - 50%), -50%);
        transform: scale(0) translate(calc(330px - 50%), -50%);
    }
}

@-webkit-keyframes blob-grow {
    0%,
    39% {
        -webkit-transform: scale(0) translate(-50%, -50%);
        transform: scale(0) translate(-50%, -50%);
    }
    40%,
    42% {
        -webkit-transform: scale(1, 0.9) translate(-50%, -50%);
        transform: scale(1, 0.9) translate(-50%, -50%);
    }
    43%,
    44% {
        -webkit-transform: scale(1.2, 1.1) translate(-50%, -50%);
        transform: scale(1.2, 1.1) translate(-50%, -50%);
    }
    45%,
    46% {
        -webkit-transform: scale(1.3, 1.2) translate(-50%, -50%);
        transform: scale(1.3, 1.2) translate(-50%, -50%);
    }
    47%,
    48% {
        -webkit-transform: scale(1.4, 1.3) translate(-50%, -50%);
        transform: scale(1.4, 1.3) translate(-50%, -50%);
    }
    52% {
        -webkit-transform: scale(1.5, 1.4) translate(-50%, -50%);
        transform: scale(1.5, 1.4) translate(-50%, -50%);
    }
    54% {
        -webkit-transform: scale(1.7, 1.6) translate(-50%, -50%);
        transform: scale(1.7, 1.6) translate(-50%, -50%);
    }
    58% {
        -webkit-transform: scale(1.8, 1.7) translate(-50%, -50%);
        transform: scale(1.8, 1.7) translate(-50%, -50%);
    }
    68%,
    70% {
        -webkit-transform: scale(1.7, 1.5) translate(-50%, -50%);
        transform: scale(1.7, 1.5) translate(-50%, -50%);
    }
    78% {
        -webkit-transform: scale(1.6, 1.4) translate(-50%, -50%);
        transform: scale(1.6, 1.4) translate(-50%, -50%);
    }
    80%,
    81% {
        -webkit-transform: scale(1.5, 1.4) translate(-50%, -50%);
        transform: scale(1.5, 1.4) translate(-50%, -50%);
    }
    82%,
    83% {
        -webkit-transform: scale(1.4, 1.3) translate(-50%, -50%);
        transform: scale(1.4, 1.3) translate(-50%, -50%);
    }
    84%,
    85% {
        -webkit-transform: scale(1.3, 1.2) translate(-50%, -50%);
        transform: scale(1.3, 1.2) translate(-50%, -50%);
    }
    86%,
    87% {
        -webkit-transform: scale(1.2, 1.1) translate(-50%, -50%);
        transform: scale(1.2, 1.1) translate(-50%, -50%);
    }
    90%,
    91% {
        -webkit-transform: scale(1, 0.9) translate(-50%, -50%);
        transform: scale(1, 0.9) translate(-50%, -50%);
    }
    92%,
    100% {
        -webkit-transform: scale(0) translate(-50%, -50%);
        transform: scale(0) translate(-50%, -50%);
    }
}

@keyframes blob-grow {
    0%,
    39% {
        -webkit-transform: scale(0) translate(-50%, -50%);
        transform: scale(0) translate(-50%, -50%);
    }
    40%,
    42% {
        -webkit-transform: scale(1, 0.9) translate(-50%, -50%);
        transform: scale(1, 0.9) translate(-50%, -50%);
    }
    43%,
    44% {
        -webkit-transform: scale(1.2, 1.1) translate(-50%, -50%);
        transform: scale(1.2, 1.1) translate(-50%, -50%);
    }
    45%,
    46% {
        -webkit-transform: scale(1.3, 1.2) translate(-50%, -50%);
        transform: scale(1.3, 1.2) translate(-50%, -50%);
    }
    47%,
    48% {
        -webkit-transform: scale(1.4, 1.3) translate(-50%, -50%);
        transform: scale(1.4, 1.3) translate(-50%, -50%);
    }
    52% {
        -webkit-transform: scale(1.5, 1.4) translate(-50%, -50%);
        transform: scale(1.5, 1.4) translate(-50%, -50%);
    }
    54% {
        -webkit-transform: scale(1.7, 1.6) translate(-50%, -50%);
        transform: scale(1.7, 1.6) translate(-50%, -50%);
    }
    58% {
        -webkit-transform: scale(1.8, 1.7) translate(-50%, -50%);
        transform: scale(1.8, 1.7) translate(-50%, -50%);
    }
    68%,
    70% {
        -webkit-transform: scale(1.7, 1.5) translate(-50%, -50%);
        transform: scale(1.7, 1.5) translate(-50%, -50%);
    }
    78% {
        -webkit-transform: scale(1.6, 1.4) translate(-50%, -50%);
        transform: scale(1.6, 1.4) translate(-50%, -50%);
    }
    80%,
    81% {
        -webkit-transform: scale(1.5, 1.4) translate(-50%, -50%);
        transform: scale(1.5, 1.4) translate(-50%, -50%);
    }
    82%,
    83% {
        -webkit-transform: scale(1.4, 1.3) translate(-50%, -50%);
        transform: scale(1.4, 1.3) translate(-50%, -50%);
    }
    84%,
    85% {
        -webkit-transform: scale(1.3, 1.2) translate(-50%, -50%);
        transform: scale(1.3, 1.2) translate(-50%, -50%);
    }
    86%,
    87% {
        -webkit-transform: scale(1.2, 1.1) translate(-50%, -50%);
        transform: scale(1.2, 1.1) translate(-50%, -50%);
    }
    90%,
    91% {
        -webkit-transform: scale(1, 0.9) translate(-50%, -50%);
        transform: scale(1, 0.9) translate(-50%, -50%);
    }
    92%,
    100% {
        -webkit-transform: scale(0) translate(-50%, -50%);
        transform: scale(0) translate(-50%, -50%);
    }
}


/*--------------------------------------------------------------
  ##  Header
  --------------------------------------------------------------*/

.site-header {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 9999;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.site-header .header-inner {
    position: relative;
}

.site-header .header-inner .site-logo a {
    display: block;
    max-width: 150px;
}

.site-header .header-inner .site-logo a img {
    width: 100%;
}

.site-header .header-inner .site-logo a .sticky-logo {
    display: none;
}

.site-header .header-inner .site-nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.site-header .header-inner .site-nav .menu-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
}

@media (max-width: 991px) {
    .site-header .header-inner .site-nav .menu-wrapper {
        display: block;
    }
}

.site-header .header-inner .site-nav .menu-wrapper:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

.site-header .header-inner .site-nav .nav-right {
    margin-left: 50px;
}

.site-header .header-inner .site-nav .nav-right .nav-btn {
    background: #fff;
    -webkit-box-shadow: 0px 20px 30px 0px rgba(79, 35, 35, 0.15);
    box-shadow: 0px 20px 30px 0px rgba(79, 35, 35, 0.15);
    color: #fa7070;
    display: inline-block;
    padding: 7px 37px;
    font-weight: 600;
    border-radius: 30px;
    font-size: 14px;
    border: 2px solid transparent;
}

.site-header .header-inner .site-nav .nav-right .nav-btn:hover {
    background: #fa7070;
    color: #fff;
}

.site-header .header-inner .site-nav.nav-two .nav-right .nav-btn {
    background: transparent;
    color: #fff;
    border: 2px solid #fff;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 6px 37px;
}

.site-header .header-inner .site-nav.nav-two .nav-right .nav-btn:hover {
    background: #fff;
    color: #7052fb;
    border-color: #fff;
}

@media (max-width: 991px) {
    .site-header .header-inner .site-nav.nav-two .nav-right .nav-btn {
        border-color: #7052fb;
        color: #7052fb;
    }
    .site-header .header-inner .site-nav.nav-two .nav-right .nav-btn:hover {
        color: #fff;
        background-color: #7052fb;
        border-color: #7052fb;
    }
}

.site-header .header-inner .site-nav.nav-two .nav-right .nav-btn.style-two {
    background-color: rgba(255, 255, 255, 0.1);
    border-color: rgba(255, 255, 255, 0.3);
    color: #fff;
    padding: 9px 37px;
}

.site-header .header-inner .site-nav.nav-two .nav-right .nav-btn.style-two:hover {
    background: #fff;
    color: #7052fb;
}

@media (max-width: 991px) {
    .site-header .header-inner .site-nav.nav-two .nav-right .nav-btn.style-two {
        border-color: #7052fb;
        color: #7052fb;
    }
}

.site-header .header-inner .site-nav.nav-two .site-main-menu li .sub-menu li a:hover,
.site-header .header-inner .site-nav.nav-two .site-main-menu li .sub-menu li a.current_page {
    color: #7052fb;
}

.site-header .site-mobile-logo {
    display: none;
}

.site-header .site-main-menu {
    margin: 0;
    padding: 0;
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.site-header .site-main-menu li {
    margin: 0 23px;
    position: relative;
    padding: 36px 0;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.site-header .site-main-menu li:last-child {
    margin-right: 0;
}

.site-header .site-main-menu li>a {
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    position: relative;
}

.site-header .site-main-menu li>a:after {
    content: '';
    position: absolute;
    bottom: -2px;
    width: 0;
    height: 1px;
    background: #fff;
    opacity: 1;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    left: 0;
}

.site-header .site-main-menu li>a:hover:after,
.site-header .site-main-menu li>a.current_page:after {
    width: 100%;
    opacity: 1;
}

.site-header .site-main-menu li.menu-item-has-children:after {
    position: absolute;
    right: -18px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-family: eleganticons;
    font-size: 10px;
    font-weight: 700;
    color: #fff;
    font-size: 14px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.site-header .site-main-menu li.menu-item-has-children:hover:after {
    color: #fa7070;
}

.site-header .site-main-menu li .sub-menu {
    display: block;
    margin: 0;
    padding: 23px 30px;
    list-style: none;
    background: #fff;
    -webkit-box-shadow: 0px 0px 36px 4px rgba(79, 35, 35, 0.1);
    box-shadow: 0px 0px 36px 4px rgba(79, 35, 35, 0.1);
    position: absolute;
    top: 110%;
    left: 0;
    min-width: 250px;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    z-index: 999999;
    border-radius: 4px;
}

.site-header .site-main-menu li .sub-menu li {
    display: block;
    margin: 0;
    padding: 0;
}

.site-header .site-main-menu li .sub-menu li.menu-item-has-children:after {
    content: '5';
    right: 0px;
    color: #333;
}

.site-header .site-main-menu li .sub-menu li.menu-item-has-children .sub-menu {
    left: 105%;
    top: 0;
    visibility: hidden;
    opacity: 0;
}

.site-header .site-main-menu li .sub-menu li.menu-item-has-children:hover .sub-menu {
    top: -23px;
    visibility: visible;
    opacity: 1;
}

.site-header .site-main-menu li .sub-menu li a {
    display: block;
    padding: 3px 0;
    color: #797687;
    font-size: 14px;
    font-weight: 500;
}

.site-header .site-main-menu li .sub-menu li a:after {
    display: none;
}

.site-header .site-main-menu li .sub-menu li a:hover,
.site-header .site-main-menu li .sub-menu li a.current_page {
    color: #fa7070;
}

.site-header .site-main-menu li .sub-menu li:last-child a {
    border-bottom: 0;
}

.site-header .site-main-menu li:hover .sub-menu {
    opacity: 1;
    visibility: visible;
    top: 100%;
}

.site-header .nav-dark .site-main-menu li a {
    color: #2b2350;
}

.site-header .nav-dark .site-main-menu li a:after {
    display: none;
}

.site-header .nav-dark .site-main-menu li a:hover,
.site-header .nav-dark .site-main-menu li a.current_page {
    color: #fa7070;
}

.site-header .nav-dark .site-main-menu li.menu-item-has-children:after {
    color: #2b2350;
}

.site-header.header-five .header-inner .site-nav .menu-wrapper {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: relative;
    width: 80%;
}

@media (max-width: 991px) {
    .site-header.header-five .header-inner .site-nav .menu-wrapper {
        width: 100%;
        display: block;
    }
}

@media (max-width: 991px) {
    .site-header.header-five .header-inner .site-nav .nav-right {
        display: block;
        text-align: left;
    }
}

.site-header.header-five .header-inner .site-nav .nav-right .nav-btn {
    background: transparent;
    color: #fff;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 2px solid rgba(255, 255, 255, 0.302);
    border-radius: 4px;
    padding: 7px 24px;
    font-size: 16px;
}

.site-header.header-five .header-inner .site-nav .nav-right .nav-btn:hover {
    background: #fff;
    border-color: #fff;
    color: #7052fb;
}

@media (max-width: 991px) {
    .site-header.header-five .header-inner .site-nav .nav-right .nav-btn {
        border-color: #7052fb;
        color: #7052fb;
    }
    .site-header.header-five .header-inner .site-nav .nav-right .nav-btn:hover {
        color: #fff;
        background: #7052fb;
    }
}

.site-header.header-five .site-main-menu li a {
    color: #2b2350;
}

.site-header.header-five .site-main-menu li a:after {
    display: none;
}

.site-header.header-five .site-main-menu li a:hover,
.site-header.header-five .site-main-menu li a.current_page {
    color: #7052fb;
}

.site-header.header-five .site-main-menu li.menu-item-has-children:after {
    color: #2b2350;
}

.site-header.header-five .site-main-menu li .sub-menu li a:hover,
.site-header.header-five .site-main-menu li .sub-menu li a.current_page {
    color: #7052fb;
}

.site-header.header-six .header-inner .site-nav {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.site-header.header-six .header-inner .site-nav .nav-right .nav-btn {
    background: transparent;
    border: 2px solid #efbbd9;
    color: #f97bc5;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.site-header.header-six .header-inner .site-nav .nav-right .nav-btn:hover {
    background: #f97bc5;
    color: #fff;
    border-color: #f97bc5;
}

.site-header.header-six .header-inner .site-nav .nav-right .login-btn {
    color: #646479;
    margin-right: 15px;
    font-weight: 500;
}

.site-header.header-six .header-inner .site-nav .nav-right .login-btn i {
    margin-right: 5px;
}

.site-header.header-six .header-inner .site-nav .nav-right .login-btn:hover {
    color: #f97bc5;
}

.site-header.header-six .site-main-menu li>a {
    color: #2b2350;
}

.site-header.header-six .site-main-menu li>a:hover {
    color: #f97bc5;
}

.site-header.header-six .site-main-menu li .sub-menu li a:hover,
.site-header.header-six .site-main-menu li .sub-menu li a.current_page {
    color: #f97bc5;
}

.site-header.header-seven .header-inner .site-nav .menu-wrapper {
    -ms-flex-pack: distribute;
    justify-content: space-around;
    position: relative;
    width: 80%;
}

@media (max-width: 991px) {
    .site-header.header-seven .header-inner .site-nav .menu-wrapper {
        width: 100%;
    }
}

.site-header.header-seven .header-inner .site-nav .nav-right {
    margin-left: 0;
}

@media (max-width: 991px) {
    .site-header.header-seven .header-inner .site-nav .nav-right {
        margin-left: 20px;
    }
}

.site-header.header-seven .header-inner .site-nav .nav-right .nav-btn {
    background: transparent;
    color: #fff;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 2px solid rgba(255, 255, 255, 0.302);
    border-radius: 4px;
    padding: 7px 24px;
    font-size: 16px;
}

.site-header.header-seven .header-inner .site-nav .nav-right .nav-btn:hover {
    background: #fff;
    border-color: #fff;
    color: #fd4d5c;
}

@media (max-width: 991px) {
    .site-header.header-seven .header-inner .site-nav .nav-right .nav-btn {
        color: #fd4d5c;
        border-color: #fd4d5c;
    }
}

.site-header.header-seven .site-main-menu li a {
    color: #fff;
}

.site-header.header-seven .site-main-menu li a:after {
    display: none;
}

.site-header.header-seven .site-main-menu li a:hover,
.site-header.header-seven .site-main-menu li a.current_page {
    color: #fd4d5c;
}

.site-header.header-seven .site-main-menu li.menu-item-has-children:after {
    color: #2b2350;
}

.site-header.header-seven .site-main-menu li .sub-menu li a:hover,
.site-header.header-seven .site-main-menu li .sub-menu li a.current_page {
    color: #fd4d5c;
}

.site-header.header-seven.pix-header-fixed .site-main-menu li a {
    color: #333;
}

.site-header.header-seven.pix-header-fixed .site-main-menu li a:hover {
    color: #fd4d5c;
}

.site-header.header-seven.pix-header-fixed .site-main-menu li.menu-item-has-children:after {
    color: #2b2350;
}

.site-header.header-seven.pix-header-fixed .header-inner .site-nav .nav-right .nav-btn {
    border-color: #fd4d5c;
    color: #fd4d5c;
}

.site-header.header-seven.pix-header-fixed .header-inner .site-nav .nav-right .nav-btn:hover {
    background: #fd4d5c;
    color: #fff;
}

.site-header.header-eight .toggle-menu .bar {
    background: #0b0b0b;
}

.site-header.header-eight .header-inner .site-nav {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.site-header.header-eight .header-inner .site-nav .nav-right .nav-btn {
    background: #12bf7c;
    border: 2px solid #12bf7c;
    color: #fff;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 4px;
    -webkit-box-shadow: 0px 10px 20px 0px rgba(11, 176, 112, 0.3);
    box-shadow: 0px 10px 20px 0px rgba(11, 176, 112, 0.3);
}

.site-header.header-eight .header-inner .site-nav .nav-right .nav-btn:hover {
    background: transparent;
    color: #12bf7c;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.site-header.header-eight .site-main-menu li>a {
    color: #2b2350;
}

.site-header.header-eight .site-main-menu li>a:hover {
    color: #12bf7c;
}

.site-header.header-eight .site-main-menu li>a:hover:after {
    background: #12bf7c;
}

.site-header.header-eight .site-main-menu li .sub-menu li a:hover,
.site-header.header-eight .site-main-menu li .sub-menu li a.current_page {
    color: #12bf7c;
}

.site-header.header-nine {
    background: #fff;
    -webkit-box-shadow: 0px 10px 20px 0px rgba(14, 3, 52, 0.08);
    box-shadow: 0px 10px 20px 0px rgba(14, 3, 52, 0.08);
}

.site-header.header-nine .toggle-menu .bar {
    background: #1a133b;
}

.site-header.header-nine .site-main-menu li {
    padding: 26px 0;
}

.site-header.header-nine .site-main-menu li a {
    color: #615f6b;
}

.site-header.header-nine .site-main-menu li a:after {
    display: none;
}

.site-header.header-nine .site-main-menu li a:hover,
.site-header.header-nine .site-main-menu li a.current_page {
    color: #f98e47;
}

.site-header.header-nine .site-main-menu li.menu-item-has-children:after {
    color: #615f6b;
}

.site-header.header-nine .header-inner .site-nav .nav-right .nav-btn {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 6px;
    background: #f98e47;
    border: 1px solid #f98e47;
    color: #fff;
    padding: 4px 30px;
    font-weight: 500;
}

.site-header.header-nine .header-inner .site-nav .nav-right .nav-btn:hover {
    background: transparent;
    color: #f98e47;
}

.site-header.header-hosting .site-main-menu li a {
    color: #fff;
}

.site-header.header-hosting .site-main-menu li a:after {
    display: none;
}

.site-header.header-hosting .site-main-menu li a:hover,
.site-header.header-hosting .site-main-menu li a.current_page {
    color: #14aeee;
}

.site-header.header-hosting .site-main-menu li .sub-menu li a:hover,
.site-header.header-hosting .site-main-menu li .sub-menu li a.current_page {
    color: #14aeee;
}

.site-header.header-hosting.pix-header-fixed .site-main-menu li a {
    color: #2c234d;
}

.site-header.header-hosting.pix-header-fixed .site-main-menu li a:hover {
    color: #14aeee;
}

.site-header.header-hosting.pix-header-fixed .site-main-menu li.menu-item-has-children:after {
    color: #2b2350;
}

.site-header.header-hosting.pix-header-fixed .header-inner .site-nav .nav-right .nav-btn {
    border-color: #14aeee;
    color: #14aeee;
}

.site-header.header-hosting.pix-header-fixed .header-inner .site-nav .nav-right .nav-btn:hover {
    background: #14aeee;
    color: #fff;
}

.site-header.header-hosting .header-inner .site-nav .nav-right .nav-btn {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 30px;
    background: transparent;
    border: 2px solid rgba(255, 255, 255, 0.302);
    color: #fff;
    padding: 4px 41px;
}

.site-header.header-hosting .header-inner .site-nav .nav-right .nav-btn:hover {
    background: #14aeee;
    border-color: #14aeee;
    color: #fff;
}

@media (max-width: 991px) {
    .site-header.header-hosting .header-inner .site-nav .nav-right .nav-btn {
        border-color: #14aeee;
        color: #14aeee;
    }
}

.site-header.header-travel {
    border-bottom: 1px solid rgba(255, 255, 255, 0.059);
}

.site-header.header-travel .toggle-menu .bar {
    background: #fff !important;
}

.site-header.header-travel .site-main-menu li {
    padding: 26px 0;
}

.site-header.header-travel .header-contact-info {
    color: #fff;
    font-size: 14px;
}

.site-header.header-travel .header-contact-info i {
    margin-right: 10px;
    font-size: 16px;
}

.site-header.header-travel.pix-header-fixed .header-contact-info {
    color: #2b2350;
}

.site-header.header-travel.pix-header-fixed .toggle-menu .bar {
    background: #2b2350 !important;
}

.logo-sticky {
    display: none;
}


/* .sil-banner {
        background: url(img/career.jpg);
        height: 450px;
        width: 100%;
        position: relative;
        text-align: center;
        color: white;
    } */

.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.sufix_img img {
    height: 350px;
    width: 100%;
}

.sil-txt {
    font-family: 'Roboto', sans-serif;
    color: #000;
    font-size: 25px;
}

.sil-Ptxt {
    font-family: 'Roboto', sans-serif;
    color: #000;
    font-size: 16px;
}

.job-details-inner {
    padding-top: 60px;
    border-top: 1px solid #eaeaea;
}

.team__job {
    padding: 35px 20px;
    border: 1px solid #e3e3e3;
    content: '';
    display: block;
    clear: both;
    overflow: auto;
}

.mb-30 {
    margin-bottom: 30px;
}

.mt-15 {
    margin-top: 15px;
}

.team__job h4 {
    font-size: 1.8rem;
    margin-bottom: 25px;
}

.job-list ul li {
    padding-left: 12px;
    font-size: 1.2rem;
    position: relative;
    margin-bottom: 16px;
    color: #667488;
    list-style-type: square;
    /* list-style: none; */
}

.team__job ul li h5 {
    width: 50%;
    float: left;
    font-size: 1.1rem;
    color: #667488;
    margin-bottom: 0;
    line-height: 30px;
}

@media (max-width: 768px) {
    .team__job ul li h5 {
        width: 50%;
        float: left;
        font-size: 1.2rem;
        color: #667488;
        margin-bottom: 0;
        line-height: 20px;
    }
    .team__job {
        padding: 35px 20px;
        border: 1px solid #e3e3e3;
        content: '';
        display: block;
        clear: both;
        overflow: auto;
    }
    .mb-30 {
        margin-bottom: 30px;
    }
    .mt-15 {
        margin-top: 15px;
    }
    .team__job h4 {
        font-size: 1.8rem;
        margin-bottom: 25px;
    }
    .job-list ul li {
        padding-left: 12px;
        font-size: 1.4rem;
        position: relative;
        margin-bottom: 16px;
        color: #667488;
        list-style-type: square;
        /* list-style: none; */
    }
}

.team__job ul li span {
    display: inline-block;
    width: 50%;
    float: right;
    color: #1c2733;
}

.job-list h4 {
    font-size: 1.8rem;
    margin-bottom: 25px;
}

.pix-header-fixed {
    top: 0;
    left: 0;
    right: 0;
    position: fixed;
    z-index: 99 !important;
    -webkit-box-shadow: 0px 10px 20px 0px rgba(79, 35, 35, 0.08);
    box-shadow: 0px 10px 20px 0px rgba(79, 35, 35, 0.08);
    -webkit-animation: stickySlideDown 0.65s cubic-bezier(0.23, 1, 0.32, 1) both;
    animation: stickySlideDown 0.65s cubic-bezier(0.23, 1, 0.32, 1) both;
    background: #fff;
}

.pix-header-fixed .header-inner .site-logo {
    padding: 18px 0;
}

.pix-header-fixed .header-inner .site-logo .logo-sticky {
    display: block;
}

.pix-header-fixed .header-inner .site-logo .logo-main {
    display: none;
}

.pix-header-fixed .header-inner .site-nav .nav-right .nav-btn {
    background: transparent;
    color: #fa7070;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #fa7070;
}

.pix-header-fixed .header-inner .site-nav .nav-right .nav-btn:hover {
    background: #fa7070;
    color: #fff;
}

.pix-header-fixed .header-inner .site-nav.nav-two .nav-right .nav-btn,
.pix-header-fixed .header-inner .site-nav.nav-two .nav-right .nav-btn.style-two {
    border-color: #7052fb;
    color: #7052fb;
}

.pix-header-fixed .header-inner .site-nav.nav-two .nav-right .nav-btn:hover,
.pix-header-fixed .header-inner .site-nav.nav-two .nav-right .nav-btn.style-two:hover {
    background: #7052fb;
    color: #fff;
    border-color: #7052fb;
}

.pix-header-fixed .header-inner .site-nav.nav-two .site-main-menu li a:hover,
.pix-header-fixed .header-inner .site-nav.nav-two .site-main-menu li a.current_page {
    color: #7052fb;
}

.pix-header-fixed .site-main-menu li {
    padding: 28px 0;
}

.pix-header-fixed .site-main-menu li.menu-item-has-children:after {
    color: #333;
}

.pix-header-fixed .site-main-menu li a {
    color: #333;
}

.pix-header-fixed .site-main-menu li a:after {
    display: none;
}

.pix-header-fixed .site-main-menu li a:hover,
.pix-header-fixed .site-main-menu li a.current_page {
    color: #fa7070;
}

.pix-header-fixed .site-main-menu li a:hover:before,
.pix-header-fixed .site-main-menu li a:hover:after,
.pix-header-fixed .site-main-menu li a.current_page:before,
.pix-header-fixed .site-main-menu li a.current_page:after {
    background: #fa7070;
}

.pix-header-fixed .site-logo .main-logo {
    display: none;
}

.pix-header-fixed .site-logo .sticky-logo {
    display: block;
}

.pix-header-fixed.header-five .header-inner .site-nav .nav-right .nav-btn {
    color: #7052fb;
    border: 2px solid #7052fb;
}

.pix-header-fixed.header-five .header-inner .site-nav .nav-right .nav-btn:hover {
    background: #7052fb;
    border-color: #7052fb;
    color: #fff;
}

.mask-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 3333;
    top: 0;
    left: 0;
}

.close-menu {
    display: none;
}

.home-color-two .return-to-top:hover:after {
    -webkit-transform: scale(1.07);
    -ms-transform: scale(1.07);
    transform: scale(1.07);
    background: #7052fb;
    -webkit-box-shadow: 0px 10px 20px 8px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 10px 20px 8px rgba(0, 0, 0, 0.15);
}

.home-color-two .return-to-top:hover>i {
    text-shadow: 0px -50px #fff, 0px 0px #fff;
}

@media (max-width: 991px) {
    .toggle-menu {
        position: absolute;
        left: 0;
        width: 26px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        display: block;
        height: 24px;
    }
    .toggle-menu .bar {
        width: 18px;
        height: 2px;
        display: block;
        float: left;
        margin: 3px auto;
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        background: #fff;
    }
    .toggle-menu .bar:nth-child(2) {
        width: 24px;
    }
    .site-header .header-inner {
        text-align: center;
        padding: 15px 0;
    }
    .site-header .header-inner .site-nav .nav-right {
        margin-left: 20px;
    }
    .site-header .header-inner .site-nav .nav-right .nav-btn {
        padding: 8px 22px;
    }
    .site-header .site-mobile-logo {
        display: block;
    }
    .site-header .site-main-menu li {
        margin: 0 15px;
    }
    .site-header .site-logo {
        display: none;
    }
    .site-header.header-two.toggle-light .toggle-menu .bar {
        background: #fff;
    }
    .site-header.header-five .toggle-menu .bar,
    .site-header.header-six .toggle-menu .bar {
        background: #333;
    }
    .site-header .site-nav {
        position: fixed;
        width: 320px !important;
        height: 100vh;
        background: #fff;
        top: 0;
        left: -100%;
        display: block !important;
        -webkit-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out;
        overflow: scroll;
        -webkit-box-shadow: 0 20px 30px rgba(0, 0, 0, 0.1);
        box-shadow: 0 20px 30px rgba(0, 0, 0, 0.1);
    }
    .site-header .site-nav .site-main-menu {
        display: block;
        width: 100%;
        padding-bottom: 20px;
        text-align: left;
    }
    .site-header .site-nav .site-main-menu li {
        padding: 0;
        margin: 0;
        border-bottom: 1px solid #f1f2f3;
    }
    .site-header .site-nav .site-main-menu li:first-child {
        border-top: 1px solid #f1f2f3;
    }
    .site-header .site-nav .site-main-menu li a {
        display: block;
        color: #888888;
        padding: 6px 35px;
        font-weight: 400;
    }
    .site-header .site-nav .site-main-menu li a:after {
        display: none;
    }
    .site-header .site-nav .site-main-menu li.menu-item-has-children {
        position: relative;
    }
    .site-header .site-nav .site-main-menu li.menu-item-has-children:after {
        display: none;
    }
    .site-header .site-nav .site-main-menu li.menu-item-has-children a:hover,
    .site-header .site-nav .site-main-menu li.menu-item-has-children:focus {
        color: #fa7070;
    }
    .site-header .site-nav .site-main-menu li.menu-item-has-children i {
        position: absolute;
        top: 6px;
        right: 20px;
        display: block;
        width: 30px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        z-index: 222;
    }
    .site-header .site-nav .site-main-menu li.menu-item-has-children .sub-menu {
        position: static;
        -webkit-box-shadow: none;
        box-shadow: none;
        opacity: 1;
        width: auto;
        visibility: visible;
        background: transparent;
        padding: 0;
        display: none;
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }
    .site-header .site-nav .site-main-menu li.menu-item-has-children .sub-menu li {
        padding: 0;
    }
    .site-header .site-nav .site-main-menu li.menu-item-has-children .sub-menu li:first-child {
        border-top: 1px solid #f1f2f3;
    }
    .site-header .site-nav .site-main-menu li.menu-item-has-children .sub-menu li:last-child {
        border-bottom: none;
        padding-bottom: 0;
    }
    .site-header .site-nav .site-main-menu li.menu-item-has-children .sub-menu li a {
        color: #888888;
        font-weight: 400;
        padding: 5px 35px 5px 45px;
    }
    .site-header .site-nav .site-main-menu li.menu-item-has-children .sub-menu li.menu-item-has-children .sub-menu {
        opacity: 1;
        visibility: visible;
        position: relative;
        left: 0;
        top: auto;
    }
    .site-header .site-nav .site-main-menu li.menu-item-has-children .sub-menu li.menu-item-has-children .sub-menu li a {
        padding-left: 60px;
    }
    .site-header .site-nav .site-main-menu li.menu-item-has-children .sub-menu li.menu-item-has-children:after {
        content: '3';
        color: #fff;
    }
    .site-header .site-nav .nav-right {
        text-align: left;
        padding-left: 13px;
    }
    .site-header .site-nav.nav-two .site-main-menu li.menu-item-has-children:hover,
    .site-header .site-nav.nav-two .site-main-menu li.menu-item-has-children:focus {
        color: #7052fb;
    }
    .site-header .site-nav.nav-two .site-main-menu li.menu-item-has-children:hover:after,
    .site-header .site-nav.nav-two .site-main-menu li.menu-item-has-children:focus:after {
        color: #7052fb;
    }
    .site-header .site-nav.nav-two .site-main-menu li.menu-item-has-children a:hover,
    .site-header .site-nav.nav-two .site-main-menu li.menu-item-has-children a:focus {
        color: #7052fb;
    }
    .site-header.header-two .toggle-menu .bar {
        background: #2b2350;
    }
    .header-inner .site-mobile-logo img {
        max-height: 30px;
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }
    .pix-header-fixed .toggle-menu {
        top: 30px;
    }
    .pix-header-fixed .toggle-menu .bar {
        background: #333;
    }
    .pix-header-fixed .site-main-menu li a {
        color: #fff;
    }
    .pix-header-fixed .site-main-menu li.menu-item-has-children:after {
        color: #fff;
    }
    .pix-header-fixed .header-inner .site-logo {
        padding: 12px 0;
    }
    .pix-header-fixed.header-two.toggle-light .toggle-menu .bar {
        background: #000;
    }
    .sidebar-open .site-header .site-nav {
        left: 0;
    }
    .sidebar-open .menu-wrapper:before {
        opacity: 1;
        visibility: visible;
    }
    .close-menu {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        top: 0;
        text-align: right;
        width: 100%;
        left: 0;
        padding: 2px;
        padding: 30px 20px;
    }
    .close-menu i {
        font-size: 36px;
        color: #2b2350;
    }
    .close-menu span {
        font-size: 15px;
    }
    .header-dark .toggle-menu .bar {
        background: #2b2350;
    }
}

@media (max-width: 420px) {
    .site-header .site-nav {
        width: 100% !important;
    }
}


/*--------------------------------------------------------------
  ##  Banner
  --------------------------------------------------------------*/

.banner {
    position: relative;
}

.banner .banner-content-wrap {
    position: relative;
    -webkit-transform: translateY(52%);
    -ms-transform: translateY(52%);
    transform: translateY(52%);
    z-index: 2;
}

.banner .banner-content .banner-title {
    font-size: 60px;
    line-height: 70px;
    font-weight: 400;
    color: #fff;
    margin-bottom: 20px;
}

.banner .banner-content .banner-title span {
    font-weight: 800;
}

.banner .banner-content .description {
    font-size: 18px;
    font-weight: 400;
    color: #fff;
    line-height: 30px;
    margin-bottom: 47px;
}

.banner .banner-content .banner-btn {
    color: #fff;
    padding: 10px 40px;
    border: 1px solid #fff;
    border-radius: 30px;
    font-size: 14px;
    display: inline-block;
}

.banner .banner-content .banner-btn:hover {
    background: #fff;
    color: #fa7070;
    -webkit-box-shadow: 0px 20px 30px 0px rgba(79, 35, 35, 0.15);
    box-shadow: 0px 20px 30px 0px rgba(79, 35, 35, 0.15);
}

.banner.banner-one {
    height: 1050px;
    background-image: -o-linear-gradient(350deg, #fa7070 0%, #fa9770 100%);
    background-image: linear-gradient(100deg, #fa7070 0%, #fa9770 100%);
    position: relative;
    overflow-x: hidden;
}

.banner.banner-one .circle-shape {
    position: absolute;
    top: -20%;
    left: -13%;
}

.banner.banner-one .promo-mockup {
    margin-left: -120px;
}

.banner.banner-one .promo-mockup img {
    max-width: 920px;
    -webkit-animation: zoom 4s infinite ease;
    animation: zoom 4s infinite ease;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
    -webkit-transform: scale(1) translate(0px);
    -ms-transform: scale(1) translate(0px);
    transform: scale(1) translate(0px);
}

.banner.banner-one .bg-shape {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
}

.banner.banner-one .bg-shape img {
    width: 100%;
}

.banner.banner-two {
    height: 1028px;
    overflow: hidden;
    background-image: -o-linear-gradient(150deg, #7052fb 0%, #8a60fd 100%);
    background-image: linear-gradient(-60deg, #7052fb 0%, #8a60fd 100%);
}

.banner.banner-two .vector-bg {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1028px;
}

.banner.banner-two .vector-bg img {
    max-width: 100%;
}

.banner.banner-two .banner-content-wrap {
    -webkit-transform: translateY(37%);
    -ms-transform: translateY(37%);
    transform: translateY(37%);
}

.banner.banner-two .banner-content .banner-title {
    color: #2b2350;
}

.banner.banner-two .banner-content .description {
    color: #2b2350;
}

.banner.banner-two .banner-content .banner-btn.color-two {
    background: #7052fb;
    -webkit-box-shadow: 0px 20px 30px 0px rgba(90, 70, 176, 0.3);
    box-shadow: 0px 20px 30px 0px rgba(90, 70, 176, 0.3);
    border: 1px solid transparent;
}

.banner.banner-two .banner-content .banner-btn:hover {
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #fff;
}

.banner.banner-two .promo-mockup img {
    max-width: 850px;
}

.banner.banner-three {
    background-image: -o-linear-gradient(350deg, #fa7070 0%, #fa9770 100%);
    background-image: linear-gradient(100deg, #fa7070 0%, #fa9770 100%);
    height: 950px;
    min-height: 700px;
}

.banner.banner-three .banner-content-wrap-two {
    padding-top: 185px;
}

.banner.banner-three .banner-content {
    margin-bottom: 100px;
    position: relative;
    z-index: 22;
}

.banner.banner-three .banner-content .banner-title {
    font-size: 50px;
    line-height: 60px;
    font-weight: 800;
    margin-bottom: 20px;
}

.banner.banner-three .banner-content .description {
    font-size: 18px;
    font-weight: 400;
    color: #fff;
    line-height: 30px;
    margin-bottom: 47px;
}

.banner.banner-three .banner-content .banner-btn {
    color: #fff;
    padding: 8px 47px;
    border: 1px solid #fff;
    border-radius: 30px;
    font-size: 14px;
    display: inline-block;
    font-weight: 600;
}

.banner.banner-three .banner-content .banner-btn.btn-fill {
    color: #fa7070;
}

.banner.banner-three .banner-content .banner-btn:hover {
    background: #fff;
    color: #fa7070;
    -webkit-box-shadow: 0px 20px 30px 0px rgba(79, 35, 35, 0.15);
    box-shadow: 0px 20px 30px 0px rgba(79, 35, 35, 0.15);
}

.banner.banner-three .play-btn {
    margin-left: 20px;
    font-weight: 500;
}

.banner.banner-three .circle-shape,
.banner.banner-three .shape {
    position: absolute;
    z-index: 0;
    top: 0;
}

.banner.banner-three .circle-shape {
    left: 0;
}

.banner.banner-three .shape {
    right: 0;
}

.banner.banner-three .promo-mockup {
    position: relative;
    max-width: 970px;
    margin: 0 auto;
}

.banner.banner-three .promo-mockup img {
    -webkit-box-shadow: 0px 60px 100px 0px rgba(79, 35, 35, 0.2);
    box-shadow: 0px 60px 100px 0px rgba(79, 35, 35, 0.2);
    border-radius: 10px;
}

.banner.banner-three .promo-mockup .shape-shadow {
    border-radius: 10px;
    background-color: white;
    opacity: 0.4;
    -webkit-box-shadow: 0px 60px 100px 0px rgba(79, 35, 35, 0.2);
    box-shadow: 0px 60px 100px 0px rgba(79, 35, 35, 0.2);
    height: 100px;
    max-width: 73%;
    position: absolute;
    bottom: -40px;
    width: 100%;
    z-index: -1;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

.banner.banner-three .bg-shape-inner {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
}

.banner.banner-three .bg-shape-inner img {
    width: 100%;
}

.banner.banner-four {
    background-image: -o-linear-gradient(150deg, #7052fb 0%, #8a60fd 100%);
    background-image: linear-gradient(-60deg, #7052fb 0%, #8a60fd 100%);
    height: 1060px;
    padding-top: 290px;
}

.banner.banner-four .banner-content-wrap-two {
    padding-top: 185px;
}

.banner.banner-four .banner-content {
    margin-bottom: 100px;
    position: relative;
    z-index: 22;
}

.banner.banner-four .banner-content .banner-title {
    font-size: 60px;
    line-height: 76px;
    font-weight: 700;
    margin-bottom: 20px;
}

.banner.banner-four .banner-content .banner-title span {
    font-weight: 300;
}

.banner.banner-four .banner-content .description {
    font-size: 18px;
    font-weight: 400;
    color: #fff;
    line-height: 30px;
    margin-bottom: 47px;
}

.banner.banner-four .promo-mockup {
    position: relative;
    z-index: 2;
    margin-top: -80px;
    -webkit-animation: jump 2s infinite;
    animation: jump 2s infinite;
}

.banner.banner-four .play-btn {
    font-weight: 400;
}

.banner.banner-four .play-btn i {
    color: #7052fb;
    text-shadow: none;
}

.banner.banner-four .bg-shape-inner {
    position: absolute;
    bottom: -2px;
}

.banner .banner-button-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.banner.banner-five {
    height: 930px;
    padding: 230px 0;
    background-image: -o-linear-gradient(150deg, #7052fb 0%, #8a60fd 100%);
    background-image: linear-gradient(-60deg, #7052fb 0%, #8a60fd 100%);
}

.banner.banner-five .banner-right-shape {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.banner.banner-five .banner-right-shape img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.banner.banner-five .banner-content-wrap-five {
    position: relative;
    z-index: 33;
}

.banner.banner-five .banner-content-wrap-five .banner-content {
    margin-top: 98px;
}

.banner.banner-five .banner-content-wrap-five .banner-content .sub-title {
    font-size: 16px;
    text-transform: uppercase;
    color: #7052fb;
    font-weight: 400;
    letter-spacing: 2px;
    margin-bottom: 15px;
}

.banner.banner-five .banner-content-wrap-five .banner-content .banner-title {
    color: #322d49;
    font-size: 60px;
    line-height: 64px;
    font-weight: 500;
}

.banner.banner-five .banner-content-wrap-five .banner-content .description {
    color: #5e5b74;
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 42px;
}

.banner.banner-five .banner-content-wrap-five .banner-content .banner-btn {
    background: #7052fb;
    border-radius: 4px;
    border: 1px solid transparent;
    -webkit-box-shadow: 0px 20px 30px 0px rgba(90, 70, 176, 0.3);
    box-shadow: 0px 20px 30px 0px rgba(90, 70, 176, 0.3);
}

.banner.banner-five .banner-content-wrap-five .banner-content .banner-btn:hover {
    color: #7052fb;
    background: transparent;
    border-color: #7052fb;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.banner.banner-six {
    height: 950px;
    position: relative;
    padding-top: 225px;
    overflow: hidden;
}

.banner.banner-six .banne-content-wrapper-six {
    position: relative;
    z-index: 2;
}

.banner.banner-six .banne-content-wrapper-six .subtitle {
    font-size: 16px;
    font-weight: 400;
    color: #f97bc5;
    text-transform: uppercase;
    letter-spacing: 3px;
    margin-bottom: 13px;
}

.banner.banner-six .banne-content-wrapper-six .banner-title {
    font-size: 50px;
    line-height: 60px;
    font-weight: 600;
    margin-bottom: 20px;
}

.banner.banner-six .banne-content-wrapper-six p {
    margin-bottom: 40px;
}

.banner.banner-six:after {
    content: '';
    position: absolute;
    z-index: -1;
    width: 43%;
    height: 100%;
    right: 0;
    top: 0;
    background: #fdf5fe;
}

.banner.banner-six .banner-six-promo-image {
    position: relative;
    z-index: 2;
}

.banner.banner-six .banner-six-promo-image>img {
    border-radius: 10px;
    -webkit-box-shadow: 0px 30px 40px 0px rgba(1, 50, 5, 0.14);
    box-shadow: 0px 30px 40px 0px rgba(1, 50, 5, 0.14);
}

.banner.banner-six .banner-six-promo-image .dot-shape {
    display: block;
    position: absolute;
    bottom: -40px;
    left: -40px;
    z-index: -1;
    -webkit-animation: wave 8s infinite linear;
    animation: wave 8s infinite linear;
}

.banner.banner-six .bottom-shape-coin {
    margin: 0;
    padding: 0;
    list-style: none;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.banner.banner-six .bottom-shape-coin li {
    position: absolute;
    bottom: 0;
}

.banner.banner-six .bottom-shape-coin li:nth-child(1) {
    left: -50px;
}

.banner.banner-six .bottom-shape-coin li:nth-child(2) {
    left: 60px;
}

.banner.banner-six .bottom-shape-coin li:nth-child(3) {
    left: 150px;
    bottom: 35px;
}

.banner.banner-six .bottom-shape-coin li:nth-child(4) {
    bottom: 20px;
    left: 37%;
}

.banner.banner-six .bottom-shape-coin li:nth-child(5) {
    top: 35%;
    left: 10%;
}

.banner.banner-six .particles-six span {
    display: block;
    position: absolute;
}

.banner.banner-six .particles-six span.angle {
    top: 140px;
    left: 90px;
    -webkit-animation: animationFramesOne 20s infinite linear;
    animation: animationFramesOne 20s infinite linear;
}

.banner.banner-six .particles-six span.dotsm {
    height: 15px;
    width: 15px;
    background: #43d355;
    left: 50%;
    top: 15%;
    border-radius: 50%;
    -webkit-animation: animationFramesTwo 25s infinite linear;
    animation: animationFramesTwo 25s infinite linear;
}

.banner.banner-six .particles-six span.dotmd {
    height: 15px;
    width: 15px;
    background: #f8cc61;
    border-radius: 50%;
    top: 50%;
    left: 15%;
    -webkit-animation: animationFramesRight 20s infinite linear;
    animation: animationFramesRight 20s infinite linear;
}

.banner.banner-seven {
    height: 950px;
    background: #fff;
    padding-top: 230px;
}

@media (max-width: 991px) {
    .banner.banner-seven {
        padding-top: 150px;
        height: 800px;
    }
}

@media (max-width: 576px) {
    .banner.banner-seven {
        padding-bottom: 60px;
        height: auto;
    }
}

.banner.banner-seven .banner-content .banner-title {
    font-size: 44px;
    line-height: 56px;
    font-weight: 500;
}

@media (max-width: 991px) {
    .banner.banner-seven .banner-content .banner-title {
        font-size: 36px;
        line-height: 46px;
    }
    .banner.banner-seven .banner-content .banner-title br {
        display: none;
    }
}

@media (max-width: 576px) {
    .banner.banner-seven .banner-content .banner-title {
        font-size: 32px;
        line-height: 42px;
    }
}

@media (max-width: 991px) {
    .banner.banner-seven .banner-content .description br {
        display: none;
    }
}

.banner.banner-seven .job-search-form-wrapper {
    max-width: 770px;
    margin: 0 auto 115px;
}

@media (max-width: 576px) {
    .banner.banner-seven .job-search-form-wrapper {
        margin: 0 auto 80px;
    }
}

.banner.banner-seven .job-search-form-wrapper .job-form-inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-radius: 4px;
    overflow: hidden;
}

.banner.banner-seven .job-search-form-wrapper .job-form-inner input {
    border-radius: 0;
    margin: 0;
}

.banner.banner-seven .job-search-form-wrapper .job-form-inner button {
    min-width: 150px;
    border: 0;
    background: #fd4d5c;
    color: #fff;
}

.banner.banner-seven .promo-mockup {
    border-radius: 20px;
    overflow: hidden;
    -webkit-box-shadow: 0px 50px 70px 0px rgba(10, 1, 47, 0.12);
    box-shadow: 0px 50px 70px 0px rgba(10, 1, 47, 0.12);
    max-width: 1030px;
    margin: 0 auto;
}

.banner.banner-eight {
    position: relative;
    height: 1050px;
    min-height: 100vh;
}

@media (max-width: 991px) {
    .banner.banner-eight {
        height: auto;
    }
}

.banner.banner-eight .banne-content-wrapper-eight {
    position: relative;
    z-index: 222;
}

@media (max-width: 991px) {
    .banner.banner-eight .banne-content-wrapper-eight {
        padding-top: 120px;
    }
}

.banner.banner-eight .banne-content-wrapper-eight .subtitle {
    font-size: 12px;
    color: #1a133b;
    background: #f0f2f7;
    padding: 5px 40px 5px 5px;
    border-radius: 10px;
    display: inline-block;
}

.banner.banner-eight .banne-content-wrapper-eight .subtitle span {
    background: #12bf7c;
    color: #fff;
    padding: 0 9px;
    border-radius: 20px;
    display: inline-block;
    line-height: 1.4;
    margin-right: 20px;
}

.banner.banner-eight .banne-content-wrapper-eight .banner-title {
    font-size: 70px;
    font-weight: 600;
    color: #1a133b;
    line-height: 1.2;
    margin-bottom: 12px;
}

@media (max-width: 1200px) {
    .banner.banner-eight .banne-content-wrapper-eight .banner-title {
        font-size: 55px;
    }
}

@media (max-width: 768px) {
    .banner.banner-eight .banne-content-wrapper-eight .banner-title {
        font-size: 40px;
    }
}

@media (max-width: 768px) {
    .banner.banner-eight .banne-content-wrapper-eight .banner-title {
        font-size: 36px;
    }
}

.banner.banner-eight .banne-content-wrapper-eight .banner-title span {
    font-weight: 300;
}

.banner.banner-eight .banne-content-wrapper-eight p {
    margin-bottom: 40px;
}

.banner.banner-eight .banne-content-wrapper-eight .banner-button-container {
    -webkit-box-pack: unset;
    -ms-flex-pack: unset;
    justify-content: unset;
}

.banner.banner-eight .banne-content-wrapper-eight .banner-button-container .banner-btn {
    padding: 10px 38px;
    font-size: 16px;
    margin-right: 20px;
}

.banner.banner-eight .banner-six-promo-image {
    padding-top: 195px;
    position: relative;
}

@media (max-width: 991px) {
    .banner.banner-eight .banner-six-promo-image.text-right {
        text-align: center !important;
    }
}

@media (max-width: 1400px) {
    .banner.banner-eight .banner-six-promo-image {
        padding-top: 130px;
    }
}

@media (max-width: 991px) {
    .banner.banner-eight .banner-six-promo-image {
        padding-top: 50px;
        max-width: 480px;
        margin: 0 auto;
    }
}

.banner.banner-eight .banner-six-promo-image img {
    position: relative;
    z-index: 22;
    margin-right: -55px;
}

@media (max-width: 991px) {
    .banner.banner-eight .banner-six-promo-image img {
        margin-right: 0;
    }
}

.banner.banner-eight .banner-six-promo-image .banner-leaf {
    position: absolute;
    bottom: 120px;
    left: 0;
    width: 100%;
    z-index: 1;
}

.banner.banner-eight .banner-six-promo-image .banner-leaf .leaf-left,
.banner.banner-eight .banner-six-promo-image .banner-leaf .leaf-right {
    position: absolute;
    bottom: 0;
}

.banner.banner-eight .banner-six-promo-image .banner-leaf .leaf-left {
    left: 65px;
}

@media (max-width: 1200px) {
    .banner.banner-eight .banner-six-promo-image .banner-leaf .leaf-left {
        left: -30px;
    }
}

@media (max-width: 991px) {
    .banner.banner-eight .banner-six-promo-image .banner-leaf .leaf-left {
        left: -65px;
    }
}

.banner.banner-eight .banner-six-promo-image .banner-leaf .leaf-right {
    right: -15px;
    bottom: 65px;
}

.banner.banner-eight .banner-background-element {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.banner.banner-eight .banner-background-element .dot-shape,
.banner.banner-eight .banner-background-element .circle-bg,
.banner.banner-eight .banner-background-element .ball,
.banner.banner-eight .banner-background-element .triangle {
    position: absolute;
}

.banner.banner-eight .banner-background-element .ball {
    left: 100px;
    top: 190px;
}

@media (max-width: 1400px) {
    .banner.banner-eight .banner-background-element .ball {
        left: 20px;
    }
}

.banner.banner-eight .banner-background-element .triangle {
    bottom: 150px;
    left: 40%;
}

.banner.banner-eight .banner-background-element .dot-shape {
    right: 40px;
    top: 40px;
}

.banner.banner-eight .banner-background-element .circle-bg {
    height: 1100px;
    width: 1100px;
    background-image: -o-linear-gradient(210deg, #fd9720 0%, #12bf7c 100%);
    background-image: linear-gradient(-120deg, #fd9720 0%, #12bf7c 100%);
    opacity: 0.102;
    border-radius: 50%;
    top: -14%;
    right: -3.2%;
    z-index: -1;
}

@media (max-width: 1400px) {
    .banner.banner-eight .banner-background-element .circle-bg {
        height: 900px;
        width: 900px;
        top: -4%;
        right: -7%;
    }
}

@media (max-width: 991px) {
    .banner.banner-eight .banner-background-element .circle-bg {
        height: 600px;
        width: 600px;
        top: -2%;
        right: -3%;
    }
}

.banner.banner-nine {
    background: #f8f9fd;
    padding-top: 227px;
    background-size: cover;
    background-position: center center;
}

@media (max-width: 991px) {
    .banner.banner-nine {
        padding: 140px 0 100px;
        height: auto;
    }
}

.banner.banner-nine .banner-content {
    max-width: 640px;
    margin: 0 auto 65px;
}

.banner.banner-nine .banner-content .banner-title {
    color: #1a133b;
    font-size: 50px;
    font-weight: 600;
    margin-bottom: 13px;
}

@media (max-width: 768px) {
    .banner.banner-nine .banner-content .banner-title {
        font-size: 34px;
        line-height: 45px;
    }
}

.banner.banner-nine .banner-content .banner-title span {
    font-weight: 400;
}

.banner.banner-nine .banner-content .description {
    color: #777580;
    margin-bottom: 40px;
}

.banner.banner-nine .banner-content .banner-btn {
    border-radius: 4px;
    border: 2px solid #f98e47;
    color: #f98e47;
    background: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 9px 38px;
    font-size: 16px;
}

.banner.banner-nine .banner-content .banner-btn:hover {
    color: #fff;
    background: #f98e47;
}

.banner.banner-ten {
    background: #040532;
    height: 100vh;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.banner.banner-ten .banner-content-wrapper-ten .banner-title {
    color: #fff;
    margin-bottom: 27px;
    font-size: 44px;
}

@media (max-width: 1200px) {
    .banner.banner-ten .banner-content-wrapper-ten .banner-title br {
        display: none;
    }
}

@media (max-width: 991px) {
    .banner.banner-ten .banner-content-wrapper-ten .banner-title {
        font-size: 40px;
    }
}

@media (max-width: 767px) {
    .banner.banner-ten .banner-content-wrapper-ten .banner-title {
        font-size: 30px;
    }
}

.banner.banner-ten .banner-content-wrapper-ten .description {
    color: #fff;
    margin-bottom: 50px;
    line-height: 28px;
}

@media (max-width: 991px) {
    .banner.banner-ten .banner-content-wrapper-ten {
        text-align: center;
        margin-bottom: 40px;
    }
}

.banner.banner-ten .banner-btn {
    padding: 7px 34px;
    font-size: 16px;
    font-weight: 500;
}

.banner.banner-ten .banner-btn.btn-outline {
    margin-left: 20px;
    color: #fff;
    border-color: rgba(255, 255, 255, 0.302);
}

.banner.banner-ten .banner-btn.btn-outline:hover {
    color: #14aeee;
}

@media (max-width: 380px) {
    .banner.banner-ten .banner-btn.btn-outline {
        margin-left: 0;
        margin-top: 20px;
    }
}

.banner.banner-ten .banner-six-promo-image {
    text-align: center !important;
}

@media (max-width: 991px) {
    .banner.banner-ten {
        height: auto;
        padding: 150px 0 100px;
    }
}

.banner.banner-eleven {
    height: 100vh;
    background-image: url(../../media/banner/banner_travel.png);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.banner.banner-eleven .subtitle {
    font-family: 'Satisfy', cursive;
    font-size: 20px;
    color: #fff;
    font-weight: 400;
    margin-bottom: 5px;
}

.banner.banner-eleven .banner-title {
    font-size: 70px;
    color: #fff;
    font-weight: 700;
}

@media (max-width: 992px) {
    .banner.banner-eleven .banner-title {
        font-size: 50px;
    }
}

@media (max-width: 576px) {
    .banner.banner-eleven .banner-title {
        font-size: 40px;
    }
}

.banner.banner-eleven .description {
    color: #fff;
    font-size: 20px;
    margin-bottom: 45px;
}

.banner.banner-eleven .banner-button-container {
    -webkit-box-pack: left;
    -ms-flex-pack: left;
    justify-content: left;
}

.banner.banner-eleven .banner-btn {
    border-color: rgba(255, 255, 255, 0.302);
    padding: 15px 32px;
    border-radius: 10px;
    line-height: 1;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 20px;
}

.banner.banner-eleven .banner-btn i {
    margin-left: 6px;
    font-size: 20px;
}

.banner.banner-eleven .banner-btn:hover {
    color: #7052fb;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.banner.banner-eleven .play-btn {
    color: #fff;
}

.banner.banner-eleven .play-btn i {
    border-color: #fff;
    text-shadow: 0px 6px 6px rgba(118, 43, 255, 0.4);
    color: #7052fb;
}

.animate-particle {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    list-style: none;
    height: 100%;
    width: 100%;
    z-index: 22;
}

.animate-particle li {
    position: absolute;
}

.animate-particle li:nth-child(1) {
    left: 90px;
    top: 140px;
    -webkit-animation: animationFramesRight 40s infinite linear;
    animation: animationFramesRight 40s infinite linear;
}

.animate-particle li:nth-child(2) {
    left: 40%;
    bottom: 13%;
    -webkit-animation: animationFramesRight 30s infinite linear;
    animation: animationFramesRight 30s infinite linear;
}

.animate-particle li:nth-child(3) {
    right: 28%;
    top: 14%;
    -webkit-animation: animationFramesRight 30s infinite linear;
    animation: animationFramesRight 30s infinite linear;
}

.animate-particle li:nth-child(4) {
    right: 5%;
    top: 63%;
    -webkit-animation: animationFramesLeft 20s infinite linear;
    animation: animationFramesLeft 20s infinite linear;
}

.animate-particle li:nth-child(5) {
    left: 50%;
    top: 25%;
    -webkit-animation: animationFramesLeft 20s infinite linear;
    animation: animationFramesLeft 20s infinite linear;
}

.animate-particle li:nth-child(6) {
    right: -9%;
    top: 110px;
}

.animate-particle li.bubble {
    height: 10px;
    width: 10px;
    background: #a2e3f6;
    border-radius: 50%;
    left: 15%;
    top: 28%;
    -webkit-animation: animationFramesLeft 30s infinite linear;
    animation: animationFramesLeft 30s infinite linear;
}

.left-circle-shape {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 52%;
    left: 0;
}

.left-circle-shape .circle-fill {
    height: 800px;
    width: 800px;
    background: #faf9ff;
    display: block;
    border-radius: 50%;
    position: absolute;
    left: -18%;
    -webkit-animation: wave 10s linear infinite;
    animation: wave 10s linear infinite;
}

.left-circle-shape .circle-border {
    height: 400px;
    width: 400px;
    display: block;
    border: 2px dashed #feebee;
    z-index: 22;
    position: absolute;
    border-radius: 50%;
    left: 7%;
    top: 9%;
    -webkit-animation: waveRote 5s linear infinite;
    animation: waveRote 5s linear infinite;
}

.newsletter-form-banner {
    margin-bottom: 50px;
}

.newsletter-form-banner .newsletter-inner {
    position: relative;
    max-width: 475px;
}

.newsletter-form-banner .newsletter-inner input {
    width: 100%;
    margin: 0;
    height: 56px;
    border-radius: 30px;
    background: #fff;
    border: 0;
    outline: 0;
    padding: 10px 180px 10px 30px;
}

.newsletter-form-banner .newsletter-inner input:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.newsletter-form-banner .newsletter-inner button {
    position: absolute;
    right: 3px;
    top: 4px;
    border: 0;
    background: #7052fb;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    padding: 10px 30px;
    border-radius: 30px;
}

.newsletter-form-banner .newsletter-inner button i {
    display: none;
}

.newsletter-form-banner .newsletter-inner button.clicked i {
    display: block;
}

.newsletter-form-banner .newsletter-inner button:hover {
    background: #5c42d2;
}

.animate-promo-mockup {
    position: relative;
    width: 830px;
    height: 600px;
    margin-left: -40px;
}

.animate-promo-mockup img {
    position: absolute;
}

.animate-promo-mockup img:nth-child(1) {
    left: 100px;
    bottom: 100px;
}

.animate-promo-mockup img:nth-child(2) {
    left: 0;
    bottom: 100px;
}

.animate-promo-mockup img:nth-child(3) {
    right: 0;
    bottom: 150px;
}

.animate-promo-mockup img:nth-child(4) {
    bottom: 15px;
    left: 80px;
}

.animate-promo-mockup img:nth-child(5) {
    bottom: 25px;
    left: 49%;
}

.animate-promo-mockup img:nth-child(6) {
    right: 176px;
    bottom: 0;
    -webkit-animation: jump 2s infinite;
    animation: jump 2s infinite;
}

.animate-promo-mockup img:nth-child(7) {
    right: 114px;
    bottom: 33px;
}

.animate-promo-mockup img:nth-child(8) {
    left: 0;
    top: 0;
    -webkit-animation: moveclouds 4s 1s linear infinite alternate;
    animation: moveclouds 4s 1s linear infinite alternate;
}

.animate-promo-mockup img:nth-child(9) {
    left: 50%;
    top: 0;
    -webkit-animation: moveclouds 4s 2s linear infinite alternate;
    animation: moveclouds 4s 2s linear infinite alternate;
}

.animate-promo-mockup img:nth-child(10) {
    left: 90%;
    top: 0;
    -webkit-animation: moveclouds 4s 3s linear infinite alternate;
    animation: moveclouds 4s 3s linear infinite alternate;
}

.slider-nav {
    position: absolute;
    top: 40%;
    left: 0;
    width: 100%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 999;
}

.slider-nav .slider-prev,
.slider-nav .slider-next {
    height: 100px;
    width: 40px;
    text-align: center;
    line-height: 100px;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    cursor: pointer;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.slider-nav .slider-prev i,
.slider-nav .slider-next i {
    font-size: 30px;
    color: #fff;
}

.slider-nav .slider-prev:hover,
.slider-nav .slider-next:hover {
    background: #fa7070;
}

.slider-nav .slider-next {
    right: 0;
}

.slider-nav .slider-prev {
    left: 0;
}

.swiper-pagination {
    margin-top: 25px;
    position: absolute;
    left: 50% !important;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

.swiper-pagination .swiper-pagination-bullet {
    height: 14px;
    width: 14px;
    margin-right: 3px;
}

.swiper-pagination .swiper-pagination-bullet:focus {
    outline: 0;
}

.swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: #fff;
    opacity: 1;
}

.swiper-pagination.style-three {
    margin-top: 15px;
}

.swiper-pagination.style-three .swiper-pagination-bullet {
    height: 10px;
    width: 10px;
    background: #ababad;
    margin-right: 5px;
}

.swiper-pagination.style-three .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: #fd4d5c;
    opacity: 1;
}

.section-dark .gp-testimonial .gp-testimonial-author .author-details .testi-author-name {
    color: #fff;
}

.section-dark .gp-testimonial .gp-testimonial-author .author-details span {
    color: #b5b5b5;
}

.section-dark .swiper-pagination-bullet {
    background: #fff;
    opacity: 0.5;
}

.scroll-circle {
    position: absolute;
    bottom: -48%;
    z-index: 1;
}


/* Animate element five*/

.animate-element-five {
    position: absolute;
    margin: 0;
    padding: 0;
    list-style: none;
    width: 710px;
    height: 530px;
}

.animate-element-five li {
    position: absolute;
}

.animate-element-five li:nth-child(1) {
    right: 60px;
}

.animate-element-five li:nth-child(2) {
    left: 150px;
    top: 80px;
}

.animate-element-five li:nth-child(3) {
    right: 25px;
    top: 33%;
}

.animate-element-five li:nth-child(4) {
    right: 15px;
    bottom: 55px;
}

.animate-element-five li:nth-child(5) {
    left: 0;
    bottom: 0;
}

.animate-element-five li:nth-child(6) {
    bottom: -10px;
    left: 40%;
}

.animate-element-five li:nth-child(7) {
    bottom: 130px;
    left: 47%;
}

.animate-element-five li:nth-child(8) {
    bottom: 130px;
    left: 44%;
}

.animate-element-five li:nth-child(9) {
    right: 100px;
    bottom: -10px;
}

@media (max-width: 1600px) {
    .banner.banner-one .banner-content-wrap {
        -webkit-transform: translateY(70%);
        -ms-transform: translateY(70%);
        transform: translateY(70%);
    }
    .banner.banner-one .promo-mockup {
        margin-left: -50px;
    }
    .banner.banner-one .promo-mockup img {
        max-width: 800px;
    }
    .banner.banner-two .vector-bg {
        left: -15%;
    }
    .banner.banner-two .animate-promo-mockup {
        width: 550px;
        height: 530px;
    }
    .banner.banner-two .animate-promo-mockup img:nth-child(3) {
        right: -160px;
    }
    .banner.banner-two .animate-promo-mockup img:nth-child(5) {
        left: 62%;
    }
    .banner.banner-two .animate-promo-mockup img:nth-child(6) {
        right: -10px;
        width: 230px;
    }
    .banner.banner-two .animate-promo-mockup img:nth-child(7) {
        right: -60px;
        width: 99px;
    }
    .banner.banner-three .circle-shape {
        left: -20%;
    }
}

@media (max-width: 1440px) {
    .banner.banner-one .banner-content-wrap {
        -webkit-transform: translateY(100%);
        -ms-transform: translateY(100%);
        transform: translateY(100%);
    }
    .banner.banner-one .promo-mockup {
        margin-left: -50px;
    }
    .banner.banner-one .promo-mockup img {
        max-width: 700px;
    }
    .banner.banner-two {
        height: 890px;
    }
    .banner.banner-two .vector-bg {
        left: -14%;
        height: 890px;
    }
    .banner.banner-two .vector-bg img {
        height: 890px;
    }
    .banner.banner-two .banner-content-wrap {
        -webkit-transform: translateY(50%);
        -ms-transform: translateY(50%);
        transform: translateY(50%);
    }
    .banner.banner-two .banner-content .banner-title {
        font-size: 50px;
        line-height: 60px;
    }
    .banner.banner-two .animate-promo-mockup {
        width: 480px;
        height: 470px;
    }
    .banner.banner-two .animate-promo-mockup img:nth-child(1) {
        left: 85px;
    }
    .banner.banner-two .animate-promo-mockup img:nth-child(2) {
        width: 85px;
    }
    .banner.banner-two .animate-promo-mockup img:nth-child(3) {
        right: -135px;
        width: 61px;
    }
    .banner.banner-two .animate-promo-mockup img:nth-child(4) {
        left: 65px;
        width: 150px;
    }
    .banner.banner-two .animate-promo-mockup img:nth-child(5) {
        left: 62%;
    }
    .banner.banner-two .animate-promo-mockup img:nth-child(6) {
        right: -5px;
        width: 210px;
    }
    .banner.banner-two .animate-promo-mockup img:nth-child(7) {
        right: -45px;
        width: 90px;
    }
    .banner.banner-three .circle-shape {
        left: -35%;
    }
    .banner.banner-three .shape {
        right: -15%;
    }
    .animate-element-five {
        margin-left: -100px;
    }
}

@media (max-width: 1280px) {
    .banner.banner-one .banner-content-wrap {
        -webkit-transform: translateY(100%);
        -ms-transform: translateY(100%);
        transform: translateY(100%);
    }
    .banner.banner-one .banner-content .banner-title {
        font-size: 50px;
        line-height: 60px;
    }
    .banner.banner-one .banner-content p br {
        display: none;
    }
    .banner.banner-one .promo-mockup {
        margin-left: -90px;
    }
    .banner.banner-one .promo-mockup img {
        max-width: 100%;
    }
    .banner.banner-six .banne-content-wrapper-six .banner-title {
        font-size: 40px;
        line-height: 50px;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .banner.banner-two .banner-content .banner-title {
        font-size: 40px;
        line-height: 50px;
    }
    .banner.banner-two .banner-content p br {
        display: none;
    }
    .banner.banner-two .animate-promo-mockup {
        width: 380px;
        height: 400px;
    }
    .banner.banner-two .animate-promo-mockup img:nth-child(1) {
        left: 65px;
    }
    .banner.banner-two .animate-promo-mockup img:nth-child(2) {
        width: 65px;
    }
    .banner.banner-two .animate-promo-mockup img:nth-child(3) {
        right: -100px;
        width: 50px;
    }
    .banner.banner-two .animate-promo-mockup img:nth-child(4) {
        left: 55px;
        width: 125px;
        bottom: 30px;
    }
    .banner.banner-two .animate-promo-mockup img:nth-child(5) {
        left: 55%;
        width: 40px;
    }
    .banner.banner-two .animate-promo-mockup img:nth-child(6) {
        right: -3px;
        width: 180px;
        bottom: 8px;
    }
    .banner.banner-two .animate-promo-mockup img:nth-child(7) {
        right: -35px;
        width: 70px;
        bottom: 50px;
    }
    .banner.banner-three .shape {
        right: -25%;
    }
}

@media (max-width: 1200px) {
    .banner.banner-two {
        height: 840px;
    }
    .banner.banner-two .vector-bg {
        height: 840px;
        left: -26%;
    }
    .banner.banner-two .vector-bg img {
        height: 840px;
    }
    .banner.banner-four {
        height: 880px;
        padding-top: 240px;
    }
    .banner.banner-four .banner-content .banner-title {
        font-size: 50px;
        line-height: 65px;
    }
    .banner.banner-four .promo-mockup {
        margin-top: -30px;
    }
    .banner.banner-five {
        height: 700px;
    }
    .banner.banner-five .banner-content-wrap-five .banner-content {
        margin-top: 50px;
    }
    .banner.banner-five .banner-content-wrap-five .banner-content .banner-title {
        font-size: 45px;
        line-height: 55px;
    }
    .banner.banner-five .banner-content-wrap-five .banner-content .description br {
        display: none;
    }
    .animate-element-five {
        margin: 0;
        width: 480px;
        height: 390px;
    }
    .animate-element-five li:nth-child(1) {
        width: 320px;
        right: 20px;
    }
    .animate-element-five li:nth-child(2) {
        left: 120px;
        top: 55px;
        height: 60px;
        width: 60px;
    }
    .animate-element-five li:nth-child(3) {
        right: 0;
        top: 30%;
        height: 60px;
        width: 60px;
    }
    .animate-element-five li:nth-child(4) {
        width: 70px;
        right: -10px;
    }
    .animate-element-five li:nth-child(5) {
        width: 230px;
    }
    .animate-element-five li:nth-child(6) {
        width: 90px;
        bottom: 15px;
        left: 41%;
    }
    .animate-element-five li:nth-child(7) {
        bottom: 110px;
        left: 47%;
    }
    .animate-element-five li:nth-child(8) {
        bottom: 108px;
        left: 43%;
    }
    .animate-element-five li:nth-child(9) {
        width: 140px;
        right: 55px;
        bottom: 10px;
    }
    .left-circle-shape .circle-fill {
        height: 550px;
        width: 550px;
    }
    .left-circle-shape .circle-border {
        height: 300px;
        width: 300px;
    }
}

@media (min-width: 991px) and (max-width: 1024px) {
    .banner.banner-one {
        height: 700px;
    }
    .banner.banner-one .banner-content-wrap {
        -webkit-transform: translateY(60%);
        -ms-transform: translateY(60%);
        transform: translateY(60%);
    }
    .banner.banner-one .banner-content .banner-title {
        font-size: 40px;
        line-height: 50px;
    }
    .banner.banner-one .circle-shape {
        max-width: 500px;
    }
}

@media (max-width: 991px) {
    .banner.banner-one .banner-content-wrap {
        -webkit-transform: translateY(20%);
        -ms-transform: translateY(20%);
        transform: translateY(20%);
    }
    .banner.banner-one .banner-content {
        margin-bottom: 50px;
    }
    .banner.banner-one .banner-content .banner-title {
        font-size: 40px;
        line-height: 50px;
    }
    .banner.banner-one .banner-content p br {
        display: none;
    }
    .banner.banner-one .promo-mockup {
        margin-left: -45px;
    }
    .banner.banner-one .circle-shape {
        max-width: 500px;
    }
    .banner.banner-two {
        height: 840px;
    }
    .banner.banner-two .vector-bg {
        height: 840px;
        left: -27%;
    }
    .banner.banner-two .vector-bg img {
        height: 840px;
    }
    .banner.banner-two .banner-content-wrap {
        -webkit-transform: translateY(18%);
        -ms-transform: translateY(18%);
        transform: translateY(18%);
    }
    .banner.banner-two .banner-content .banner-title {
        font-size: 40px;
        line-height: 50px;
    }
    .banner.banner-two .banner-content p br {
        display: none;
    }
    .banner.banner-two .animate-promo-mockup {
        width: 380px;
        height: 380px;
    }
    .banner.banner-two .animate-promo-mockup img:nth-child(1) {
        left: 65px;
    }
    .banner.banner-two .animate-promo-mockup img:nth-child(2) {
        width: 65px;
    }
    .banner.banner-two .animate-promo-mockup img:nth-child(3) {
        right: -100px;
        width: 50px;
    }
    .banner.banner-two .animate-promo-mockup img:nth-child(4) {
        left: 55px;
        width: 125px;
        bottom: 30px;
    }
    .banner.banner-two .animate-promo-mockup img:nth-child(5) {
        left: 55%;
        width: 40px;
    }
    .banner.banner-two .animate-promo-mockup img:nth-child(6) {
        right: -3px;
        width: 180px;
        bottom: 8px;
    }
    .banner.banner-two .animate-promo-mockup img:nth-child(7) {
        right: -35px;
        width: 70px;
        bottom: 50px;
    }
    .banner.banner-three .promo-mockup img {
        -webkit-box-shadow: 0px 40px 50px 0px rgba(79, 35, 35, 0.2);
        box-shadow: 0px 40px 50px 0px rgba(79, 35, 35, 0.2);
    }
    .banner.banner-three .promo-mockup .shape-shadow {
        -webkit-box-shadow: 0px 40px 50px 0px rgba(79, 35, 35, 0.2);
        box-shadow: 0px 40px 50px 0px rgba(79, 35, 35, 0.2);
    }
    .banner.banner-three.banner-three .circle-shape {
        left: -50%;
    }
    .banner.banner-three.banner-three .shape {
        right: -40%;
    }
    .banner.banner-three .banner-content .banner-title {
        font-size: 45px;
        line-height: 55px;
    }
    .banner.banner-four {
        height: 740px;
        padding-top: 200px;
    }
    .banner.banner-four .banner-content .banner-title {
        font-size: 40px;
        line-height: 55px;
    }
    .banner.banner-four .banner-content p {
        font-size: 16px;
    }
    .banner.banner-four .banner-content p br {
        display: none;
    }
    .banner.banner-four .promo-mockup {
        margin-top: -30px;
    }
    .banner.banner-five {
        height: auto;
        padding: 100px 0 100px;
    }
    .banner.banner-five .banner-content-wrap-five .banner-content {
        max-width: 500px;
        margin: 0 auto 50px;
        text-align: center;
        background: rgba(255, 255, 255, 0.3);
        padding: 35px;
        border-radius: 10px;
    }
    .banner.banner-five .banner-content-wrap-five .banner-content .sub-titl {
        font-size: 14px;
        margin-bottom: 5px;
    }
    .banner.banner-five .banner-content-wrap-five .banner-content .banner-title {
        font-size: 34px;
        line-height: 44px;
    }
    .banner.banner-five .banner-content-wrap-five .banner-content .description {
        font-size: 14px;
    }
    .banner.banner-six {
        height: 650px;
        padding-top: 145px;
    }
    .banner.banner-six .banne-content-wrapper-six .banner-title {
        font-size: 30px;
        line-height: 40px;
    }
    .banner .animate-element-five {
        position: relative;
        margin: 0 auto;
    }
}

@media (max-width: 768px) {
    .banner.banner-one {
        height: 950px;
    }
    .banner.banner-one .banner-content-wrap {
        -webkit-transform: translateY(20%);
        -ms-transform: translateY(20%);
        transform: translateY(20%);
    }
    .banner.banner-one .promo-mockup {
        margin-left: -45px;
    }
    .banner.banner-one .promo-mockup img {
        max-width: 100%;
    }
    .banner.banner-two {
        height: 840px;
    }
    .banner.banner-two .vector-bg {
        right: -120%;
    }
    .banner.banner-two .banner-content-wrap {
        -webkit-transform: translateY(18%);
        -ms-transform: translateY(18%);
        transform: translateY(18%);
    }
    .banner.banner-two .animate-promo-mockup {
        width: 360px;
        height: 370px;
        margin-left: 0;
    }
    .banner.banner-two .animate-promo-mockup img:nth-child(1) {
        left: 65px;
    }
    .banner.banner-two .animate-promo-mockup img:nth-child(2) {
        width: 65px;
    }
    .banner.banner-two .animate-promo-mockup img:nth-child(3) {
        right: -100px;
        width: 50px;
    }
    .banner.banner-two .animate-promo-mockup img:nth-child(4) {
        left: 55px;
        width: 125px;
        bottom: 30px;
    }
    .banner.banner-two .animate-promo-mockup img:nth-child(5) {
        left: 55%;
        width: 40px;
    }
    .banner.banner-two .animate-promo-mockup img:nth-child(6) {
        right: -3px;
        width: 170px;
        bottom: 13px;
    }
    .banner.banner-two .animate-promo-mockup img:nth-child(7) {
        right: -35px;
        width: 67px;
        bottom: 50px;
    }
    .banner.banner-three {
        height: 750px;
    }
    .banner.banner-three .banner-content {
        margin-bottom: 50px;
    }
    .banner.banner-three .banner-content .banner-title {
        font-size: 34px;
        line-height: 44px;
    }
    .banner.banner-three .banner-content .description br {
        display: none;
    }
    .banner.banner-three .banner-content-wrap-two {
        padding-top: 130px;
    }
    .banner.banner-three .circle-shape {
        left: -85% !important;
    }
    .banner.banner-three .shape {
        right: -60% !important;
    }
    .banner.banner-four {
        height: auto;
        padding-top: 120px;
    }
    .banner.banner-four .banner-content .banner-title {
        font-size: 40px;
        line-height: 55px;
    }
    .banner.banner-four .banner-content p {
        font-size: 16px;
    }
    .banner.banner-four .banner-content p br {
        display: none;
    }
    .banner.banner-four .promo-mockup {
        margin-top: -30px;
    }
    .banner.banner-six {
        height: auto;
        padding-bottom: 80px;
    }
    .banner.banner-six .banne-content-wrapper-six {
        margin-bottom: 30px;
    }
}

@media (max-width: 640px) {
    .banner.banner-two .vector-bg {
        left: -45%;
    }
    .banner.banner-two .vector-bg {
        left: -52%;
    }
    .banner.banner-three .circle-shape {
        left: -95% !important;
    }
    .banner.banner-three .shape {
        right: -70% !important;
    }
}

@media (max-width: 576px) {
    .banner .banner-content .description {
        font-size: 16px;
    }
    .banner.banner-one {
        height: 840px;
    }
    .banner.banner-one .promo-mockup {
        margin-left: 0;
    }
    .banner.banner-one .banner-content .banner-title {
        font-size: 35px;
        line-height: 45px;
    }
    .banner.banner-two .vector-bg {
        left: -50%;
    }
    .banner.banner-three {
        height: 780px;
    }
    .banner.banner-three .circle-shape {
        left: -130% !important;
    }
    .banner.banner-three .shape {
        right: -90% !important;
    }
    .banner.banner-three .shape-shadow {
        display: none;
    }
    .banner.banner-three .promo-mockup img {
        max-width: 400px;
    }
}

@media (max-width: 480px) {
    .banner.banner-one {
        height: 770px;
    }
    .banner.banner-one .banner-content-wrap {
        -webkit-transform: translateY(20%);
        -ms-transform: translateY(20%);
        transform: translateY(20%);
    }
    .banner.banner-one .promo-mockup img {
        max-width: 100%;
    }
    .banner.banner-two {
        height: 840px;
    }
    .banner.banner-two .vector-bg {
        left: -100%;
    }
    .banner.banner-two .banner-content-wrap {
        -webkit-transform: translateY(18%);
        -ms-transform: translateY(18%);
        transform: translateY(18%);
    }
    .banner.banner-two .animate-promo-mockup {
        width: 300px;
        height: 325px;
        margin-left: 0;
        margin-top: 20px;
    }
    .banner.banner-two .animate-promo-mockup img:nth-child(1) {
        left: 30px;
    }
    .banner.banner-two .animate-promo-mockup img:nth-child(2) {
        width: 40px;
    }
    .banner.banner-two .animate-promo-mockup img:nth-child(3) {
        right: -30px;
        width: 30px;
        bottom: 130px;
    }
    .banner.banner-two .animate-promo-mockup img:nth-child(4) {
        left: 20px;
        width: 80px;
        bottom: 56px;
    }
    .banner.banner-two .animate-promo-mockup img:nth-child(5) {
        left: 49%;
        width: 30px;
        bottom: 45px;
    }
    .banner.banner-two .animate-promo-mockup img:nth-child(6) {
        right: 20px;
        width: 140px;
        bottom: 30px;
    }
    .banner.banner-two .animate-promo-mockup img:nth-child(7) {
        right: -5px;
        width: 50px;
        bottom: 70px;
    }
    .banner.banner-three {
        height: auto;
        padding-bottom: 40px;
    }
    .banner.banner-three .banner-content .banner-title {
        font-size: 30px;
        line-height: 40px;
    }
    .banner.banner-three .promo-mockup img {
        max-width: 100%;
    }
    .banner.banner-six .banne-content-wrapper-six .banner-title {
        font-size: 24px;
        line-height: 34px;
    }
    .left-circle-shape {
        top: 28%;
    }
    .animate-element-five {
        width: 300px;
        height: 280px;
    }
    .animate-element-five li:nth-child(1) {
        width: 250px;
    }
    .animate-element-five li:nth-child(2) {
        width: 45px;
        height: 45px;
        left: 15px;
        top: 45px;
    }
    .animate-element-five li:nth-child(3) {
        width: 45px;
        height: 45px;
    }
    .animate-element-five li:nth-child(4) {
        width: 55px;
        bottom: 18px;
    }
    .animate-element-five li:nth-child(5) {
        width: 160px;
        left: -30px;
        bottom: -10px;
    }
    .animate-element-five li:nth-child(6) {
        bottom: -6px;
        left: 41%;
        width: 70px;
    }
    .animate-element-five li:nth-child(7) {
        bottom: 65px;
        left: 49%;
        width: 50px;
    }
    .animate-element-five li:nth-child(8) {
        bottom: 62px;
        left: 43%;
        width: 28px;
    }
    .animate-element-five li:nth-child(9) {
        width: 70px;
        right: 36px;
        bottom: 0;
    }
    .left-circle-shape {
        left: -20%;
    }
    .left-circle-shape .circle-fill {
        height: 350px;
        width: 350px;
    }
    .left-circle-shape .circle-border {
        height: 200px;
        width: 200px;
    }
}

@media (max-width: 380px) {
    .banner .banner-button-container {
        display: block;
    }
    .banner .banner-button-container .play-btn {
        margin-top: 30px;
    }
    .banner.banner-five {
        overflow: hidden;
    }
    .newsletter-form-banner .newsletter-inner button {
        padding: 10px 20px;
    }
    .newsletter-form-banner .newsletter-inner input {
        padding: 10px 140px 10px 30px;
    }
}


/*--------------------------------------------------------------
  ##  Section Title
  --------------------------------------------------------------*/

.section-title {
    margin-bottom: 70px;
}

.section-title .sub-title {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 500;
    color: #fa7070;
    letter-spacing: 3px;
}

.section-title .title {
    font-size: 36px;
    font-weight: 600;
    line-height: 1.4;
}

.section-title .title span {
    color: #fa7070;
}

.section-title .title-two {
    font-size: 30px;
    font-weight: 500;
    line-height: 44px;
    max-width: 930px;
    margin: 0 auto;
}

.section-title .title-two span {
    color: #fa7070;
}

.section-title.style-two .title {
    font-weight: 500;
}

.section-title.style-two p {
    font-size: 18px;
    line-height: 30px;
    margin-top: 28px;
}

.section-title.style-two .sub-title {
    margin: 0;
    line-height: 1.8;
}

.section-title.style-two.title-light .sub-title {
    letter-spacing: 5px;
    font-weight: 400;
}

.section-title.style-two.title-light .sub-title,
.section-title.style-two.title-light .title {
    color: #fff;
}

.section-title.style-two.title-light p {
    color: #fff;
}

.section-title.style-three .sub-title {
    color: #f97bc5;
    letter-spacing: 5px;
    font-weight: 400;
    margin: 0;
    line-height: 1.8;
}

.section-title.style-three span {
    font-weight: 300;
    color: #2b2350;
}

.section-title.dark-title .sub-title,
.section-title.dark-title .title {
    color: #fff;
}

.section-title.color-two .sub-title {
    color: #7052fb;
}

.section-title.style-four .title {
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 12px;
}

@media (max-width: 991px) {
    .section-title.style-four .title {
        font-size: 32px;
    }
}

.section-title.style-four .sub-title {
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 3px;
    color: #7d7a8b;
    text-transform: uppercase;
    line-height: 1.4;
}

.section-title.style-five {
    margin-bottom: 55px;
}

.section-title.style-five .sub-title {
    color: #12bf7c;
    font-size: 14px;
    letter-spacing: 4px;
    font-weight: 500;
    margin-bottom: 13px;
}

.section-title.style-five .title {
    font-size: 40px;
    color: #1a133b;
    font-weight: 600;
}

@media (max-width: 991px) {
    .section-title.style-five .title {
        font-size: 32px;
    }
}

@media (max-width: 768px) {
    .section-title.style-five .title {
        font-size: 30px;
    }
}

.section-title.style-six .title {
    font-size: 36px;
    color: #1a133b;
    font-weight: 700;
}

.section-title.style-six .title span {
    font-weight: 300;
    color: #1a133b;
    display: block;
}

.section-title.style-seven .title {
    font-size: 34px;
    line-height: 50px;
    font-weight: 600;
    color: #2c234d;
    margin-bottom: 15px;
}

.section-title.style-seven.color-light .title {
    color: #fff;
}

.section-title.style-seven.color-light p {
    color: #fff;
}

.section-title.style-seven p {
    color: #6d7682;
}

.section-title.style-eight .sub-title {
    font-family: 'Satisfy', cursive;
    color: #7052fb;
    text-transform: capitalize;
    font-weight: 400;
    font-size: 20px;
}

.section-title.style-eight .title {
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 19px;
    line-height: 1.2;
}

@media (max-width: 991px) {
    .section-title.style-eight .title {
        font-size: 32px;
    }
}

@media (max-width: 991px) {
    .section-title {
        margin-bottom: 50px;
    }
    .section-title .title {
        font-size: 32px;
    }
    .section-title p br {
        display: none;
    }
    .section-title.style-two p {
        margin-top: 13px;
    }
    .section-title.style-two p br {
        display: none;
    }
    .section-title .title-two {
        font-size: 26px;
        line-height: 36px;
    }
}

@media (max-width: 768px) {
    .section-title .title {
        font-size: 28px;
    }
    .section-title .title br {
        display: none;
    }
}


/*--------------------------------------------------------------
  ##  Icon Box
  --------------------------------------------------------------*/

.featured {
    padding-top: 32px;
}

.featured-seven {
    padding: 115px 0 50px;
}

.featured-eight {
    padding: 117px 0 90px;
}

.featured-eight .top-bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
}

.featured-eight .top-bg img {
    width: 100%;
}

.saaspik-icon-box-wrapper {
    margin-bottom: 30px;
}

.saaspik-icon-box-wrapper.style-one {
    margin-top: 16px;
}

.saaspik-icon-box-wrapper.style-one .saaspik-icon-box-icon {
    margin-bottom: 40px;
    height: 150px;
}

.saaspik-icon-box-wrapper.style-one .pixsass-icon-box-content .pixsass-icon-box-title {
    font-size: 22px;
    font-weight: 500;
    line-height: 30px;
    margin-bottom: 0;
}

.saaspik-icon-box-wrapper.style-one .pixsass-icon-box-content .pixsass-icon-box-title a {
    color: #2b2350;
}

.saaspik-icon-box-wrapper.style-one .pixsass-icon-box-content .pixsass-icon-box-title a:hover {
    color: #fa7070;
}

.saaspik-icon-box-wrapper .saaspik-icon-box-icon {
    margin-bottom: 32px;
}

.saaspik-icon-box-wrapper .pixsass-icon-box-content .pixsass-icon-box-title {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    margin-bottom: 20px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.saaspik-icon-box-wrapper .pixsass-icon-box-content .pixsass-icon-box-title a {
    color: #2b2350;
}

.saaspik-icon-box-wrapper .pixsass-icon-box-content .pixsass-icon-box-title a:hover {
    color: #fa7070;
}

.saaspik-icon-box-wrapper .pixsass-icon-box-content p {
    margin: 0;
}

.saaspik-icon-box-wrapper.style-two {
    margin-bottom: 88px;
}

.saaspik-icon-box-wrapper.style-two .pixsass-icon-box-content .pixsass-icon-box-title {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    margin-bottom: 10px;
}

.saaspik-icon-box-wrapper.style-two .pixsass-icon-box-content .pixsass-icon-box-title a {
    color: #2b2350;
}

.saaspik-icon-box-wrapper.style-two .pixsass-icon-box-content .pixsass-icon-box-title a:hover {
    color: #fa7070;
}

.saaspik-icon-box-wrapper.style-three {
    border-radius: 6px;
    background-color: white;
    -webkit-box-shadow: 0px 40px 60px 0px rgba(79, 35, 35, 0.14);
    box-shadow: 0px 40px 60px 0px rgba(79, 35, 35, 0.14);
    padding: 40px 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    position: relative;
    z-index: 22;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.saaspik-icon-box-wrapper.style-three .saaspik-icon-box-icon {
    width: 70px;
    margin-right: 30px;
    margin-bottom: 0;
}

.saaspik-icon-box-wrapper.style-three .pixsass-icon-box-content {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.saaspik-icon-box-wrapper.style-three .pixsass-icon-box-content p {
    margin: 0;
}

.saaspik-icon-box-wrapper.style-four {
    border-radius: 4px;
    border: 1px solid #ece4e4;
    padding: 60px 40px 32px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    position: relative;
    overflow: hidden;
}

.saaspik-icon-box-wrapper.style-four .layer {
    position: absolute;
    top: -100%;
    left: 0;
    width: 100%;
    z-index: -1;
    -webkit-transition: all 0.6s ease-in-out;
    -o-transition: all 0.6s ease-in-out;
    transition: all 0.6s ease-in-out;
}

.saaspik-icon-box-wrapper.style-four .saaspik-icon-box-icon {
    margin-bottom: 44px;
    height: 80px;
}

.saaspik-icon-box-wrapper.style-four .pixsass-icon-box-content .pixsass-icon-box-title {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 25px;
}

.saaspik-icon-box-wrapper.style-four .pixsass-icon-box-content .pixsass-icon-box-title a {
    color: #2b2350;
}

.saaspik-icon-box-wrapper.style-four .pixsass-icon-box-content .pixsass-icon-box-title a:hover {
    color: #fa7070;
}

.saaspik-icon-box-wrapper.style-four .pixsass-icon-box-content p {
    margin-bottom: 22px;
}

.saaspik-icon-box-wrapper.style-four .pixsass-icon-box-content .more-btn {
    font-size: 35px;
    color: #bdb4b4;
    display: inline-block;
}

.saaspik-icon-box-wrapper.style-four .pixsass-icon-box-content .more-btn:hover {
    color: #fa7070;
}

.saaspik-icon-box-wrapper.style-four:hover {
    -webkit-box-shadow: 0px 40px 60px 0px rgba(79, 35, 35, 0.14);
    box-shadow: 0px 40px 60px 0px rgba(79, 35, 35, 0.14);
}

.saaspik-icon-box-wrapper.style-four:hover .layer {
    top: 0;
}

.saaspik-icon-box-wrapper.style-five {
    -webkit-box-shadow: 0px 30px 60px 0px rgba(52, 27, 159, 0.1);
    box-shadow: 0px 30px 60px 0px rgba(52, 27, 159, 0.1);
    padding: 45px 40px;
    background: #fff;
}

.saaspik-icon-box-wrapper.style-five .saaspik-icon-box-icon {
    height: 60px;
    width: 60px;
    line-height: 60px;
    text-align: center;
    border: 2px solid #7052fb;
    color: #7052fb;
    font-size: 22px;
    border-radius: 50%;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    margin-bottom: 42px;
}

.saaspik-icon-box-wrapper.style-five .pixsass-icon-box-content .pixsass-icon-box-title {
    margin-bottom: 15px;
}

.saaspik-icon-box-wrapper.style-five .pixsass-icon-box-content .pixsass-icon-box-title a:hover {
    color: #7052fb;
}

.saaspik-icon-box-wrapper.style-five .pixsass-icon-box-content p {
    margin-bottom: 35px;
}

.saaspik-icon-box-wrapper.style-five .pixsass-icon-box-content .more-btn {
    color: #2b2350;
    font-weight: 500;
}

.saaspik-icon-box-wrapper.style-five .pixsass-icon-box-content .more-btn i {
    vertical-align: middle;
    display: inline-block;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.saaspik-icon-box-wrapper.style-five .pixsass-icon-box-content .more-btn:hover {
    color: #7052fb;
}

.saaspik-icon-box-wrapper.style-five .pixsass-icon-box-content .more-btn:hover i {
    padding-left: 3px;
    color: #7052fb;
}

.saaspik-icon-box-wrapper.style-five:hover .saaspik-icon-box-icon {
    -webkit-box-shadow: 0px 20px 30px 0px rgba(75, 42, 222, 0.2);
    box-shadow: 0px 20px 30px 0px rgba(75, 42, 222, 0.2);
    background: #7052fb;
    color: #fff;
}

.saaspik-icon-box-wrapper.style-six {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 40px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.saaspik-icon-box-wrapper.style-six .saaspik-icon-box-icon {
    height: 70px;
    width: 70px;
    text-align: center;
    -webkit-box-shadow: 0px 30px 40px 0px rgba(52, 27, 159, 0.1);
    box-shadow: 0px 30px 40px 0px rgba(52, 27, 159, 0.1);
    padding: 15px;
    border-radius: 4px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    margin-bottom: 0;
    margin-right: 30px;
    background: #fff;
}

.saaspik-icon-box-wrapper.style-six .pixsass-icon-box-content {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.saaspik-icon-box-wrapper.style-six .pixsass-icon-box-content .pixsass-icon-box-title {
    margin-bottom: 15px;
    font-weight: 400;
    margin: 0;
}

.saaspik-icon-box-wrapper.style-six .pixsass-icon-box-content .pixsass-icon-box-title a:hover {
    color: #7052fb;
}

.saaspik-icon-box-wrapper.style-six .pixsass-icon-box-content p {
    margin-bottom: 35px;
}

.saaspik-icon-box-wrapper.style-six .pixsass-icon-box-content .more-btn {
    color: #2b2350;
    font-weight: 500;
}

.saaspik-icon-box-wrapper.style-six .pixsass-icon-box-content .more-btn i {
    vertical-align: middle;
    display: inline-block;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.saaspik-icon-box-wrapper.style-six .pixsass-icon-box-content .more-btn:hover {
    color: #7052fb;
}

.saaspik-icon-box-wrapper.style-six .pixsass-icon-box-content .more-btn:hover i {
    padding-left: 3px;
    color: #7052fb;
}

.saaspik-icon-box-wrapper.style-seven {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 90px;
}

.saaspik-icon-box-wrapper.style-seven .saaspik-icon-box-icon {
    width: 60px;
    margin-right: 20px;
}

.saaspik-icon-box-wrapper.style-seven .saaspik-icon-box-content {
    -webkit-box-flex: 2;
    -ms-flex: 2;
    flex: 2;
}

.saaspik-icon-box-wrapper.style-seven .saaspik-icon-box-content .saaspik-icon-box-title {
    font-size: 20px;
    color: #081e39;
    font-weight: 600;
    margin-bottom: 13px;
}

.saaspik-icon-box-wrapper.style-seven .saaspik-icon-box-content .saaspik-icon-box-title a {
    color: #081e39;
}

.saaspik-icon-box-wrapper.style-seven .saaspik-icon-box-content .saaspik-icon-box-title a:hover {
    color: #7052fb;
}

.saaspik-icon-box-wrapper.style-seven .saaspik-icon-box-content p {
    color: #5e5b74;
    margin-bottom: 17px;
}

.saaspik-icon-box-wrapper.style-seven .saaspik-icon-box-content .read-more {
    color: #5e5b74;
    font-size: 16px;
    font-weight: 500;
}

.saaspik-icon-box-wrapper.style-seven .saaspik-icon-box-content .read-more:hover {
    color: #7052fb;
}

.saaspik-icon-box-wrapper.style-eight {
    border-radius: 6px;
    background: #fff;
    -webkit-box-shadow: 0px 30px 50px 0px rgba(64, 24, 47, 0.08);
    box-shadow: 0px 30px 50px 0px rgba(64, 24, 47, 0.08);
    text-align: center;
    padding: 40px 35px;
}

.saaspik-icon-box-wrapper.style-eight .pixsass-icon-box-content .pixsass-icon-box-title {
    margin-bottom: 15px;
}

.saaspik-icon-box-wrapper.style-eight .pixsass-icon-box-content .pixsass-icon-box-title a:hover {
    color: #f97bc5;
}

.saaspik-icon-box-wrapper.style-eight .pixsass-icon-box-content p {
    margin-bottom: 35px;
}

.saaspik-icon-box-wrapper.style-nine {
    margin-bottom: 90px;
}

.saaspik-icon-box-wrapper.style-nine .saaspik-icon-box-icon {
    height: 70px;
    width: 70px;
    background: #fee4f4;
    border-radius: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.saaspik-icon-box-wrapper.style-nine .saaspik-icon-box-icon.color-two {
    background: #fcf5e3;
}

.saaspik-icon-box-wrapper.style-nine .saaspik-icon-box-icon.color-three {
    background: #efe9fe;
}

.saaspik-icon-box-wrapper.style-nine .saaspik-icon-box-icon.color-four {
    background: #eaf7eb;
}

.saaspik-icon-box-wrapper.style-nine .saaspik-icon-box-icon.color-five {
    background: #e4f8fd;
}

.saaspik-icon-box-wrapper.style-nine .saaspik-icon-box-icon.color-six {
    background: #ffeae7;
}

.saaspik-icon-box-wrapper.style-nine .pixsass-icon-box-content .pixsass-icon-box-title {
    margin-bottom: 10px;
}

.saaspik-icon-box-wrapper.style-nine .pixsass-icon-box-content .pixsass-icon-box-title a:hover {
    color: #f97bc5;
}

.saaspik-icon-box-wrapper.style-ten {
    padding: 50px 40px;
    border-radius: 10px;
    background-color: white;
    -webkit-box-shadow: 0px 20px 60px 0px rgba(1, 51, 32, 0.1);
    box-shadow: 0px 20px 60px 0px rgba(1, 51, 32, 0.1);
    text-align: center;
}

.saaspik-icon-box-wrapper.style-ten .pixsass-icon-box-content .pixsass-icon-box-title {
    margin-bottom: 15px;
}

.saaspik-icon-box-wrapper.style-ten .pixsass-icon-box-content p {
    margin-bottom: 30px;
}

.saaspik-icon-box-wrapper.style-eleven {
    -webkit-box-shadow: 0 20px 50px 0 rgba(14, 3, 52, 0.1);
    box-shadow: 0 20px 50px 0 rgba(14, 3, 52, 0.1);
    background: #fff;
    border-radius: 20px;
    padding: 55px 35px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.saaspik-icon-box-wrapper.style-eleven .saaspik-icon-box-icon {
    height: 65px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
}

.saaspik-icon-box-wrapper.style-eleven .pixsass-icon-box-content .pixsass-icon-box-title {
    margin-bottom: 13px;
}

.saaspik-icon-box-wrapper.style-eleven .pixsass-icon-box-content p {
    color: #615f6b;
}

.saaspik-icon-box-wrapper.style-eleven:hover {
    background: #2c234d;
}

.saaspik-icon-box-wrapper.style-eleven:hover .pixsass-icon-box-content .pixsass-icon-box-title {
    color: #fff;
}

.saaspik-icon-box-wrapper.style-eleven:hover .pixsass-icon-box-content .pixsass-icon-box-title:hover {
    color: #f98e47;
}

.saaspik-icon-box-wrapper.style-eleven:hover .pixsass-icon-box-content .pixsass-icon-box-title a {
    color: #fff;
}

.saaspik-icon-box-wrapper.style-eleven:hover .pixsass-icon-box-content .pixsass-icon-box-title a:hover {
    color: #f98e47;
}

.saaspik-icon-box-wrapper.style-eleven:hover .pixsass-icon-box-content p {
    color: #c3c0d0;
}

.saaspik-icon-box-wrapper.style-twelve {
    padding: 40px 35px;
    border-radius: 6px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    margin-bottom: 20px;
}

.saaspik-icon-box-wrapper.style-twelve .saaspik-icon-box-icon {
    height: 70px;
    width: 70px;
    border-radius: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background: rgba(19, 200, 70, 0.102);
}

.saaspik-icon-box-wrapper.style-twelve .saaspik-icon-box-icon.color--two {
    background: #fff3dd;
}

.saaspik-icon-box-wrapper.style-twelve .saaspik-icon-box-icon.color--three {
    background: #efe1fa;
}

.saaspik-icon-box-wrapper.style-twelve .saaspik-icon-box-icon.color--four {
    background: #f6d9e2;
}

.saaspik-icon-box-wrapper.style-twelve .saaspik-icon-box-icon.color--five {
    background: #dbddfa;
}

.saaspik-icon-box-wrapper.style-twelve .saaspik-icon-box-icon.color--six {
    background: #d1f0f1;
}

.saaspik-icon-box-wrapper.style-twelve .pixsass-icon-box-content .pixsass-icon-box-title {
    font-size: 20px;
    font-weight: 500;
    color: #2c234d;
}

.saaspik-icon-box-wrapper.style-twelve .pixsass-icon-box-content .pixsass-icon-box-title:hover {
    color: #14aeee;
}

.saaspik-icon-box-wrapper.style-twelve .pixsass-icon-box-content .pixsass-icon-box-title a {
    color: #2c234d;
}

.saaspik-icon-box-wrapper.style-twelve .pixsass-icon-box-content .pixsass-icon-box-title a:hover {
    color: #14aeee;
}

.saaspik-icon-box-wrapper.style-twelve:hover {
    background: #fff;
    -webkit-box-shadow: 0 30px 70px 0 rgba(5, 34, 85, 0.06);
    box-shadow: 0 30px 70px 0 rgba(5, 34, 85, 0.06);
}

.featured-six {
    padding: 110px 0 104px;
}

.featured-five {
    padding: 70px 0;
}

.featured-eight .particale-top {
    width: 100%;
    height: 500px;
    margin: 0;
    padding: 0;
    list-style: none;
    position: absolute;
    top: 0;
    left: 0;
}

.featured-eight .particale-top li {
    position: absolute;
}

.featured-eight .particale-top li:nth-child(1) {
    width: 31px;
    height: 31px;
    border-radius: 50%;
    left: 8%;
    top: 40%;
    background-color: rgba(255, 255, 255, 0.141);
    -webkit-animation: animationFramesOne 20s infinite linear;
    animation: animationFramesOne 20s infinite linear;
}

.featured-eight .particale-top li:nth-child(2) {
    left: 16%;
    top: 54%;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 35px solid rgba(255, 255, 255, 0.141);
    -webkit-animation: animationFramesLeft 20s infinite linear;
    animation: animationFramesLeft 20s infinite linear;
}

.featured-eight .particale-top li:nth-child(3) {
    left: 11%;
    bottom: 28px;
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-right: 20px solid rgba(255, 255, 255, 0.141);
    -webkit-animation: animationFramesTwo 20s infinite linear;
    animation: animationFramesTwo 20s infinite linear;
}

.featured-eight .particale-top li:nth-child(4) {
    width: 13px;
    height: 13px;
    border-radius: 50%;
    right: 12%;
    top: 44%;
    background-color: rgba(255, 255, 255, 0.141);
    -webkit-animation: animationFramesFive 20s infinite linear;
    animation: animationFramesFive 20s infinite linear;
}

.featured-eight .particale-top li:nth-child(5) {
    right: 0;
    top: 33%;
    width: 0;
    height: 0;
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
    border-bottom: 50px solid rgba(255, 255, 255, 0.141);
    -webkit-animation: animationFramesLeft 20s infinite linear;
    animation: animationFramesLeft 20s infinite linear;
}

.featured-eight .particale-top li:nth-child(6) {
    right: 80px;
    top: 140px;
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-right: 20px solid rgba(255, 255, 255, 0.141);
    -webkit-animation: animationFramesOne 20s infinite linear;
    animation: animationFramesOne 20s infinite linear;
}

.featured-tax {
    padding: 32px 0 22px;
}

.featured-ten {
    padding: 115px 0 90px;
}

@media (max-width: 991px) {
    .featured-ten {
        padding: 75px 0 50px;
    }
}

.featured-two-service .saaspik-icon-box-wrapper.style-two .pixsass-icon-box-content .pixsass-icon-box-title a {
    color: #2b2350;
}

.featured-two-service .saaspik-icon-box-wrapper.style-two .pixsass-icon-box-content .pixsass-icon-box-title a:hover {
    color: #fa7070;
}

.featured-two {
    padding: 126px 0 28px;
}

.featured-two .section-title {
    margin-bottom: 80px;
}

.featured-three {
    padding: 116px 0 120px;
    background: #fdf8f8;
    overflow: hidden;
}

.featured-three .scroll-circle {
    z-index: 1;
    left: -120px;
    bottom: -67%;
}

.featured-four {
    padding-top: 400px;
}

.featured-two-same {
    padding-top: 144px;
    padding-bottom: 27px;
}

.featured-two-same .section-title {
    margin-bottom: 80px;
}

.featured-two-same .saaspik-icon-box-wrapper.style-two .pixsass-icon-box-content .pixsass-icon-box-title a {
    color: #2b2350;
}

.featured-two-same .saaspik-icon-box-wrapper.style-two .pixsass-icon-box-content .pixsass-icon-box-title a:hover {
    color: #7052fb;
}

.featured-two-same .scroll-circle {
    bottom: auto;
    top: -50%;
}

.featured-four-ab {
    padding-top: 146px;
}

.featured-nine {
    margin-bottom: -110px;
    z-index: 22;
    padding-top: 50px;
}

.featured-homethree {
    padding-top: 228px;
    padding-bottom: 27px;
}

.featured-homethree .section-title {
    margin-bottom: 80px !important;
}

.feature-twelve {
    background: #f6f7fa;
    padding: 115px 0 90px;
}

.down-bg {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: -1;
}

@media (max-width: 1200px) {
    .featured-homethree {
        padding-top: 118px;
    }
    .featured-six {
        padding-top: 50px;
    }
}

@media (max-width: 991px) {
    .featured-two {
        padding: 85px 0 35px;
    }
    .featured-eight {
        padding: 72px 0 50px;
    }
    .featured-two-same {
        padding-top: 74px;
        padding-bottom: 44px;
    }
    .featured {
        padding-top: 75px;
    }
    .featured-three {
        padding: 76px 0 80px;
    }
    .featured-homethree {
        padding-top: 0;
    }
    .saaspik-icon-box-wrapper.style-two {
        margin-bottom: 40px;
        text-align: center;
    }
    .featured-four {
        padding-top: 185px;
        padding-bottom: 40px;
    }
    .featured-homethree {
        padding-bottom: 34px;
    }
    .featured-four-ab {
        padding-top: 76px;
    }
    .featured-two-service {
        padding: 55px 0 43px !important;
    }
    .featured-two-same .scroll-circle {
        left: -20%;
    }
    .featured-three .scroll-circle {
        z-index: 1;
        left: -15%;
        bottom: 20%;
    }
    .featured-three .scroll-circle img {
        max-width: 450px;
    }
    .saaspik-icon-box-wrapper.style-one {
        text-align: center;
    }
    .saaspik-icon-box-wrapper .pixsass-icon-box-content .pixsass-icon-box-title a br {
        display: none;
    }
    .featured-six {
        padding-top: 0;
        padding-bottom: 38px;
    }
    .featured-seven {
        padding: 75px 0 30px;
    }
    .saaspik-icon-box-wrapper.style-seven {
        margin-bottom: 50px;
    }
    .saaspik-icon-box-wrapper.style-eight .pixsass-icon-box-content p br {
        display: none;
    }
}

@media (max-width: 768px) {
    .saaspik-icon-box-wrapper .pixsass-icon-box-content p br {
        display: none;
    }
    .featured-four {
        padding-top: 250px;
    }
    .featured-two-same .scroll-circle {
        left: -30%;
    }
    .featured-three .scroll-circle {
        z-index: 1;
        left: -15%;
        bottom: 20%;
    }
    .featured-three .scroll-circle img {
        max-width: 330px;
    }
    .feature-image-wrapper {
        margin-bottom: 40px;
    }
    .featured-eight .top-bg {
        background: #f97bc5;
        min-height: 350px;
    }
}

@media (max-width: 767px) {
    .featured-four {
        padding-top: 137px;
    }
}

@media (max-width: 576px) {
    .featured-four {
        padding-top: 76px;
    }
}

@media (max-width: 480px) {
    .featured-two-same .scroll-circle {
        left: -70%;
    }
    .saaspik-icon-box-wrapper.style-three {
        text-align: center;
        display: block;
    }
    .saaspik-icon-box-wrapper.style-three .saaspik-icon-box-icon {
        margin: 0 auto 30px;
    }
}


/*--------------------------------------------------------------
  ##  Editor
  --------------------------------------------------------------*/

.editor-design {
    padding-top: 113px;
    position: relative;
    padding-bottom: 170px;
}

.editor-design-two {
    padding-top: 210px;
    padding-bottom: 50px;
}

.editure-feature-image {
    position: absolute;
    left: 75px;
}

.editure-feature-image .image-one {
    max-width: 720px;
}

.editure-feature-image .image-two {
    top: 50%;
    position: absolute;
    right: -150px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.editor-content {
    padding-left: 105px;
    padding-top: 50px;
}

.editor-content .section-title.style-two {
    margin-bottom: 35px;
}

.editor-content .section-title.style-two p {
    color: #fa7070;
    margin-bottom: 0px;
}

.editor-content .section-title.style-two .title {
    margin-bottom: 20px;
}

.editor-content .section-title.style-two.color-two {
    margin-bottom: 35px;
}

.editor-content .section-title.style-two.color-two p {
    color: #7052fb;
    margin: 0;
}

.editor-content .description p {
    margin-bottom: 46px;
}

.shape-bg {
    position: absolute;
    top: -188px;
    left: -10px;
    z-index: -1;
}

.animaated-elements {
    position: relative;
    height: 540px;
    width: 570px;
}

.animaated-elements img,
.animaated-elements .elm-mass {
    position: absolute;
}

.animaated-elements .elm-clock {
    left: 20px;
}

.animaated-elements .elm-man {
    left: 127px;
    bottom: 39px;
}

.animaated-elements .elm-table {
    bottom: 40px;
    left: 45px;
    z-index: 2;
}

.animaated-elements .elm-sm-vase {
    left: 70px;
    bottom: 254px;
}

.animaated-elements .elm-vase {
    bottom: 40px;
    right: -27px;
    z-index: 1;
}

.animaated-elements .elm-mass {
    right: 103px;
    top: 92px;
    width: 135px;
}

.animaated-elements .elm-mass .mass-img {
    -webkit-animation: jumping 1.6s infinite;
    animation: jumping 1.6s infinite;
}

.animaated-elements-two {
    position: relative;
    height: 560px;
    width: 570px;
    margin-top: 87px;
    z-index: 22;
}

.animaated-elements-two .elm-two {
    left: 20px;
    bottom: 85px;
}

.animaated-elements-two .elm-three {
    top: -115px;
    right: 40px;
    -webkit-animation: jump 2s infinite;
    animation: jump 2s infinite;
}

.animaated-elements-two .elm-four {
    right: 192px;
    bottom: 38px;
}

.animaated-elements-two img {
    position: absolute;
}

.genera-informes-content {
    position: relative;
    z-index: 22;
}


/** Image Content Three **/

#job_image_content {
    padding: 120px 0 80px;
}

@media (max-width: 991px) {
    #job_image_content {
        padding: 80px 0 40px;
    }
}

.animaated-elements-three {
    position: relative;
    margin-left: -190px;
    height: 520px;
}

@media (max-width: 480px) {
    .animaated-elements-three {
        height: 440px;
    }
}

@media (max-width: 576px) {
    .animaated-elements-three {
        margin-left: 0;
    }
}

.animaated-elements-three .map-bg {
    position: absolute;
    top: 100px;
}

.animaated-elements-three .image-one,
.animaated-elements-three .image-two,
.animaated-elements-three .image-three {
    position: absolute;
}

.animaated-elements-three .image-one {
    top: 0;
    left: 140px;
}

@media (max-width: 576px) {
    .animaated-elements-three .image-one {
        left: 0;
    }
}

.animaated-elements-three .image-two {
    right: 50px;
    top: 160px;
}

@media (max-width: 768px) {
    .animaated-elements-three .image-two {
        right: -80px;
    }
}

@media (max-width: 480px) {
    .animaated-elements-three .image-two {
        top: 60px;
    }
}

.animaated-elements-three .image-three {
    top: 291px;
    left: 73px;
}

@media (max-width: 576px) {
    .animaated-elements-three .image-three {
        top: 230px;
        left: -45px;
    }
}

@media (max-width: 480px) {
    .animaated-elements-three .image-three {
        top: 175px;
        left: -45px;
    }
}

.animaated-elements-four {
    position: relative;
    height: 705px;
    margin-right: -100px;
}

@media (max-width: 991px) {
    .animaated-elements-four {
        margin-right: 0;
    }
}

@media (max-width: 480px) {
    .animaated-elements-four {
        height: 560px;
    }
}

.animaated-elements-four .image-one,
.animaated-elements-four .image-two,
.animaated-elements-four .image-three,
.animaated-elements-four .image-four,
.animaated-elements-four .element-bg-shape {
    position: absolute;
}

.animaated-elements-four .element-bg-shape {
    right: -25px;
    top: 155px;
}

.animaated-elements-four .image-one {
    left: 130px;
    top: 60px;
    z-index: 2;
}

@media (max-width: 991px) {
    .animaated-elements-four .image-one {
        left: 60px;
    }
}

@media (max-width: 480px) {
    .animaated-elements-four .image-one {
        left: 15px;
        max-width: 300px;
    }
}

.animaated-elements-four .image-two {
    top: 0;
    left: 300px;
}

@media (max-width: 991px) {
    .animaated-elements-four .image-two {
        left: 145px;
    }
}

@media (max-width: 480px) {
    .animaated-elements-four .image-two {
        left: 90px;
    }
}

.animaated-elements-four .image-three {
    z-index: 3;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.animaated-elements-four .image-four {
    bottom: 82px;
    right: 95px;
    z-index: -1;
}

.animaated-elements-four .image-four img {
    -webkit-animation: wave 6s infinite linear;
    animation: wave 6s infinite linear;
}

@media (max-width: 991px) {
    .animaated-elements-four .image-four {
        bottom: 150px;
        right: 30px;
    }
}

@media (max-width: 991px) {
    .image-content-three {
        margin-top: 50px;
    }
}

.image-content-three .section-title {
    margin-bottom: 41px;
}

.image-content-three .section-title .title {
    font-size: 38px;
    line-height: 1.1;
    margin-bottom: 25px;
}

@media (max-width: 991px) {
    .image-content-three .section-title .title {
        font-size: 34px;
    }
}

.image-content-three .section-title p {
    margin-bottom: 18px;
}

.image-content-three.app-content-wrap .section-title {
    margin-bottom: 37px;
}

#app_image_content {
    padding: 150px 0;
}

@media (max-width: 991px) {
    #app_image_content {
        padding: 80px 0;
    }
}

.app-image-content-wrapper-one {
    margin-bottom: 200px;
}

@media (max-width: 991px) {
    .app-image-content-wrapper-one {
        margin-bottom: 100px;
    }
}

.app-download-image {
    height: 500px;
    width: 500px;
    background: #f6ecd8;
    border-radius: 50%;
    text-align: center;
    overflow: hidden;
}

@media (max-width: 576px) {
    .app-download-image {
        width: 380px;
        height: 380px;
        margin: 0 auto;
    }
    .app-download-image img {
        width: 300px;
    }
}

@media (max-width: 420px) {
    .app-download-image {
        width: 300px;
        height: 300px;
        margin: 0 auto;
    }
    .app-download-image img {
        width: 250px;
    }
}

.app-download-image img {
    margin-top: -12px;
}

.job-image-content-wrapper-one {
    margin-bottom: 160px;
}

@media (max-width: 991px) {
    .job-image-content-wrapper-one {
        margin-bottom: 100px;
    }
}

.job-image-content-wrapper-one .animaated-elements-three {
    padding-top: 40px;
}

@media (max-width: 1600px) {
    .editure-feature-image .image-one {
        max-width: 600px;
    }
    .editure-feature-image .image-two {
        margin-left: -124px;
        max-width: 220px;
    }
    .editor-design {
        padding-bottom: 100px;
    }
    .editor-design .shape-bg {
        max-width: 1200px;
    }
}

@media (max-width: 1440px) {
    .editure-feature-image .image-one {
        max-width: 550px;
    }
    .editure-feature-image .image-two {
        right: -130px;
        max-width: 200px;
    }
    .editor-content {
        padding-top: 0;
    }
}

@media (max-width: 1280px) {
    .editor-content {
        padding-left: 0;
    }
    .editure-feature-image {
        left: 20px;
    }
    .editure-feature-image .image-one {
        max-width: 480px;
    }
    .editure-feature-image .image-two {
        margin-left: -100px;
        max-width: 180px;
    }
    .editor-content {
        padding-top: 0;
    }
    .editor-design {
        padding-top: 70px;
        padding-bottom: 100px;
    }
    .editor-design .shape-bg {
        max-width: 1100px;
    }
    .editure-feature-image .image-two {
        right: -120px;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .editor-design-two {
        padding-top: 160px;
    }
    .editor-design {
        padding-top: 100px;
        padding-bottom: 0;
    }
    .editor-content {
        padding-left: 50px;
    }
    .animaated-elements-two {
        left: -90px;
    }
    .genera-informes-content {
        padding-right: 50px;
    }
    .animaated-elements {
        width: 470px;
        height: 440px;
    }
    .animaated-elements .elm-clock {
        width: 120px;
    }
    .animaated-elements .elm-table {
        width: 380px;
        bottom: 25px;
    }
    .animaated-elements .elm-man {
        width: 225px;
    }
    .animaated-elements .elm-sm-vase {
        width: 35px;
        bottom: 205px;
    }
    .animaated-elements .elm-vase {
        width: 120px;
        bottom: 27px;
    }
    .animaated-elements .elm-mass {
        right: 60px;
    }
    .animaated-elements .elm-mass img {
        width: 110px;
    }
    .animaated-elements-two {
        width: 470px;
        height: 460px;
        left: 0;
    }
    .animaated-elements-two .elm-two {
        bottom: 65px;
        width: 120px;
        left: 25px;
    }
    .animaated-elements-two .elm-three {
        width: 180px;
        top: -70px;
    }
    .animaated-elements-two .elm-four {
        width: 160px;
        right: 160px;
    }
}

@media (max-width: 1024px) {
    .editor-content {
        padding-left: 100px;
    }
}

@media (max-width: 991px) {
    .editor-design {
        padding-top: 62px;
        padding-bottom: 70px;
    }
    .editor-design-two {
        padding-top: 90px;
        padding-bottom: 30px;
    }
    .editure-feature-image {
        position: relative;
        left: 0;
    }
    .editure-feature-image .image-two {
        position: absolute;
        top: 50%;
        right: -82px;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }
    .genera-informes-content {
        padding-bottom: 30px;
    }
    .editor-content {
        padding-left: 0;
        padding-top: 17px;
    }
    .editor-content .section-title.style-two.color-two {
        margin-bottom: 20px;
    }
    .editor-content .section-title.style-two .title {
        margin-bottom: 15px;
    }
}

@media (max-width: 768px) {
    .animaated-elements {
        left: -50px;
    }
    .animaated-elements {
        width: 470px;
        height: 440px;
    }
    .animaated-elements .elm-clock {
        width: 120px;
    }
    .animaated-elements .elm-table {
        width: 380px;
        bottom: 25px;
    }
    .animaated-elements .elm-man {
        width: 225px;
    }
    .animaated-elements .elm-sm-vase {
        width: 35px;
        bottom: 205px;
    }
    .animaated-elements .elm-vase {
        width: 120px;
        bottom: 27px;
    }
    .animaated-elements .elm-mass {
        right: 60px;
    }
    .animaated-elements .elm-mass img {
        width: 110px;
    }
    .animaated-elements-two {
        width: 470px;
        height: 460px;
        margin-top: 35px;
    }
    .animaated-elements-two .elm-two {
        bottom: 65px;
        width: 120px;
        left: 25px;
    }
    .animaated-elements-two .elm-three {
        width: 180px;
        top: -70px;
    }
    .animaated-elements-two .elm-four {
        width: 160px;
        right: 160px;
    }
}

@media (max-width: 576px) {
    .editure-feature-image {
        position: relative;
        left: 0;
        margin-bottom: 30px;
    }
    .editure-feature-image .image-one {
        max-width: 400px;
    }
    .editure-feature-image .image-two {
        right: -80px;
    }
    .editor-content {
        padding-left: 0;
    }
    .animaated-elements {
        width: 320px;
        height: 340px;
        left: 0;
    }
    .animaated-elements .elm-clock {
        width: 80px;
    }
    .animaated-elements .elm-table {
        width: 260px;
        bottom: 35px;
    }
    .animaated-elements .elm-man {
        width: 160px;
    }
    .animaated-elements .elm-sm-vase {
        width: 25px;
        bottom: 158px;
    }
    .animaated-elements .elm-vase {
        width: 70px;
        bottom: 35px;
    }
    .animaated-elements .elm-mass {
        right: -40px;
        top: 80px;
    }
    .animaated-elements .elm-mass img {
        width: 70px;
    }
    .animaated-elements-two {
        width: 330px;
        height: 340px;
    }
    .animaated-elements-two .elm-two {
        bottom: 65px;
        width: 70px;
        left: 25px;
    }
    .animaated-elements-two .elm-three {
        width: 120px;
        top: -25px;
    }
    .animaated-elements-two .elm-four {
        width: 105px;
        right: 120px;
    }
    .genera-informes-two .scroll-circle {
        left: -70% !important;
    }
}

@media (max-width: 480px) {
    .editure-feature-image .image-one {
        max-width: 300px;
    }
    .editure-feature-image .image-two {
        right: 0;
    }
    .editure-feature-image .image-two-inner img {
        max-width: 150px;
    }
    .genera-informes-two .scroll-circle {
        left: -100% !important;
    }
}


/*--------------------------------------------------------------
  ##  Genera Informes
  --------------------------------------------------------------*/

.genera-informes {
    padding-top: 197px;
    padding-bottom: 222px;
    overflow: hidden;
}

.genera-informes .section-title {
    margin-bottom: 30px;
}

.genera-informes .list-items {
    margin-bottom: 50px;
}

.genera-informes .shape-bg {
    left: auto;
    top: 0;
    right: 0;
}

.genera-informes-two {
    padding-top: 105px;
}

.genera-informes-two .section-title {
    margin-top: 115px;
    margin-bottom: 36px;
}

.genera-informes-two .list-items {
    margin-bottom: 50px;
}

.informes-feature-image {
    position: absolute;
    right: 75px;
    top: 143px;
}

.informes-feature-image .image-one {
    max-width: 720px;
}

.informes-feature-image .image-two {
    position: absolute;
    right: 150px;
    bottom: -140px;
    max-width: 720px;
}

.genera-informes-two .scroll-circle {
    top: 60px;
}

.genera-informes-three {
    padding-top: 242px;
    position: relative;
}

.genera-informes-three .shape-bg {
    left: auto;
    right: 0;
    top: 40px;
}

.genera-informes-three .list-items {
    margin-bottom: 50px;
}

.genera-informes-three .section-title {
    margin-bottom: 35px;
}

@media (max-width: 1680px) {
    .informes-feature-image {
        position: absolute;
        right: 50px;
        top: 125px;
    }
    .informes-feature-image .image-one {
        max-width: 620px;
    }
    .informes-feature-image .image-two {
        position: absolute;
        right: 130px;
        bottom: -140px;
    }
    .informes-feature-image .image-two img {
        max-width: 620px !important;
    }
}

@media (max-width: 1600px) {
    .genera-informes {
        padding-bottom: 120px;
    }
    .genera-informes .shape-bg {
        max-width: 500px;
    }
    .informes-feature-image {
        top: 80px;
    }
}

@media (max-width: 1440px) {
    .informes-feature-image {
        position: absolute;
        right: 25px;
        top: 125px;
    }
    .informes-feature-image .image-one {
        max-width: 550px;
    }
    .informes-feature-image .image-two {
        position: absolute;
        right: 100px;
        bottom: -140px;
    }
    .informes-feature-image .image-two img {
        max-width: 550px !important;
    }
}

@media (max-width: 1280px) {
    .genera-informes {
        padding-bottom: 120px;
    }
    .genera-informes .shape-bg {
        max-width: 400px;
    }
    .informes-feature-image {
        top: 70px;
    }
}

@media (max-width: 1200px) {
    .informes-feature-image {
        position: absolute;
        right: 25px;
        top: 100px;
    }
    .informes-feature-image .image-one {
        max-width: 450px;
    }
    .informes-feature-image .image-two {
        position: absolute;
        right: 70px;
        bottom: -140px;
    }
    .informes-feature-image .image-two img {
        max-width: 450px !important;
    }
    .genera-informes {
        padding-top: 140px;
    }
    .genera-informes .shape-bg {
        right: -180px;
    }
    .genera-informes-three {
        padding-top: 180px;
    }
    .genera-informes-three .shape-bg {
        width: 500px;
    }
}

@media (max-width: 1024px) {
    .informes-feature-image {
        position: absolute;
        right: 25px;
        top: 75px;
    }
    .informes-feature-image .image-one {
        max-width: 450px;
    }
    .informes-feature-image .image-two {
        position: absolute;
        right: 70px;
        bottom: -140px;
        max-width: 450px;
    }
    .genera-informes .shape-bg {
        right: -180px;
    }
}

@media (max-width: 991px) {
    .genera-informes {
        padding-top: 0;
        overflow: hidden;
        padding-bottom: 80px;
    }
    .genera-informes .section-title {
        margin-bottom: 20px;
        padding-top: 0;
    }
    .genera-informes .list-items {
        margin-bottom: 40px;
    }
    .genera-informes .informes-feature-image {
        position: relative;
        right: 0;
        top: 0;
        margin-top: 0;
        margin-bottom: 120px;
        padding-top: 0;
    }
    .genera-informes .informes-feature-image .image-one {
        max-width: 450px;
    }
    .genera-informes .informes-feature-image .image-two {
        position: absolute;
        right: -70px;
        bottom: -110px;
        max-width: 450px;
    }
    .informes-feature-image .image-two {
        bottom: -100px;
    }
    .genera-informes-two {
        padding-top: 46px;
    }
    .genera-informes-two .section-title {
        margin-top: 71px;
        margin-bottom: 25px;
    }
    .genera-informes-two .list-items {
        margin-bottom: 40px;
    }
    .genera-informes-three {
        padding-top: 0;
    }
    .genera-informes-three .section-title {
        margin-top: 96px;
    }
    .genera-informes-three .section-title.style-two {
        margin-top: 100px;
    }
    .genera-informes-three .informes-feature-image {
        position: relative;
        top: 0;
        right: 0;
    }
    .genera-informes-three .informes-feature-image .image-two {
        right: auto;
        left: 70px;
    }
    .genera-informes .shape-bg {
        right: -180px;
    }
    .genera-informes-two .scroll-circle {
        left: -40%;
    }
}

@media (max-width: 768px) {
    .informes-feature-image .image-two {
        right: -70px;
    }
}

@media (max-width: 576px) {
    .informes-feature-image .image-two {
        left: 0;
        right: auto;
    }
    .informes-feature-image .image-one img,
    .informes-feature-image .image-two img {
        max-width: 100% !important;
    }
}


/*--------------------------------------------------------------
  ##  Revolutionize
  --------------------------------------------------------------*/

.revolutionize {
    padding: 116px 0 30px;
}

.revolutionize-two {
    padding: 116px 0 30px;
}

.revolutionize-two .bg-angle {
    background-image: -o-linear-gradient(150deg, #7052fb 0%, #8a60fd 100%);
    background-image: linear-gradient(-60deg, #7052fb 0%, #8a60fd 100%);
}

.bg-angle {
    background-image: -o-linear-gradient(350deg, #fa7070 0%, #fa9770 100%);
    background-image: linear-gradient(100deg, #fa7070 0%, #fa9770 100%);
    max-height: 700px;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    overflow: hidden;
}

.bg-angle:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 0;
    border-top: 200px solid transparent;
    border-left: 1900px solid #fff;
    border-bottom: 0px solid transparent;
}


/* Tabs */

#pix-tabs-nav {
    text-align: center;
    list-style: none;
    margin-bottom: 38px;
    padding: 0;
}

#pix-tabs-nav li {
    display: inline-block;
}

#pix-tabs-nav li:not(:last-child) {
    margin-right: 20px;
}

#pix-tabs-nav li a {
    color: #f1e8e8;
    font-weight: 500;
    cursor: pointer;
    padding: 11px 27px;
    border-radius: 30px;
    display: inline-block;
    margin-bottom: 20px;
}

#pix-tabs-nav li a:hover {
    background: #fff;
    color: #fa7070;
    -webkit-box-shadow: 0px 20px 30px 0px rgba(176, 70, 70, 0.3);
    box-shadow: 0px 20px 30px 0px rgba(176, 70, 70, 0.3);
}

#pix-tabs-nav li.active a {
    background: #fff;
    color: #fa7070;
    -webkit-box-shadow: 0px 20px 30px 0px rgba(176, 70, 70, 0.3);
    box-shadow: 0px 20px 30px 0px rgba(176, 70, 70, 0.3);
}

#pix-tabs-nav.pix-tab-two li a:hover {
    color: #7052fb;
    -webkit-box-shadow: 0px 20px 30px 0px rgba(90, 70, 176, 0.3);
    box-shadow: 0px 20px 30px 0px rgba(90, 70, 176, 0.3);
}

#pix-tabs-nav.pix-tab-two li.active a {
    color: #7052fb;
    -webkit-box-shadow: 0px 20px 30px 0px rgba(90, 70, 176, 0.3);
    box-shadow: 0px 20px 30px 0px rgba(90, 70, 176, 0.3);
}

#pix-tabs-content .content {
    text-align: center;
    position: relative;
}

#pix-tabs-content .content img {
    background-color: #e1d5d5;
    -webkit-box-shadow: 0px 60px 100px 0px rgba(79, 35, 35, 0.2);
    box-shadow: 0px 60px 100px 0px rgba(79, 35, 35, 0.2);
    border-radius: 10px;
}

#pix-tabs-content .content .shape-shadow {
    border-radius: 10px;
    background-color: white;
    opacity: 0.4;
    -webkit-box-shadow: 0px 60px 100px 0px rgba(79, 35, 35, 0.2);
    box-shadow: 0px 60px 100px 0px rgba(79, 35, 35, 0.2);
    height: 100px;
    max-width: 73%;
    position: absolute;
    bottom: -40px;
    width: 100%;
    z-index: -1;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

#pix-tabs-content .content.color-two img {
    -webkit-box-shadow: 0px 60px 100px 0px rgba(43, 35, 79, 0.2);
    box-shadow: 0px 60px 100px 0px rgba(43, 35, 79, 0.2);
}

#pix-tabs-content .content.color-two .shape-shadow {
    -webkit-box-shadow: 0px 60px 100px 0px rgba(43, 35, 79, 0.2);
    box-shadow: 0px 60px 100px 0px rgba(43, 35, 79, 0.2);
}

@media (max-width: 991px) {
    .revolutionize,
    .revolutionize-two {
        padding: 76px 0 30px;
    }
    #pix-tabs-content .content.color-two img {
        -webkit-box-shadow: 0px 40px 50px 0px rgba(43, 35, 79, 0.2);
        box-shadow: 0px 40px 50px 0px rgba(43, 35, 79, 0.2);
    }
    #pix-tabs-content .content.color-two .shape-shadow {
        -webkit-box-shadow: 0px 40px 50px 0px rgba(43, 35, 79, 0.2);
        box-shadow: 0px 40px 50px 0px rgba(43, 35, 79, 0.2);
    }
}

@media (max-width: 768px) {
    #pix-tabs-content .content img {
        -webkit-box-shadow: 0px 40px 50px 0px rgba(79, 35, 35, 0.2);
        box-shadow: 0px 40px 50px 0px rgba(79, 35, 35, 0.2);
    }
    #pix-tabs-content .content .shape-shadow {
        bottom: -30px;
        -webkit-box-shadow: 0px 40px 50px 0px rgba(79, 35, 35, 0.2);
        box-shadow: 0px 40px 50px 0px rgba(79, 35, 35, 0.2);
    }
    #pix-tabs-nav li:not(:last-child) {
        margin-right: 5px;
    }
}

@media (max-width: 576px) {
    #pix-tabs-nav {
        margin: 0;
    }
    #pix-tabs-nav li {
        margin-right: 3px;
    }
    #pix-tabs-nav li a {
        font-size: 14px;
        padding: 6px 22px;
    }
}

@media (max-width: 420px) {
    #pix-tabs-nav {
        margin-bottom: 20px;
    }
    #pix-tabs-nav li a {
        margin-bottom: 10px;
    }
    #pix-tabs-nav li:not(:last-child) {
        margin-right: 0;
    }
}


/*--------------------------------------------------------------
  ##  Inspect
  --------------------------------------------------------------*/

.inspect {
    padding: 107px 0 75px;
    background: #fbfafd;
}

.performance {
    background: #faf9fd;
    padding: 120px 0 125px;
}

.gp-tab-contentens .section-heading {
    margin-bottom: 50px;
}

.gp-tabs-navigation {
    margin: 0;
    padding: 0;
    list-style: none;
}

.gp-tabs-navigation li {
    margin-bottom: 20px;
    border-radius: 4px;
    padding: 25px 20px 25px 50px;
    background: #fff;
    border-radius: 4px;
    -webkit-box-shadow: 0px 20px 40px 0px rgba(43, 35, 79, 0.08);
    box-shadow: 0px 20px 40px 0px rgba(43, 35, 79, 0.08);
    border-left: 5px solid #7052fb;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.gp-tabs-navigation li .acc-btn {
    color: #322d49;
    font-size: 20px;
    font-weight: 500;
}

.gp-tabs-navigation li .content {
    display: none;
    padding-top: 14px;
}

.gp-tabs-navigation li .content p {
    margin-bottom: 22px;
}

.gp-tabs-navigation li:last-child {
    margin-bottom: 0;
}

.gp-tabs-navigation li .tab-icon {
    margin-right: 20px;
}

.gp-tabs-navigation li.active .content {
    display: block;
}

.gp-tabs-navigation li .more-btn {
    font-size: 14px;
    color: #5e5b74;
    font-weight: 500;
}

.gp-tabs-navigation li .more-btn i {
    vertical-align: middle;
    margin-left: 5px;
}

.gp-tabs-navigation li .more-btn:hover {
    color: #7052fb;
}

.gp-tabs-navigation li.active-tab {
    padding: 30px 20px 25px 50px;
}

.gp-tabs-content {
    padding: 0;
    list-style: none;
    z-index: 22;
    position: relative;
}

.gp-tabs-content .pix-tab-item {
    display: none;
    opacity: 0;
    padding: 37px 50px 40px 0px;
}

.gp-tabs-content .pix-tab-item.active-tab {
    display: block;
    opacity: 1;
}

.gp-tabs-content .pix-tab-item.active-tab .image-bottom {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
}

.gp-tabs-content .pix-tab-item.active-tab .image-top {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
    -webkit-transition-delay: 0.3s;
    -o-transition-delay: 0.3s;
    transition-delay: 0.3s;
}

.gp-tabs-content .pix-tab-item.active-tab .image-left {
    opacity: 1;
    -webkit-transition-delay: 0.5s;
    -o-transition-delay: 0.5s;
    transition-delay: 0.5s;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

.gp-tabs-content .inspect-tab-image {
    position: relative;
    height: 510px;
    width: 610px;
    margin: 0 auto;
}

.gp-tabs-content .inspect-tab-image>div {
    position: absolute;
    opacity: 0;
    -webkit-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

.gp-tabs-content .inspect-tab-image .image-top {
    top: -10px;
    left: 0;
    z-index: 2;
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    transform: translateY(-20px);
}

.gp-tabs-content .inspect-tab-image .image-bottom {
    bottom: -66px;
    right: -70px;
    -webkit-transform: translateY(30px);
    -ms-transform: translateY(30px);
    transform: translateY(30px);
}

.gp-tabs-content .inspect-tab-image .image-left {
    bottom: -30px;
    left: 50px;
    -webkit-transform: scale(0.7);
    -ms-transform: scale(0.7);
    transform: scale(0.7);
}

.tab-content-inner {
    position: relative;
}

.tab-content-inner .animate-shape-one {
    position: absolute;
    top: 66%;
    left: 55%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 1;
}

.tab-content-inner .animate-shape-two {
    position: absolute;
    right: 25px;
    top: 35%;
}

@-webkit-keyframes animBottom {
    0% {
        -webkit-transform: translate3d(0, 20px, 0);
        transform: translate3d(0, 20px, 0);
        opacity: 0;
    }
    100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@keyframes animBottom {
    0% {
        -webkit-transform: translate3d(0, 20px, 0);
        transform: translate3d(0, 20px, 0);
        opacity: 0;
    }
    100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@-webkit-keyframes animeTop {
    0% {
        -webkit-transform: translate3d(0, -20px, 0);
        transform: translate3d(0, -20px, 0);
        opacity: 0;
    }
    100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@keyframes animeTop {
    0% {
        -webkit-transform: translate3d(0, -20px, 0);
        transform: translate3d(0, -20px, 0);
        opacity: 0;
    }
    100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@-webkit-keyframes animeZoom {
    0% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
        opacity: 0;
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes animeZoom {
    0% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
        opacity: 0;
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}

@media (min-width: 768px) {
    .gp-tabs-content {
        min-height: 480px;
    }
}

@media (min-width: 960px) {
    .gp-tabs-content {
        min-height: 0;
    }
}

@-webkit-keyframes gp-fade-in {
    0% {
        opacity: 0;
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }
    100% {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes gp-fade-in {
    0% {
        opacity: 0;
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }
    100% {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@media (max-width: 1200px) {
    .inspect {
        padding: 107px 0 120px;
    }
    .gp-tabs-navigation {
        margin-bottom: 40px;
    }
    .gp-tabs-content .pix-tab-item {
        padding: 0;
    }
    .gp-tabs-content .inspect-tab-image {
        max-width: 100%;
        height: 450px;
    }
    .gp-tabs-content .inspect-tab-image .image-top {
        max-width: 450px;
    }
    .gp-tabs-content .inspect-tab-image .image-bottom {
        right: 0;
        max-width: 470px;
    }
    .gp-tabs-content .inspect-tab-image .image-left {
        left: 0;
    }
}

@media (max-width: 992px) {
    .inspect {
        padding: 75px 0 100px;
    }
}

@media (max-width: 576px) {
    .gp-tabs-content .pix-tab-item {
        padding: 0;
    }
    .gp-tabs-content .inspect-tab-image {
        max-width: 400px;
        height: 290px;
    }
    .gp-tabs-content .inspect-tab-image .image-top {
        max-width: 340px;
    }
    .gp-tabs-content .inspect-tab-image .image-bottom {
        right: 0;
        max-width: 360px;
    }
    .gp-tabs-content .inspect-tab-image .image-left {
        bottom: -60px;
        width: 200px;
    }
}

@media (max-width: 420px) {
    .gp-tabs-navigation li {
        padding: 20px 20px 20px 30px;
    }
    .gp-tabs-navigation li .acc-btn {
        font-size: 16px;
    }
    .gp-tabs-navigation li.active-tab {
        padding: 20px 20px 20px 30px;
    }
    .gp-tabs-content .inspect-tab-image {
        width: 300px;
        height: 190px;
    }
    .gp-tabs-content .inspect-tab-image .image-top {
        max-width: 260px;
    }
    .gp-tabs-content .inspect-tab-image .image-bottom {
        right: 0;
        max-width: 270px;
    }
    .gp-tabs-content .inspect-tab-image .image-left {
        bottom: -60px;
        width: 140px;
    }
}


/*--------------------------------------------------------------
  ##  Testimonial
  --------------------------------------------------------------*/

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next,
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
    background-image: none;
}

.testimonials {
    background: #fdf8f8;
    padding: 116px 0 0;
    overflow: hidden;
}

.testimonials .scroll-circle {
    bottom: 35%;
    right: -133px;
}

.testimonial-wrapper {
    padding: 0 100px 160px;
    position: relative;
    z-index: 22;
}

.testimonial-wrapper .shape-shadow {
    border-radius: 10px;
    background-color: white;
    opacity: 0.4;
    -webkit-box-shadow: 0px 60px 100px 0px rgba(102, 74, 74, 0.2);
    box-shadow: 0px 60px 100px 0px rgba(102, 74, 74, 0.2);
    height: 100px;
    max-width: 54%;
    position: absolute;
    bottom: 120px;
    width: 100%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

.testimonial-wrapper .slider-nav .swiper-button-next,
.testimonial-wrapper .slider-nav .swiper-button-prev {
    color: #bdb4b4;
    font-size: 36px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    outline: 0;
}

.testimonial-wrapper .slider-nav .swiper-button-next:hover,
.testimonial-wrapper .slider-nav .swiper-button-prev:hover {
    color: #fa7070;
}

.testimonial-wrapper .slider-nav .swiper-button-next {
    right: 20px;
}

.testimonial-wrapper .slider-nav .swiper-button-prev {
    left: 20px;
}

#testimonial {
    border-radius: 10px;
    max-width: 770px;
    margin: 0 auto;
    position: relative;
    -webkit-box-shadow: 0px 60px 100px 0px rgba(79, 35, 35, 0.1);
    box-shadow: 0px 60px 100px 0px rgba(79, 35, 35, 0.1);
    background: #fff;
    width: 100%;
}

#testimonial .testimonial {
    text-align: center;
    margin: 0 auto;
    padding: 60px;
    position: relative;
}

#testimonial .testimonial .testimonial-content {
    max-width: 630px;
    margin: 0 auto 30px;
}

#testimonial .testimonial .testimonial-content p {
    color: #656177;
    font-size: 20px;
    margin: 0;
    line-height: 38px;
}

#testimonial .testimonial .single-bio-thumb {
    max-width: 80px;
    margin: 0 auto 41px;
    -webkit-box-shadow: 0px 20px 30px 0px rgba(79, 35, 35, 0.2);
    box-shadow: 0px 20px 30px 0px rgba(79, 35, 35, 0.2);
    border-radius: 50%;
}

#testimonial .testimonial .single-bio-thumb img {
    border-radius: 50%;
}

#testimonial .testimonial .bio-info .name {
    font-size: 20px;
    line-height: 32px;
    margin: 0;
}

#testimonial .testimonial-travel {
    border-radius: 20px;
    -webkit-box-shadow: 0px 50px 80px 0px rgba(13, 3, 31, 0.1);
    box-shadow: 0px 50px 80px 0px rgba(13, 3, 31, 0.1);
}

.testimonials-two {
    position: relative;
    overflow: hidden;
}

.testimonials-two .section-title {
    margin-bottom: 50px;
}

.testimonials-two .animate-shape {
    position: absolute;
    right: -9%;
    top: -37%;
}

#testimonial-wrapper {
    position: relative;
    z-index: 2;
}

#testimonial-wrapper .slider-nav .swiper-button-prev,
#testimonial-wrapper .slider-nav .swiper-button-next {
    outline: 0;
}

#testimonial-wrapper .slider-nav .swiper-button-prev i,
#testimonial-wrapper .slider-nav .swiper-button-next i {
    font-size: 32px;
}

#testimonial-wrapper .slider-nav .swiper-button-prev:hover,
#testimonial-wrapper .slider-nav .swiper-button-next:hover {
    color: #7052fb;
}

#testimonial-wrapper .slider-nav .swiper-button-next {
    right: -100px;
}

#testimonial-wrapper .slider-nav .swiper-button-prev {
    left: -100px;
}

.testimonials-two,
.testimonials-two-about {
    padding: 116px 0 60px;
}

.testimonials-two .swiper-container,
.testimonials-two-about .swiper-container {
    padding: 0 20px 100px;
}

.testimonials-two {
    background: #f8f7fc;
}

.testimonial-two .testi-content-inner {
    overflow: hidden;
    position: relative;
    background: #fff;
    -webkit-box-shadow: 0px 30px 20px 0px rgba(43, 35, 79, 0.05);
    box-shadow: 0px 30px 20px 0px rgba(43, 35, 79, 0.05);
    border-radius: 10px;
    padding: 50px 60px;
}

.testimonial-two .testimonial-bio {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 43px;
}

.testimonial-two .testimonial-bio .avatar {
    height: 70px;
    width: 70px;
    border-radius: 50%;
    margin-right: 30px;
}

.testimonial-two .testimonial-bio .avatar img {
    border-radius: 50%;
}

.testimonial-two .testimonial-bio .bio-info .name {
    font-size: 20px;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 5px;
}

.testimonial-two .testimonial-content {
    margin-bottom: 35px;
}

.testimonial-two .testimonial-content p {
    font-size: 18px;
    line-height: 30px;
    margin: 0;
}

.testimonial-two .shape-shadow {
    border-radius: 10px;
    background-color: white;
    opacity: 0.4;
    -webkit-box-shadow: 0px 20px 50px 0px rgba(102, 74, 74, 0.2);
    box-shadow: 0px 20px 50px 0px rgba(102, 74, 74, 0.2);
    height: 100px;
    max-width: 81%;
    position: absolute;
    bottom: -40px;
    width: 100%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: -1;
}

.testimonial-two .quote {
    position: absolute;
    right: 60px;
}

.testimonial-travel-wrapper {
    position: relative;
    max-width: 770px;
    margin: 0 auto;
}

.testimonial-travel-wrapper .travel-testimonial {
    border-radius: 20px;
    background: #fff;
    -webkit-box-shadow: 0px 50px 80px 0px rgba(13, 3, 31, 0.1);
    box-shadow: 0px 50px 80px 0px rgba(13, 3, 31, 0.1);
    padding: 60px 50px 50px;
}

.testimonial-travel-wrapper .testimonial-five {
    text-align: center;
}

.testimonial-travel-wrapper .testimonial-five .single-bio-thumb {
    height: 80px;
    width: 80px;
    border-radius: 50%;
    margin: 0 auto 30px;
    overflow: hidden;
}

.testimonial-travel-wrapper .testimonial-five .rating {
    margin-bottom: 5px;
}

.testimonial-travel-wrapper .testimonial-five .testimonial-content {
    max-width: 550px;
    margin: 0 auto 25px;
}

.testimonial-travel-wrapper .testimonial-five .testimonial-content p {
    font-size: 20px;
    color: #0d031f;
    margin: 0;
    line-height: 32px;
}

.testimonial-travel-wrapper .testimonial-five .bio-info .name {
    font-size: 18px;
    font-weight: 700;
    color: #0d031f;
    margin: 0;
}

.testimonial-travel-wrapper .testimonial-five .bio-info .job {
    font-size: 14px;
    color: #68666c;
}

.testimonial-travel-wrapper .right-qoute {
    position: absolute;
    right: 33px;
    bottom: -14px;
}

.testimonial-travel-wrapper .dot-shape {
    position: absolute;
    top: 60px;
    left: -40px;
}

.rating {
    margin: 0;
    padding: 0;
    list-style: none;
}

.rating li {
    display: inline-block;
    font-size: 14px;
    color: #fdae04;
}

#testimonial-wrapper-three {
    max-width: 1060px;
    margin: 0 auto;
    position: relative;
}

#testimonial-wrapper-three .quote {
    position: absolute;
    bottom: 80px;
    right: 80px;
    z-index: 22;
}

#testimonial-wrapper-three .swiper-container {
    padding: 0 45px 80px;
}

#testimonial-wrapper-three .slider-nav {
    width: 90px;
    top: auto;
    bottom: 100px;
    left: 50%;
    height: 40px;
}

#testimonial-wrapper-three .slider-nav .swiper-button-prev {
    height: 25px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    outline: 0;
}

#testimonial-wrapper-three .slider-nav .swiper-button-prev .arrow {
    margin-top: 12px;
    width: 25px;
    height: 2px;
    background: #bdb4b4;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

#testimonial-wrapper-three .slider-nav .swiper-button-prev .arrow:after {
    content: '';
    position: absolute;
    width: 15px;
    height: 2px;
    top: 7px;
    left: -2px;
    background-color: #bdb4b4;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

#testimonial-wrapper-three .slider-nav .swiper-button-prev .arrow:before {
    content: '';
    position: absolute;
    width: 15px;
    height: 2px;
    top: 17px;
    left: -2px;
    background-color: #bdb4b4;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

#testimonial-wrapper-three .slider-nav .swiper-button-prev:hover {
    width: 40px;
    left: -15px;
}

#testimonial-wrapper-three .slider-nav .swiper-button-prev:hover .arrow {
    width: 40px;
    background: #fa7070;
}

#testimonial-wrapper-three .slider-nav .swiper-button-prev:hover .arrow:before,
#testimonial-wrapper-three .slider-nav .swiper-button-prev:hover .arrow:after {
    background: #fa7070;
}

#testimonial-wrapper-three .slider-nav .swiper-button-next {
    height: 25px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

#testimonial-wrapper-three .slider-nav .swiper-button-next .arrow {
    margin-top: 12px;
    width: 25px;
    height: 2px;
    background: #bdb4b4;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

#testimonial-wrapper-three .slider-nav .swiper-button-next .arrow:after {
    content: '';
    position: absolute;
    width: 15px;
    height: 2px;
    top: 7px;
    right: -2px;
    background-color: #bdb4b4;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

#testimonial-wrapper-three .slider-nav .swiper-button-next .arrow:before {
    content: '';
    position: absolute;
    width: 15px;
    height: 2px;
    top: 17px;
    right: -2px;
    background-color: #bdb4b4;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

#testimonial-wrapper-three .slider-nav .swiper-button-next:hover {
    width: 40px;
    right: -15px;
}

#testimonial-wrapper-three .slider-nav .swiper-button-next:hover .arrow {
    width: 40px;
    background: #fa7070;
}

#testimonial-wrapper-three .slider-nav .swiper-button-next:hover .arrow:before,
#testimonial-wrapper-three .slider-nav .swiper-button-next:hover .arrow:after {
    background: #fa7070;
}

.testimonials-two-about {
    background: #fdf8f8;
    padding: 116px 0 58px;
    overflow: hidden;
}

.testimonials-two-about .testimonial-two .testi-content-inner {
    -webkit-box-shadow: 0px 30px 20px 0px rgba(79, 35, 35, 0.1);
    box-shadow: 0px 30px 20px 0px rgba(79, 35, 35, 0.1);
}

.testimonials-two-about .testimonial-two .shape-shadow {
    -webkit-box-shadow: 0px 20px 50px 0px rgba(79, 35, 35, 0.1);
    box-shadow: 0px 20px 50px 0px rgba(79, 35, 35, 0.1);
}

.testimonials-two-about .scroll-circle {
    bottom: 33%;
    z-index: 1;
    right: -7%;
}

.testimonials-two-about #testimonial-wrapper .slider-nav .swiper-button-prev:hover,
.testimonials-two-about #testimonial-wrapper .slider-nav .swiper-button-next:hover {
    color: #fa7070;
}

.testimonial-three {
    border-radius: 10px;
    background-color: #fff;
    -webkit-box-shadow: 0px 20px 40px 0px rgba(79, 35, 35, 0.1);
    box-shadow: 0px 20px 40px 0px rgba(79, 35, 35, 0.1);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.testimonial-three .avatar,
.testimonial-three .testimonial-content {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.testimonial-three .testimonial-content {
    padding: 0 60px;
    margin-top: -47px;
}

.testimonial-three .testimonial-content .bio-info {
    margin-bottom: 30px;
}

.testimonial-three .testimonial-content .bio-info .name {
    font-size: 20px;
    font-weight: 600;
}

.testimonial-three .testimonial-content p {
    font-size: 18px;
    line-height: 32px;
    color: #656177;
}

.testimonials-three {
    padding: 116px 0 40px;
}

.testimonial-wrapper-two .slider-nav {
    top: 50%;
}

.testimonials-four {
    background: #fbfafd;
    padding: 115px 0;
}

.testimonial-wrapper-two {
    position: relative;
}

.testimonial-wrapper-two .slider-nav #slide-prev,
.testimonial-wrapper-two .slider-nav #slide-next {
    outline: 0;
    height: 50px;
    width: 50px;
    line-height: 54px;
    border: 1px solid #e0deed;
    text-align: center;
    font-size: 20px;
    border-radius: 50%;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.testimonial-wrapper-two .slider-nav #slide-prev:hover,
.testimonial-wrapper-two .slider-nav #slide-next:hover {
    background: #7052fb;
    -webkit-box-shadow: 0px 20px 30px 0px rgba(90, 70, 176, 0.3);
    box-shadow: 0px 20px 30px 0px rgba(90, 70, 176, 0.3);
    color: #fff;
}

.testimonials-tax {
    padding: 112px 0 120px;
    position: relative;
}

.testimonials-tax .testi-top-shape {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
}

.testimonial-six-wrapper {
    margin: 0 auto;
    max-width: 770px;
    position: relative;
}

.testimonial-six-wrapper .quote {
    position: absolute;
    top: 40px;
    left: 30px;
}

.testimonial-six-wrapper .testimonial-six {
    border-radius: 10px;
    background-color: white;
    -webkit-box-shadow: 0px 50px 80px 0px rgba(48, 11, 33, 0.1);
    box-shadow: 0px 50px 80px 0px rgba(48, 11, 33, 0.1);
    padding: 50px 100px 100px;
}

.testimonial-six-wrapper .testimonial-six .testimonial .single-bio-thumb {
    border-radius: 50%;
    background-color: #dfe4ec;
    -webkit-box-shadow: 0px 30px 40px 0px rgba(48, 11, 33, 0.12);
    box-shadow: 0px 30px 40px 0px rgba(48, 11, 33, 0.12);
    width: 80px;
    height: 80px;
    margin: 0 auto 30px;
    overflow: hidden;
}

.testimonial-six-wrapper .testimonial-six .testimonial .testimonial-content p {
    font-size: 18px;
    line-height: 32px;
    margin-bottom: 35px;
}

.testimonial-six-wrapper .testimonial-six .testimonial .bio-info .name {
    font-size: 18px;
    line-height: 26px;
    margin: 0;
}

.testimonial-six-wrapper .testimonial-six .testimonial .bio-info .job {
    font-size: 14px;
}

.testimonial-six-wrapper .dot-shape {
    position: absolute;
    position: absolute;
    right: -60px;
    bottom: -35px;
    z-index: -1;
}

.style-two.swiper-pagination {
    margin-top: 35px;
}

.style-two.swiper-pagination .swiper-pagination-bullet {
    height: 12px;
    width: 12px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    margin-right: 5px;
    position: relative;
    opacity: 1;
    background: transparent;
    outline: 0;
}

.style-two.swiper-pagination .swiper-pagination-bullet:before {
    content: '';
    position: absolute;
    height: 12px;
    width: 12px;
    background: #e5d6d6;
    z-index: 2;
    top: 0;
    left: 0;
    border-radius: 50%;
    left: -2px;
    top: -2px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-transform: scale(0.7);
    -ms-transform: scale(0.7);
    transform: scale(0.7);
}

.style-two.swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
    opacity: 1;
}

.style-two.swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active:before {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    background: #f97bc5;
    -webkit-box-shadow: 0px 10px 14px 0px rgba(79, 35, 35, 0.3);
    box-shadow: 0px 10px 14px 0px rgba(79, 35, 35, 0.3);
}

.testimonials-six {
    padding: 120px 0 154px;
    background-size: cover;
    background-position: center center;
}

#testimonial-wrapper-six {
    margin: 0 -45px;
}

#testimonial-wrapper-six .swiper-container {
    padding: 40px 45px 35px;
}

.swiper-slide-visible .testimonial-six .testimonial-content {
    -webkit-box-shadow: 0px 40px 50px 0px rgba(10, 1, 47, 0.12);
    box-shadow: 0px 40px 50px 0px rgba(10, 1, 47, 0.12);
}

.testimonial-six .testimonial-content {
    background-color: #fff;
    padding: 42px 40px;
    border-radius: 10px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    position: relative;
}

.testimonial-six .testimonial-content p {
    font-size: 16px;
    line-height: 30px;
    color: #322d49;
    margin-bottom: 13px;
}

.testimonial-six .testimonial-content:after {
    content: '';
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 8px solid transparent;
    border-top: 15px solid #fff;
    bottom: -15px;
    position: absolute;
    left: 100px;
}

.testimonial-six .testimonial-bio {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 35px;
    margin-left: 100px;
}

.testimonial-six .testimonial-bio .name {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
}

.testimonial-six .testimonial-bio .avatar {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 20px;
}

#testimonials-four .swiper-slide.swiper-slide-active .testimonial-four>* {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
}

#testimonials-four .swiper-slide.swiper-slide-active .testimonial-four .single-bio-thumb {
    -webkit-transition-delay: 0.3s;
    -o-transition-delay: 0.3s;
    transition-delay: 0.3s;
}

#testimonials-four .swiper-slide.swiper-slide-active .testimonial-four .testimonial-content {
    -webkit-transition-delay: 0.5s;
    -o-transition-delay: 0.5s;
    transition-delay: 0.5s;
}

#testimonials-four .swiper-slide.swiper-slide-active .testimonial-four .bio-info {
    -webkit-transition-delay: 0.7s;
    -o-transition-delay: 0.7s;
    transition-delay: 0.7s;
}

.testimonial-four {
    text-align: center;
    padding-top: 30px;
}

.testimonial-four>* {
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    transform: translateY(-20px);
    opacity: 0;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}

.testimonial-four .single-bio-thumb {
    overflow: hidden;
    height: 80px;
    width: 80px;
    margin: 0 auto 60px;
    border-radius: 50%;
    -webkit-box-shadow: 0px 20px 30px 0px rgba(43, 35, 79, 0.1);
    box-shadow: 0px 20px 30px 0px rgba(43, 35, 79, 0.1);
}

.testimonial-four .testimonial-content {
    position: relative;
    max-width: 740px;
    margin: 0 auto 68px;
}

.testimonial-four .testimonial-content .quote {
    position: absolute;
    top: -65px;
    left: 0;
    z-index: 1;
}

.testimonial-four .testimonial-content p {
    font-size: 20px;
    line-height: 36px;
    font-weight: 300;
    margin: 0;
    z-index: 22;
    position: relative;
}

.testimonial-four .bio-info .name {
    font-size: 20px;
    font-weight: 500;
    color: #322d49;
    margin: 0;
}

.testimonial-four .bio-info .job {
    font-size: 14px;
    color: #7c7a8f;
}

.testimonials-marketing {
    padding: 100px 0;
    overflow: hidden;
}

#testimonial-marketing .testimonial {
    max-width: 430px;
}

#testimonial-marketing .testimonial .quote {
    margin-bottom: 32px;
}

#testimonial-marketing .testimonial .title {
    font-size: 30px;
    line-height: 42px;
    color: #1a133b;
    margin-bottom: 25px;
}

#testimonial-marketing .testimonial .testimonial-content {
    margin-bottom: 40px;
}

#testimonial-marketing .testimonial .author-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

#testimonial-marketing .testimonial .author-wrapper .single-bio-thumb {
    width: 50px;
    margin-right: 20px;
}

#testimonial-marketing .testimonial .author-wrapper .bio-info {
    -webkit-box-flex: 2;
    -ms-flex: 2;
    flex: 2;
}

#testimonial-marketing .testimonial .author-wrapper .bio-info .name {
    font-size: 14px;
    font-weight: 600;
    color: #1a133b;
    margin: 0;
}

#testimonial-marketing .testimonial .author-wrapper .bio-info .job {
    color: #7c7a86;
    font-size: 14px;
}

.devaider {
    position: absolute;
    top: 75px;
    left: -187px;
}

@media (max-width: 768px) {
    .devaider {
        display: none;
    }
}


/* Testimonial Hosting */

.testimonial-hostings {
    padding: 115px 0;
}

.testimonial-hosting-wrapper {
    position: relative;
    max-width: 1075px;
    margin: 0 auto;
}

.testimonial-hosting-wrapper #testimonial-hosting {
    padding-bottom: 80px;
}

.testimonial-hosting-wrapper .swiper-pagination {
    position: absolute;
    left: auto !important;
    right: -40px;
    width: auto;
    margin: 0;
    bottom: 28px;
}

.testimonial-hosting-wrapper .swiper-pagination .swiper-pagination-bullet {
    height: 10px;
    width: 10px;
    background: #cbd1d4;
    position: relative;
    margin-right: 10px;
    opacity: 1;
}

.testimonial-hosting-wrapper .swiper-pagination .swiper-pagination-bullet:before {
    position: absolute;
    content: '';
    height: 18px;
    width: 18px;
    top: -4px;
    left: -4px;
    border: 1px solid transparent;
    border-radius: 50%;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-transform: scale(0.5);
    -ms-transform: scale(0.5);
    transform: scale(0.5);
}

.testimonial-hosting-wrapper .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: #14aeee;
}

.testimonial-hosting-wrapper .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active:before {
    border-color: #14aeee;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

.testimonial-hots {
    text-align: center;
    margin: 0 auto;
    padding: 60px 60px 0 60px;
    position: relative;
    border-top: 2px solid #f0f0f0;
    border-left: 2px solid #f0f0f0;
    border-right: 2px solid #f0f0f0;
}

@media (max-width: 768px) {
    .testimonial-hots {
        padding: 40px 30px 0 40px;
    }
}

@media (max-width: 768px) {
    .testimonial-hots {
        padding: 30px 30px 0 30px;
    }
}

.testimonial-hots:before,
.testimonial-hots:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 28%;
    background: #f0f0f0;
}

@media (max-width: 991px) {
    .testimonial-hots:before,
    .testimonial-hots:after {
        width: 18%;
    }
}

@media (max-width: 768px) {
    .testimonial-hots:before,
    .testimonial-hots:after {
        width: 7%;
    }
}

.testimonial-hots:before {
    left: 0;
    bottom: 0;
}

.testimonial-hots:after {
    right: 0;
    bottom: 0;
}

.testimonial-hots * {
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    transform: translateY(-20px);
    opacity: 0;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}

.swiper-slide-active .testimonial-hots * {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
}

.swiper-slide-active .testimonial-hots .testimonial-content {
    -webkit-transition-delay: 0.3s;
    -o-transition-delay: 0.3s;
    transition-delay: 0.3s;
}

.swiper-slide-active .testimonial-hots .bottom-info {
    -webkit-transition-delay: 0.5s;
    -o-transition-delay: 0.5s;
    transition-delay: 0.5s;
}

.testimonial-hots .testimonial-content {
    max-width: 670px;
    margin: 0 auto;
}

.testimonial-hots .testimonial-content p {
    font-size: 18px;
    line-height: 36px;
}

@media (max-width: 480px) {
    .testimonial-hots .testimonial-content p {
        font-size: 16px;
        line-height: 30px;
    }
}

.testimonial-hots .bottom-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-transform: translateY(50px);
    -ms-transform: translateY(50px);
    transform: translateY(50px);
    margin-left: 55px;
}

@media (max-width: 480px) {
    .testimonial-hots .bottom-info {
        margin-left: 0;
    }
}

.testimonial-hots .author-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
}

.testimonial-hots .author-wrapper .single-bio-thumb {
    border-radius: 4px;
    margin-right: 35px;
    position: relative;
}

@media (max-width: 480px) {
    .testimonial-hots .author-wrapper .single-bio-thumb {
        margin-right: 20px;
        width: 80px;
        height: 100px;
    }
}

.testimonial-hots .author-wrapper .single-bio-thumb img {
    border-radius: 4px;
    position: relative;
    z-index: 2;
}

.testimonial-hots .author-wrapper .single-bio-thumb:before {
    content: '';
    position: absolute;
    border-radius: 4px;
    width: 130px;
    height: 170px;
    border: 2px solid #f0f0f0;
    top: -20px;
    left: -60px;
}

@media (max-width: 480px) {
    .testimonial-hots .author-wrapper .single-bio-thumb:before {
        width: 80px;
        height: 118px;
        border: 2px solid #f0f0f0;
        top: -15px;
        left: -17px;
    }
}

.testimonial-hots .author-wrapper .bio-info .name {
    font-size: 20px;
    color: #2c234d;
    margin: 0;
}

@media (max-width: 480px) {
    .testimonial-hots .author-wrapper .bio-info .name {
        font-size: 16px;
    }
}

.testimonial-hots .author-wrapper .bio-info .job {
    font-size: 16px;
    color: #2c234d;
}

@media (max-width: 480px) {
    .testimonial-hots .author-wrapper .bio-info .job {
        font-size: 14px;
    }
}

@media (max-width: 1440px) {
    .testimonials-two .animate-shape,
    .testimonials-two .scroll-circle,
    .testimonials-two-about .animate-shape,
    .testimonials-two-about .scroll-circle {
        max-width: 600px;
    }
}

@media (max-width: 1200px) {
    #testimonial-wrapper-three .slider-nav {
        bottom: 70px;
    }
}

@media (max-width: 1024px) {
    .testimonial-three .testimonial-content {
        padding: 0 20px;
        margin-top: 0;
    }
    .testimonial-three .testimonial-content p {
        font-size: 16px;
        line-height: 28px;
    }
    .testimonial-three .testimonial-content .bio-info {
        margin-bottom: 10px;
    }
    #testimonial-wrapper-three .slider-nav {
        bottom: 80px;
    }
    #testimonial-wrapper-three .quote {
        width: 80px;
    }
    .testimonials .scroll-circle {
        max-width: 500px;
    }
    .testimonials-two-about .scroll-circle {
        max-width: 400px;
    }
}

@media (max-width: 991px) {
    .testimonials {
        padding: 76px 0 10px;
    }
    .testimonials-two .swiper-container,
    .testimonials-two-about .swiper-container {
        padding: 0 20px 80px;
    }
    .testimonials-two,
    .testimonials-two-about {
        padding: 76px 0 30px;
    }
    #testimonial-wrapper .slider-nav .swiper-button-prev {
        left: -30px;
    }
    #testimonial-wrapper .slider-nav .swiper-button-next {
        right: -20px;
    }
    .testimonials-three {
        padding: 76px 0 0;
    }
    #testimonial-wrapper-three .slider-nav {
        display: none;
    }
    .testimonials-two .animate-shape {
        right: -80%;
    }
    .testimonial-wrapper {
        padding: 0 50px 110px;
    }
    .testimonial-wrapper .slider-nav .swiper-button-prev {
        left: 0;
    }
    .testimonial-wrapper .slider-nav .swiper-button-next {
        right: 10px;
    }
    .testimonial-wrapper .shape-shadow {
        bottom: 80px;
    }
    #testimonial .testimonial .testimonial-content p {
        font-size: 18px;
        line-height: 34px;
    }
    .testimonial-two .testi-content-inner {
        padding: 40px 30px;
    }
    .testimonial-two .shape-shadow {
        bottom: -30px;
    }
    .testimonial-four .single-bio-thumb {
        margin: 0 auto 30px;
    }
    .testimonial-four .testimonial-content {
        max-width: 500px;
        margin: 0 auto 38px;
    }
    .testimonial-four .testimonial-content p {
        font-size: 18px;
    }
    .testimonial-four .testimonial-content p br {
        display: none;
    }
    .testimonials-four {
        padding: 50px 0 70px;
    }
    .testimonials-tax {
        padding: 72px 0 80px;
    }
}

@media (max-width: 768px) {
    .testimonials {
        padding: 76px 0 30px;
    }
    .testimonials-three {
        padding: 76px 0 30px;
    }
    #testimonial-wrapper-three .swiper-container {
        padding: 0 25px 50px;
    }
    #testimonial .testimonial {
        padding: 40px;
    }
    #testimonial .testimonial .testimonial-content p {
        font-size: 16px;
        line-height: 30px;
    }
    .testimonial-wrapper {
        padding: 0 50px 70px;
    }
    .testimonial-wrapper .shape-shadow {
        bottom: 50px;
    }
    #testimonial-wrapper .slider-nav .swiper-button-prev {
        left: -30px;
    }
    #testimonial-wrapper .slider-nav .swiper-button-next {
        right: -20px;
    }
    .testimonials-two .animate-shape {
        right: -100%;
    }
    .testimonial-three {
        display: block;
        padding: 30px;
    }
    .testimonial-three .avatar {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        margin: auto 30px;
        overflow: hidden;
    }
    .testimonial-three .avatar img {
        border-radius: 50%;
    }
    #testimonial-wrapper-three .slider-nav {
        bottom: 50px;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
        display: none;
    }
    #testimonial-wrapper-three .quote {
        bottom: 50px;
    }
    .testimonial-wrapper-two .slider-nav #slide-prev {
        left: -50px;
    }
    .testimonial-wrapper-two .slider-nav #slide-next {
        right: -50px;
    }
    .testimonial-six-wrapper .testimonial-six {
        padding: 50px 50px 100px;
    }
}

@media (max-width: 620px) {
    .testimonial-four .testimonial-content p {
        font-size: 16px;
        line-height: 26px;
    }
    .testimonial-wrapper-two .slider-nav {
        display: none;
    }
}

@media (max-width: 576px) {
    #testimonial-wrapper .slider-nav .swiper-button-prev,
    #testimonial-wrapper .slider-nav .swiper-button-next,
    .testimonial-wrapper .slider-nav .swiper-button-prev,
    .testimonial-wrapper .slider-nav .swiper-button-next {
        display: none;
    }
    .testimonial-wrapper {
        padding: 0 0 70px;
    }
    #testimonial .testimonial {
        padding: 40px 25px;
    }
    #testimonial-wrapper-three .slider-nav {
        display: none;
    }
    #testimonial-wrapper-three .swiper-container {
        padding: 0 20px 50px !important;
    }
    #testimonial-wrapper-three .quote {
        right: 30px;
    }
    .testimonial-three {
        padding: 30px 10px;
    }
}


/* Testimonial Travel */

.testimonial-travel {
    background: #f4f2f7;
    padding: 120px 0;
}

@media (max-width: 991px) {
    .testimonial-travel {
        padding: 80px 0;
    }
}

.logo-carousel-travel {
    margin-bottom: 86px;
}


/*--------------------------------------------------------------
  ##  Review Slider
  --------------------------------------------------------------*/

.review-sliders {
    background: #fcfafb;
    padding: 112px 0 120px;
    overflow: hidden;
}

#review-slider-wrapper {
    position: relative;
}

#review-slider-wrapper #review-sliders {
    overflow: visible;
}

#review-slider-wrapper #review-sliders .swiper-slide.swiper-slide-visible .review-slider {
    -webkit-box-shadow: 0px 30px 60px 0px rgba(48, 11, 33, 0.08);
    box-shadow: 0px 30px 60px 0px rgba(48, 11, 33, 0.08);
    opacity: 1;
}

#review-slider-wrapper .swiper-pagination {
    bottom: -60px;
}

.review-slider {
    border-radius: 6px;
    background: #fff;
    text-align: center;
    padding: 40px;
    height: 100%;
    min-height: 280px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    opacity: 0.6;
}

.review-slider .review-content-inner .review-score {
    height: 80px;
    width: 80px;
    line-height: 80px;
    color: #7052fb;
    background: #f5f1ff;
    border-radius: 50%;
    font-size: 30px;
    font-weight: 500;
    margin: 0 auto 23px;
}

.review-slider .review-content-inner .rating {
    margin-bottom: 22px;
}

@media (max-width: 991px) {
    .review-sliders {
        padding: 72px 0 100px;
    }
}


/*--------------------------------------------------------------
  ##  Marketing Popup
  --------------------------------------------------------------*/

.marketing-logo-carousel {
    background: #e1f1fe;
    padding: 120px 0 90px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

@media (max-width: 991px) {
    .marketing-logo-carousel {
        padding: 80px 0 50px;
    }
}

.marketing-logo-carousel .popup-videos-two {
    margin-top: -300px;
    margin-bottom: 87px;
}

@media (max-width: 991px) {
    .marketing-logo-carousel .popup-videos-two {
        margin-top: 0;
    }
}

.popup-videos-two {
    position: relative;
    border: 10px solid #fff;
    border-radius: 50px;
    -webkit-box-shadow: 0px 40px 70px 0px rgba(14, 3, 52, 0.1);
    box-shadow: 0px 40px 70px 0px rgba(14, 3, 52, 0.1);
    overflow: hidden;
}

.popup-videos-two .play-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.popup-videos-two .play-btn.large i {
    color: #f98e47;
    text-shadow: none;
}

.logo-carousel-marketing {
    margin-bottom: 90px;
}


/*--------------------------------------------------------------
##  Pricing
--------------------------------------------------------------*/

.pricing {
    padding: 116px 0 120px;
}

.pricing-two {
    padding-top: 146px;
    padding-bottom: 90px;
}

.pricing-two-single {
    padding-top: 116px;
    padding-bottom: 90px;
}

.pricing .scroll-circle {
    bottom: 20%;
}

.pricing-single-one {
    padding: 116px 0 90px;
}

.pricing-table {
    border-right: 1px solid #efe7e7;
    padding: 20px 40px;
    margin-bottom: 30px;
}

.pricing-table.br-left {
    border-left: 1px solid #efe7e7;
}

.pricing-table .pricing-header {
    text-align: center;
    padding-bottom: 55px;
    border-bottom: 1px dashed #efe7e7;
    margin-bottom: 50px;
}

.pricing-table .pricing-header .price {
    color: #fa7070;
    font-size: 36px;
    font-weight: 800;
    line-height: 50px;
    margin-bottom: 25px;
}

.pricing-table .pricing-header .price-title {
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 4px;
}

.pricing-table .pricing-header p {
    margin: 0;
}

.pricing-table .pricing-header .annual_price {
    display: none;
}

.pricing-table .pricing-header.change-subs-duration .annual_price {
    display: block;
}

.pricing-table .pricing-header.change-subs-duration .monthly_price {
    display: none;
}

.pricing-table .price-feture {
    padding: 0;
    list-style: none;
    margin-bottom: 55px;
}

.pricing-table .price-feture li {
    padding-left: 33px;
    position: relative;
    margin-bottom: 12px;
}

.pricing-table .price-feture li:before {
    font-family: eleganticons;
    position: absolute;
    left: 0;
    top: 0;
}

.pricing-table .price-feture li.have:before {
    content: 'N';
    color: #1acb44;
}

.pricing-table .price-feture li.not:before {
    content: 'M';
    color: #fa7878;
}

.pricing-table.color-two .pricing-header .price {
    color: #8070fa;
}

.pricing-table.color-three .pricing-header .price {
    color: #22cd1a;
}

.pricing-table .pix-btn {
    text-align: center;
    font-weight: 600;
    padding: 8px 0;
    width: 200px;
    text-align: center;
}

.pricing-table.style-two {
    background: #fcf7f7;
    padding: 50px 50px 60px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    position: relative;
    border: 0;
}

.pricing-table.style-two.price-two {
    background: #f8f7fc;
}

.pricing-table.style-two.price-two.featured .trend:before {
    border-right: 90px solid #7052fb;
}

.pricing-table.style-two .pricing-header {
    text-align: left;
    border-color: #b4b2bf;
    padding-bottom: 60px;
}

.pricing-table.style-two .pricing-header .price-title {
    letter-spacing: 0;
    text-transform: capitalize;
}

.pricing-table.style-two:hover {
    background: #fff;
    -webkit-box-shadow: 0px 40px 80px 0px rgba(43, 35, 79, 0.14);
    box-shadow: 0px 40px 80px 0px rgba(43, 35, 79, 0.14);
}

.pricing-table.featured .trend {
    position: absolute;
    top: 0;
    right: 0;
}

.pricing-table.featured .trend p {
    color: #fff;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    margin-left: 22px;
    margin-bottom: 0;
    z-index: 22;
    font-size: 14px;
    padding-top: 17px;
    padding-bottom: 15px;
}

.pricing-table.featured .trend:before {
    content: '';
    width: 0;
    height: 0;
    border-top: 0px solid transparent;
    border-bottom: 90px solid transparent;
    border-right: 90px solid #fa7070;
    position: absolute;
    top: 0;
    right: 0;
}

.pricing-table.style-three {
    border-radius: 6px;
    background: #fff;
    -webkit-box-shadow: 0 10px 30px 0 rgba(2, 2, 53, 0.1);
    box-shadow: 0 10px 30px 0 rgba(2, 2, 53, 0.1);
    padding: 50px 45px;
    border-right: 0;
}

.pricing-table.style-three .pricing-header {
    text-align: left;
    padding-bottom: 0;
    margin-bottom: 23px;
    border-bottom: 0;
}

.pricing-table.style-three .pricing-header .price-title {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: inherit;
    text-transform: capitalize;
    margin-bottom: 22px;
}

.pricing-table.style-three .pricing-header .price {
    font-size: 34px;
    color: #14aeee;
    font-weight: 600;
    margin-bottom: 18px;
}

.pricing-table.style-three .pricing-header .price span {
    font-size: 16px;
    color: #2c234d;
}

.pricing-table.style-three .pricing-header p {
    color: #2c234d;
}

.pricing-table.style-three .price-feture {
    margin-bottom: 42px;
}

.pricing-table.style-three .price-feture li {
    position: relative;
    font-size: 14px;
    color: #2c234d;
}

.pricing-table.style-three .price-feture li:before {
    content: 'N';
    font-family: eleganticons;
    left: 0;
    position: absolute;
    color: #3dbd70;
}

.pricing-table.style-three .price-feture li span {
    font-size: 12px;
    background: #40de7b;
    color: #fff;
    padding: 4px 7px;
    display: inline-block;
    line-height: 1;
    border-radius: 4px;
    margin-left: 5px;
}

.pricing-table.style-three .price-feture li span.red {
    background: #f62c2c;
}

.pricing-table.style-three .pix-btn {
    background: #def4fd;
    color: #14aeee;
    border: 0;
    width: auto;
    padding: 11px 42px;
}

.pricing-table.style-three .pix-btn:hover {
    color: #fff;
    background: #14aeee;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.pricing-hosting {
    padding: 110px 0 90px;
}

.tabed-content .tabs-item {
    display: none;
}

.pricing-tab {
    text-align: center;
    margin-bottom: 80px;
    position: relative;
    z-index: 2;
}

.pricing-tab .tab-btn {
    cursor: pointer;
}

.pricing-tab .monthly_tab_title {
    color: #fa7070;
}

.pricing-tab .pricing-tab-switcher {
    height: 40px;
    width: 90px;
    display: inline-block;
    border-radius: 50px;
    position: relative;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    margin: 0 18px;
    -webkit-transform: translateY(-3px);
    -ms-transform: translateY(-3px);
    transform: translateY(-3px);
    border: 1px solid #dcd2d2;
    border-radius: 30px;
    cursor: pointer;
}

.pricing-tab .pricing-tab-switcher:before {
    content: '';
    position: absolute;
    left: 5px;
    top: 4px;
    background: #fa7070;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    -webkit-box-shadow: 0px 14px 24px 0px rgba(176, 70, 70, 0.4);
    box-shadow: 0px 14px 24px 0px rgba(176, 70, 70, 0.4);
}

.pricing-tab .pricing-tab-switcher.active:before {
    left: calc(100% - 35px);
}

.pricing-tab.seleceted .annual_tab_title {
    color: #fa7070;
}

.pricing-tab.seleceted .monthly_tab_title {
    color: #797687;
}

.pricing-tab span {
    vertical-align: middle;
}

.pricing-tab.color-two .pricing-tab-switcher:before {
    background: #7052fb;
    -webkit-box-shadow: 0px 14px 24px 0px rgba(90, 70, 176, 0.4);
    box-shadow: 0px 14px 24px 0px rgba(90, 70, 176, 0.4);
}

.pricing-tab.color-two .monthly_tab_title {
    color: #7052fb;
}

.pricing-tab.color-two.seleceted .annual_tab_title {
    color: #7052fb;
}

.pricing-tab.color-two.seleceted .monthly_tab_title {
    color: #797687;
}

.tabs-wrapper {
    max-width: 830px;
    margin: 0 auto;
}

.faq-tabs {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 40px;
    position: relative;
    z-index: 22;
}

.faq-tabs li {
    margin-bottom: 20px;
}

.faq-tabs li:not(:last-child) {
    margin-right: 20px;
}

.faq-tabs li a {
    font-size: 14px;
    font-weight: 600;
    color: #fa7070;
    background: rgba(250, 112, 112, 0.122);
    padding: 1px 25px;
    border-radius: 4px;
    display: inline-block;
}

.faq-tabs li a:hover,
.faq-tabs li a.active {
    background: #fa7070;
    color: #fff;
}

.faq-tabs li:nth-child(2) a {
    background: rgba(94, 103, 225, 0.122);
    color: #5e67e1;
}

.faq-tabs li:nth-child(2) a:hover,
.faq-tabs li:nth-child(2) a.active {
    background: #5e67e1;
    color: #ffff;
}

.faq-tabs li:nth-child(3) a {
    background: rgba(26, 201, 88, 0.122);
    color: #1ac958;
}

.faq-tabs li:nth-child(3) a:hover,
.faq-tabs li:nth-child(3) a.active {
    background: #1ac958;
    color: #ffff;
}

.faq-tabs li:nth-child(4) a {
    background: rgba(251, 43, 224, 0.122);
    color: #fb2be0;
}

.faq-tabs li:nth-child(4) a:hover,
.faq-tabs li:nth-child(4) a.active {
    background: #fb2be0;
    color: #ffff;
}

.pricing-service {
    background: #fdf8f8;
    padding: 116px 0 90px;
}

@media (max-width: 991px) {
    .pricing {
        padding: 76px 0 80px;
    }
    .pricing-service {
        padding: 76px 0 50px;
    }
    .pricing-single-one,
    .pricing-two-single {
        padding: 76px 0 50px;
    }
    .pricing-tab {
        margin-bottom: 40px;
    }
    .pricing-table {
        border: 1px solid #efe7e7;
        padding: 40px;
        max-width: 500px;
        margin: 0 auto 30px;
    }
    .faq-tabs {
        margin-bottom: 30px;
    }
    .pricing-two {
        padding-top: 66px;
        padding-bottom: 50px;
    }
    .pricing .scroll-circle {
        width: 250px;
    }
}

@media (max-width: 768px) {
    .faq-tabs li {
        margin-bottom: 10px;
    }
}


/*--------------------------------------------------------------
##  Team
--------------------------------------------------------------*/

.teams {
    padding: 116px 0 75px;
}

.teams-single {
    padding: 116px 0 120px;
}

.teams-two {
    padding-top: 116px;
    padding-bottom: 120px;
}

.teams-two .scroll-circle {
    right: 0;
    left: auto;
    top: 25%;
    z-index: -1;
}

.team-member {
    margin-bottom: 70px;
    max-width: 370px;
    margin: 0 auto 70px;
}

.team-member .member-avater {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
}

.team-member .member-avater svg {
    position: absolute;
    bottom: -100%;
    left: 0;
    -webkit-transition: all 0.6s ease-in-out;
    -o-transition: all 0.6s ease-in-out;
    transition: all 0.6s ease-in-out;
}

.team-member .member-avater svg.layer-two {
    -webkit-transition-delay: 0.3s;
    -o-transition-delay: 0.3s;
    transition-delay: 0.3s;
}

.team-member .member-avater svg:hover {
    bottom: 0;
}

.team-member .member-avater .member-social {
    margin: 0;
    padding: 0;
    list-style: none;
    position: absolute;
    bottom: 20px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 333;
    opacity: 0;
    -webkit-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    -webkit-transition-delay: 0.3s;
    -o-transition-delay: 0.3s;
    transition-delay: 0.3s;
}

.team-member .member-avater .member-social li {
    display: inline-block;
    margin-right: 5px;
}

.team-member .member-avater .member-social li a {
    display: inline-block;
    height: 46px;
    width: 46px;
    line-height: 46px;
    text-align: center;
    color: #fff;
    border: 2px solid #fff;
    border-radius: 50%;
}

.team-member .member-avater .member-social li a:hover {
    background: #fff;
    color: #fa7070;
    -webkit-box-shadow: 0px 20px 20px 0px rgba(79, 35, 35, 0.2);
    box-shadow: 0px 20px 20px 0px rgba(79, 35, 35, 0.2);
}

.team-member .member-avater:hover svg {
    bottom: 0;
}

.team-member .member-avater:hover .member-social {
    bottom: 40px;
    opacity: 1;
}

.team-member .team-info {
    text-align: center;
    padding-top: 25px;
}

.team-member .team-info .name {
    font-size: 20px;
    font-weight: 600;
}

.team-member .team-info .job {
    font-size: 16px;
    color: #797687;
    font-weight: 400;
}

.image {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 1920px;
    height: 5976px;
    z-index: 242;
}

.teams-three {
    padding-bottom: 110px;
}

.team-member-two {
    position: relative;
    border-radius: 6px;
    overflow: hidden;
    margin-bottom: 10px;
}

.team-member-two img {
    width: 100%;
    -webkit-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

.team-member-two:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #7052fb;
    opacity: 0.9;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.team-member-two .team-info {
    position: absolute;
    top: 40px;
    left: 40px;
    z-index: 22;
    height: 50px;
    overflow: hidden;
}

.team-member-two .team-info .name {
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    -webkit-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    -webkit-transform: translateY(-25px);
    -ms-transform: translateY(-25px);
    transform: translateY(-25px);
}

.team-member-two .team-info .job {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    -webkit-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
}

.team-member-two .member-social {
    margin: 0;
    padding: 0;
    list-style: none;
    position: absolute;
    left: 40px;
    bottom: 40px;
    z-index: 22;
    height: 28px;
    overflow: hidden;
}

.team-member-two .member-social li {
    display: inline-block;
    margin-right: 15px;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

.team-member-two .member-social li:nth-child(2) {
    -webkit-transition-delay: 0.1s;
    -o-transition-delay: 0.1s;
    transition-delay: 0.1s;
}

.team-member-two .member-social li:nth-child(3) {
    -webkit-transition-delay: 0.2s;
    -o-transition-delay: 0.2s;
    transition-delay: 0.2s;
}

.team-member-two .member-social li:nth-child(4) {
    -webkit-transition-delay: 0.3s;
    -o-transition-delay: 0.3s;
    transition-delay: 0.3s;
}

.team-member-two .member-social li:nth-child(5) {
    -webkit-transition-delay: 0.4s;
    -o-transition-delay: 0.4s;
    transition-delay: 0.4s;
}

.team-member-two .member-social li a {
    color: #fff;
}

.team-member-two:hover:before {
    opacity: 0.9;
    visibility: visible;
}

.team-member-two:hover .team-info .name,
.team-member-two:hover .team-info .job {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
}

.team-member-two:hover .member-social li {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
}

.team-member-three {
    margin-bottom: 30px;
}

.team-member-three .member-avater {
    position: relative;
    border-radius: 6px;
    overflow: hidden;
}

.team-member-three .member-avater img {
    width: 100%;
    -webkit-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

.team-member-three .member-avater:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #12bf7c;
    opacity: 0.9;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.team-member-three .member-avater .member-social {
    margin: 0;
    padding: 0;
    list-style: none;
    position: absolute;
    left: 40px;
    bottom: 40px;
    z-index: 22;
    height: 28px;
    overflow: hidden;
}

.team-member-three .member-avater .member-social li {
    display: inline-block;
    margin-right: 15px;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

.team-member-three .member-avater .member-social li:nth-child(2) {
    -webkit-transition-delay: 0.1s;
    -o-transition-delay: 0.1s;
    transition-delay: 0.1s;
}

.team-member-three .member-avater .member-social li:nth-child(3) {
    -webkit-transition-delay: 0.2s;
    -o-transition-delay: 0.2s;
    transition-delay: 0.2s;
}

.team-member-three .member-avater .member-social li:nth-child(4) {
    -webkit-transition-delay: 0.3s;
    -o-transition-delay: 0.3s;
    transition-delay: 0.3s;
}

.team-member-three .member-avater .member-social li:nth-child(5) {
    -webkit-transition-delay: 0.4s;
    -o-transition-delay: 0.4s;
    transition-delay: 0.4s;
}

.team-member-three .member-avater .member-social li a {
    color: #fff;
}

.team-member-three .member-avater:hover:before {
    opacity: 0.9;
    visibility: visible;
}

.team-member-three .member-avater:hover .member-social li {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
}

.team-member-three .team-info {
    padding: 25px 40px 0;
}

.team-member-three .team-info .name {
    color: #1a133b;
    font-size: 20px;
    font-weight: 600;
    -webkit-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

.team-member-three .team-info .job {
    color: #76747f;
    font-size: 14px;
    font-weight: 400;
    -webkit-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

.team-member-four {
    margin-bottom: 30px;
}

.team-member-four .member-avater {
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.team-member-four .member-avater img {
    width: 100%;
    -webkit-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

.team-member-four .member-avater:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #f98e47;
    opacity: 0.9;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.team-member-four .member-avater .member-social {
    margin: 0;
    padding: 0;
    list-style: none;
    position: absolute;
    left: 0;
    bottom: 20px;
    z-index: 22;
    overflow: hidden;
    width: 100%;
    text-align: center;
}

.team-member-four .member-avater .member-social li {
    display: inline-block;
    margin-right: 5px;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

.team-member-four .member-avater .member-social li:nth-child(2) {
    -webkit-transition-delay: 0.1s;
    -o-transition-delay: 0.1s;
    transition-delay: 0.1s;
}

.team-member-four .member-avater .member-social li:nth-child(3) {
    -webkit-transition-delay: 0.2s;
    -o-transition-delay: 0.2s;
    transition-delay: 0.2s;
}

.team-member-four .member-avater .member-social li:nth-child(4) {
    -webkit-transition-delay: 0.3s;
    -o-transition-delay: 0.3s;
    transition-delay: 0.3s;
}

.team-member-four .member-avater .member-social li:nth-child(5) {
    -webkit-transition-delay: 0.4s;
    -o-transition-delay: 0.4s;
    transition-delay: 0.4s;
}

.team-member-four .member-avater .member-social li a {
    color: #fff;
    height: 40px;
    width: 40px;
    line-height: 40px;
    font-size: 14px;
    border: 2px solid #fab57f;
    border-radius: 50%;
    display: block;
    text-align: center;
}

.team-member-four .member-avater .member-social li a:hover {
    background: #fff;
    -webkit-box-shadow: 0px 10px 14px 0px rgba(56, 15, 2, 0.1);
    box-shadow: 0px 10px 14px 0px rgba(56, 15, 2, 0.1);
    color: #f98e47;
    border-color: #fff;
}

.team-member-four .member-avater:hover {
    -webkit-box-shadow: 0px 20px 30px 0px rgba(56, 24, 2, 0.16);
    box-shadow: 0px 20px 30px 0px rgba(56, 24, 2, 0.16);
}

.team-member-four .member-avater:hover:before {
    opacity: 0.9;
    visibility: visible;
}

.team-member-four .member-avater:hover .member-social li {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
}

.team-member-four .team-info {
    padding-top: 25px;
    text-align: center;
}

.team-member-four .team-info .name {
    color: #1a133b;
    font-size: 15px;
    font-weight: 500;
    -webkit-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

.team-member-four .team-info .job {
    color: #615f6b;
    font-size: 14px;
    font-weight: 400;
    -webkit-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

#team-slider .swiper-pagination {
    margin-top: 50px;
}

.teams-four {
    padding: 116px 0 135px;
}

@media (max-width: 991px) {
    .teams-four {
        padding: 76px 0 70px;
    }
}

.teams-six {
    padding: 115px 0 300px;
}

@media (max-width: 991px) {
    .teams-six {
        padding: 75px 0 80px;
    }
}

.team-slider-wrapper .swiper-pagination {
    margin-top: 20px;
}

.team-slider-wrapper .swiper-pagination .swiper-pagination-bullet {
    height: 10px;
    width: 10px;
    background: #b9a79f;
    margin-right: 10px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.team-slider-wrapper .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: #f98e47;
}

@media (max-width: 991px) {
    .teams,
    .teams-two {
        padding: 76px 0 50px;
    }
    .teams-two {
        padding: 76px 0 80px;
    }
    .team-member {
        margin-bottom: 40px;
    }
    .section-small {
        margin: 0;
    }
    .teams-single {
        padding: 76px 0 80px;
    }
    .teams-single .button-container {
        margin-top: 10px;
    }
    .teams-two .scroll-circle {
        max-width: 320px;
    }
    .teams-three {
        padding-bottom: 70px;
    }
}

@media (max-width: 768px) {
    .teams-two .scroll-circle {
        max-width: 330px;
    }
}

@media (max-width: 576px) {
    .scroll-circle {
        display: none;
    }
}


/*--------------------------------------------------------------
  ##  Faq's
  --------------------------------------------------------------*/

.faq-section {
    padding-top: 115px;
}

.faq-section-two {
    padding-top: 75px;
}

.faqs {
    padding: 120px 0;
}

.card {
    border: 0;
    background: transparent;
}

.card .card-header {
    background-color: transparent;
    border: 0;
    border-radius: 6px;
}

.card .card-header .btn-link {
    padding: 0;
    color: #2b2350;
    font-size: 20px;
    font-weight: 600;
    text-decoration: none;
}

.card .card-body {
    padding: 0;
}

.card .card-body p {
    font-size: 16px;
    margin-bottom: 25px;
}

.faq {
    position: relative;
    z-index: 2;
}

.faq .card {
    border: 0;
    background: transparent;
    margin-bottom: 20px;
    border: 1px solid #efe7e7;
}

.faq .card .card-header {
    padding: 0;
}

.faq .card .card-header h5 {
    position: relative;
    margin: 0;
}

.faq .card .card-header .btn-link {
    color: #2b2350;
    font-size: 20px;
    font-weight: 500;
    text-decoration: none;
    white-space: normal;
    text-align: left;
    width: 100%;
    padding: 18px 40px;
}

.faq .card .card-header .btn-link:before {
    content: '2';
    display: block;
    position: absolute;
    top: 50%;
    right: 40px;
    z-index: 9;
    font-family: eleganticons;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 20px;
    color: #fa7070;
}

.faq .card .card-header .btn-link.collapsed:before {
    content: '3';
    color: #aaaaaa;
}

.faq .card .card-body {
    padding: 0 40px 40px;
}

.faq .card .card-body p {
    font-size: 16px;
    margin-bottom: 0;
}

.faq .card.active {
    -webkit-box-shadow: 0px 60px 100px 0px rgba(79, 35, 35, 0.1);
    box-shadow: 0px 60px 100px 0px rgba(79, 35, 35, 0.1);
}

.faq-forms {
    padding-top: 120px;
}

.faq-forms .section-title {
    margin-bottom: 50px;
}

.faq-forms {
    max-width: 830px;
    margin: 0 auto;
}

@media (max-width: 991px) {
    #faqs {
        padding: 80px 0 50px;
    }
    .faq-two {
        margin-bottom: 30px;
    }
    .faq-section-two {
        padding-top: 65px;
    }
    .faq-section {
        padding-top: 75px;
    }
    .faqs {
        padding: 80px 0;
    }
    .faq-forms {
        padding-top: 78px;
    }
}

@media (max-width: 768px) {
    #faqs {
        padding: 50px 0 20px;
    }
    .faq .card .card-header h5:before {
        right: 20px;
    }
    .faq .card .card-header .btn-link {
        padding: 18px 20px;
    }
}

@media (max-width: 576px) {
    .faq .card .card-header .btn-link {
        padding: 18px 30px 18px 20px;
        font-size: 18px;
    }
    .faq .card .card-header h5:before {
        right: 10px;
    }
    .faq .card .card-body {
        padding: 5px 20px 25px;
    }
}


/*--------------------------------------------------------------
##  Logo Carousel
--------------------------------------------------------------*/

#brand-logo .section-small {
    margin-top: -90px;
}

.brand-logo {
    text-align: center;
}

.brand-logo img {
    opacity: 0.5;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.brand-logo img:hover {
    opacity: 1;
}

#brand-logo-two {
    padding-top: 350px;
}

@media (max-width: 991px) {
    #brand-logo-two {
        padding-top: 180px;
    }
}

@media (max-width: 576px) {
    #brand-logo-two {
        padding-top: 80px;
    }
}

.brand-logo-ab {
    padding-bottom: 120px;
}

.logo-carousel .swiper-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.brand-logo-two {
    height: 100px;
    width: 100px;
    min-width: 100px;
    min-height: 100px;
    background: #fff;
    -webkit-box-shadow: 0px 2px 4px 0px rgba(14, 3, 52, 0.06);
    box-shadow: 0px 2px 4px 0px rgba(14, 3, 52, 0.06);
    border-radius: 50%;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 auto;
}

.logo-carousel-travel .swiper-wrapper {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.logo-carousel-travel .brand-logo img {
    opacity: 1;
}


/**
Logo List
 */

.client-logo-list {
    padding-bottom: 65px;
}

.logo-list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.logo-list li {
    width: 20%;
    margin-bottom: 82px;
}

@media (max-width: 991px) {
    .logo-list li {
        width: 33.3333%;
    }
}

@media (max-width: 576px) {
    .logo-list li {
        width: 50%;
    }
}

.logo-list li a {
    display: block;
}

@media (max-width: 991px) {
    #brand-logo {
        padding-top: 76px;
    }
    #brand-logo .section-small {
        margin-top: 0;
    }
    #brand-logo .section-small .title {
        margin-bottom: 50px;
    }
    .brand-logo-ab {
        padding-bottom: 75px;
    }
    .brand-logo-ab .section-small .title {
        margin-bottom: 60px;
    }
}

@media (max-width: 768px) {
    #brand-logo {
        padding-top: 30px;
    }
    #brand-logo .section-small {
        margin-top: 0;
    }
}

@media (max-width: 480px) {
    #brand-logo {
        padding-top: 0;
    }
    #brand-logo .section-small {
        margin-top: 0;
    }
}


/*--------------------------------------------------------------
  ##  Call To Action
  --------------------------------------------------------------*/

.call-to-action {
    background-image: -o-linear-gradient(350deg, #fa7070 0%, #fa9770 100%);
    background-image: linear-gradient(100deg, #fa7070 0%, #fa9770 100%);
    padding: 111px 0 120px;
    overflow: hidden;
}

.call-to-action .overlay-bg {
    position: absolute;
    right: 0;
    top: 0;
}

.call-to-action .action-content {
    position: relative;
}

.call-to-action .action-content .title {
    color: #fff;
    font-size: 50px;
    line-height: 64px;
    font-weight: 700;
    margin-bottom: 28px;
}

.call-to-action .action-content p {
    color: #fff;
    margin-bottom: 50px;
}

.call-to-action .action-content .pix-btn {
    padding: 12px 50px;
    font-size: 16px;
    font-weight: 500;
    position: relative;
    z-index: 2;
}

.call-to-action .action-content.style-two .title {
    font-size: 40px;
    line-height: 54px;
    margin: 0;
}

.call-to-action.action-padding {
    padding: 172px 0 168px;
}

.call-to-action .scroll-circle {
    right: 0;
    top: 40%;
}

.signup-section {
    padding: 106px 0 111px;
    background-size: cover;
    background-position: center center;
    position: relative;
    background-image: -webkit-gradient( linear, left bottom, left top, from(#4f29dd), to(#7052fb));
    background-image: -o-linear-gradient(bottom, #4f29dd 0%, #7052fb 100%);
    background-image: linear-gradient(0deg, #4f29dd 0%, #7052fb 100%);
    overflow: hidden;
}

.signup-section .bg-shape {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.signup-section .bg-shape img {
    position: absolute;
}

.signup-section .bg-shape .shape-left {
    top: 0;
    left: 0;
}

.signup-section .bg-shape .shape-right {
    bottom: 0;
    right: 0;
}

.signup-heading .title {
    font-size: 50px;
    line-height: 64px;
    color: #fff;
    font-weight: 700;
    margin-bottom: 20px;
}

.signup-heading p {
    color: #fff;
    margin: 0;
}

.call-to-action-three {
    background: #f97bc5;
    padding: 230px 0 130px;
    position: relative;
    overflow: hidden;
}

.call-to-action-three:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border-top: 0px solid transparent;
    border-bottom: 170px solid transparent;
    border-right: 3000px solid #fff;
}

.call-to-action-three .bg-right-shape {
    position: absolute;
    right: 0;
    bottom: 0;
}

.call-to-action-three .action-content .title {
    font-size: 50px;
    line-height: 60px;
    color: #fff;
    margin-bottom: 30px;
}

.call-to-action-three .action-content p {
    color: #fff;
    font-size: 24px;
    font-weight: 500;
    margin: 0;
    margin: 0;
}

.call-to-action-four {
    background: #f9f8fc;
    padding: 50px 0;
    border-top: 1px solid #e9e7f1;
}

@media (max-width: 768px) {
    .call-to-action-four .text-right {
        text-align: left !important;
        margin-top: 30px;
    }
}

.call-to-action-four .action-content .title {
    font-size: 26px;
    line-height: 40px;
    font-weight: 600;
}

.call-to-action-four .action-content p {
    font-size: 26px;
    line-height: 40px;
    color: #75737e;
    margin: 0;
}

@media (max-width: 768px) {
    .call-to-action-four .action-content p {
        font-size: 20px;
    }
}

.call-to-action-four .pix-btn {
    padding: 15px 43px;
}

.call-to-action-hosting {
    padding: 130px 0 140px;
    background-image: url('../../media/background/call-to-action.jpg');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.call-to-action-hosting .section-title {
    margin-bottom: 38px;
}

.call-to-action-hosting .btn-outline {
    margin-left: 15px;
}

@media (max-width: 380px) {
    .call-to-action-hosting .btn-outline {
        margin-left: 0;
        margin-top: 20px;
    }
}

@media (max-width: 1200px) {
    .call-to-action .action-content.style-two .title {
        font-size: 36px;
        line-height: 50px;
    }
    .signup-heading .title {
        font-size: 40px;
        line-height: 50px;
    }
}

@media (max-width: 991px) {
    .call-to-action {
        padding: 71px 0 80px;
    }
    .call-to-action.action-padding {
        padding: 74px 0 80px;
    }
    .call-to-action .action-content .title {
        font-size: 40px;
        line-height: 54px;
    }
    .call-to-action .text-right {
        text-align: center !important;
    }
    .call-to-action .action-content.style-two {
        margin-bottom: 30px;
        text-align: center;
    }
    .call-to-action .action-content.style-two .title {
        font-size: 32px;
        line-height: 42px;
    }
    .call-to-action .action-content.style-two .title br {
        display: none;
    }
    .call-to-action .scroll-circle {
        max-width: 400px;
    }
    .call-to-action-three {
        padding: 130px 0 100px;
    }
    .call-to-action-three .action-content .title {
        font-size: 36px;
        line-height: 46px;
        margin-bottom: 20px;
    }
    .call-to-action-three .action-content p {
        font-size: 20px;
    }
}

@media (max-width: 768px) {
    .call-to-action .action-content .title {
        font-size: 32px;
        line-height: 42px;
    }
    .call-to-action .action-content p br {
        display: none;
    }
    .call-to-action .scroll-circle {
        max-width: 300px;
    }
    .signup-section .button-container {
        margin-top: 30px;
    }
    .signup-section .button-container.text-right {
        text-align: left !important;
    }
    .call-to-action-three .text-right {
        margin-top: 20px;
        text-align: left !important;
    }
}


/*--------------------------------------------------------------
##  Funfact
--------------------------------------------------------------*/

.countup {
    padding: 116px 0 120px;
}

.countup .pix-btn {
    margin-top: 62px;
    position: relative;
    z-index: 2;
}

.countup .scroll-circle {
    top: 58%;
}

.countup-two {
    padding: 60px 0 120px;
}

.countup-wrapper {
    position: relative;
    z-index: 22;
}

.bg-map {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: -1;
    top: 15px;
    left: 0;
    background-position: center center;
    background-repeat: no-repeat;
}

.fun-fact {
    margin-bottom: 30px;
}

.fun-fact .count,
.fun-fact span {
    font-size: 50px;
    font-weight: 400;
    margin: 0;
    line-height: 1.2;
    color: #7052fb;
    display: inline-block;
}

.fun-fact p {
    font-size: 18px;
    margin: 0;
}

.fun-fact.color-two .count,
.fun-fact.color-two span {
    color: #fa7070;
}

.fun-fact.color-three .count,
.fun-fact.color-three span {
    color: #22cd1a;
}

.fun-fact.color-four .count,
.fun-fact.color-four span {
    color: #f62ee1;
}

.fun-fact.style-two .count,
.fun-fact.style-two span {
    font-size: 40px;
    color: #1a133b;
}

.fun-fact.style-two p {
    font-size: 14px;
    color: #615f6b;
}

.countup-wrapper-two {
    border-bottom: 1px solid #f1f0f7;
    padding-bottom: 60px;
}

.fun-fact-two {
    text-align: center;
    margin-bottom: 30px;
}

.fun-fact-two .icon-container {
    border-radius: 10px;
    height: 60px;
    width: 60px;
    line-height: 68px;
    background: rgba(112, 82, 251, 0.141);
    margin: 0 auto 43px;
}

.fun-fact-two .icon-container i {
    font-size: 25px;
    color: #7052fb;
}

.fun-fact-two .counter p {
    font-size: 20px;
    color: #5e5b74;
}

.fun-fact-two .counter h4 {
    font-size: 54px;
    font-weight: 300;
    color: #7052fb;
}

.fun-fact-two.color-two .icon-container {
    background: rgba(253, 176, 26, 0.141);
}

.fun-fact-two.color-two .icon-container i {
    color: #fdb01a;
}

.fun-fact-two.color-two .counter h4 {
    color: #fdb01a;
}

.fun-fact-two.color-three .icon-container {
    background: rgba(246, 74, 143, 0.141);
}

.fun-fact-two.color-three .icon-container i {
    color: #f64a8f;
}

.fun-fact-two.color-three .counter h4 {
    color: #f64a8f;
}

.fun-fact-two.color-four .icon-container {
    background: rgba(80, 205, 138, 0.141);
}

.fun-fact-two.color-four .icon-container i {
    color: #50cd8a;
}

.fun-fact-two.color-four .counter h4 {
    color: #50cd8a;
}

@media (max-width: 991px) {
    .countup .scroll-circle {
        left: -30%;
    }
    .countup {
        padding: 76px 0 75px;
    }
    .countup .pix-btn {
        margin-top: 30px;
    }
    .countup-two {
        padding-bottom: 80px;
    }
}

@media (max-width: 768px) {
    .countup .scroll-circle {
        left: -30%;
    }
}

@media (max-width: 576px) {
    .fun-fact {
        text-align: center;
    }
}


/*--------------------------------------------------------------
##  Newsletter
--------------------------------------------------------------*/

.newsletter {
    padding: 146px 0;
    background-size: cover;
    background-position: center center;
    overflow: hidden;
}

.newsletter .scroll-circle {
    bottom: auto;
    right: 0;
    top: 100px;
}

.newsletter-two {
    background-image: -o-linear-gradient(350deg, #fa7070 0%, #fa9770 100%);
    background-image: linear-gradient(100deg, #fa7070 0%, #fa9770 100%);
    padding: 118px 0 130px;
    overflow: hidden;
}

.newsletter-two .scroll-circle {
    bottom: -79%;
    right: -7%;
    z-index: 1;
}

.form-result.alert {
    display: none;
    margin-top: 20px;
    margin-bottom: 0;
}

.newsletter-content .title {
    font-size: 40px;
    line-height: 50px;
    color: #fff;
    font-weight: 700;
}

.newsletter-content p {
    color: #fff;
}

.newsletter-form {
    max-width: 570px;
    margin-left: auto;
    position: relative;
    z-index: 22;
}

.newsletter-form .newsletter-inner {
    background: #fff;
    border-radius: 50px;
    overflow: hidden;
    padding: 5px;
    -webkit-box-shadow: 0px 20px 30px 0px rgba(79, 35, 35, 0.15);
    box-shadow: 0px 20px 30px 0px rgba(79, 35, 35, 0.15);
}

.newsletter-form .newsletter-inner input {
    background: transparent;
    border: 0;
    height: 60px;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 0 170px 0 40px;
    margin: 0;
    width: 100%;
}

.newsletter-form .newsletter-inner input::-webkit-input-placeholder {
    color: #8e8b9e;
}

.newsletter-form .newsletter-inner input::-moz-placeholder {
    color: #8e8b9e;
}

.newsletter-form .newsletter-inner input:-ms-input-placeholder {
    color: #8e8b9e;
}

.newsletter-form .newsletter-inner input::-ms-input-placeholder {
    color: #8e8b9e;
}

.newsletter-form .newsletter-inner input::placeholder {
    color: #8e8b9e;
}

.newsletter-form .newsletter-inner .newsletter-submit {
    background: #7052fb;
    border-radius: 50px;
    padding: 0 40px;
    color: #fff;
    border: 0;
    font-weight: 600;
    position: absolute;
    top: 5px;
    right: 5px;
    height: 60px;
}

.newsletter-form .newsletter-inner .newsletter-submit i {
    display: none;
}

.newsletter-form .newsletter-inner .newsletter-submit:hover {
    background: #5b39fa;
}

.newsletter-form .newsletter-inner .newsletter-submit.clicked i {
    display: block;
}

.newsletter-form .newsletter-inner .newsletter-submit.clicked .btn-text {
    display: none;
}

.newsletter-content-two {
    position: relative;
    z-index: 2;
}

.newsletter-content-two .title {
    font-size: 40px;
    line-height: 54px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 20px;
}

.newsletter-content-two p {
    color: #fff;
    margin-bottom: 70px;
    display: inline-block;
    max-width: 750px;
}

.newsletter-form-two {
    max-width: 770px;
    margin: 0 auto;
    z-index: 22;
    position: relative;
}

.newsletter-form-two .newsletter-inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.newsletter-form-two .newsletter-inner input {
    background: transparent;
    border: 0;
    outline: 0;
    color: #fff;
    border-bottom: 1px solid #fff;
    padding: 15px 15px 15px 0;
    border-radius: 0;
    height: 60px;
    max-width: 500px;
    margin: 0;
    width: 100%;
}

.newsletter-form-two .newsletter-inner input:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.newsletter-form-two .newsletter-inner input::-webkit-input-placeholder {
    color: #fff;
}

.newsletter-form-two .newsletter-inner input::-moz-placeholder {
    color: #fff;
}

.newsletter-form-two .newsletter-inner input:-ms-input-placeholder {
    color: #fff;
}

.newsletter-form-two .newsletter-inner input::-ms-input-placeholder {
    color: #fff;
}

.newsletter-form-two .newsletter-inner input::placeholder {
    color: #fff;
}

.newsletter-form-two .newsletter-inner .newsletter-submit {
    width: 220px;
    height: 60px;
    background: #fff;
    border: 0;
    border-radius: 30px;
    color: #fa7070;
    font-weight: 600;
    -webkit-box-shadow: 0px 20px 30px 0px rgba(79, 35, 35, 0.15);
    box-shadow: 0px 20px 30px 0px rgba(79, 35, 35, 0.15);
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.newsletter-form-two .newsletter-inner .newsletter-submit i {
    display: none;
}

.newsletter-form-two .newsletter-inner .newsletter-submit:hover {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.newsletter-form-travel .newsletter-inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.newsletter-form-travel input {
    background: #fff;
    border-radius: 10px;
    margin-right: 20px;
    margin-bottom: 0;
}

.newsletter-form-travel .newsletter-submit {
    background: #291154;
    color: #fff;
    border: 0;
    border-radius: 10px;
    min-width: 150px;
}

.newsletter-form-travel .newsletter-submit .fa-spin {
    display: none;
}

@media (max-width: 480px) {
    .newsletter-form-travel .newsletter-submit {
        min-width: 110px;
    }
}

@media (max-width: 991px) {
    .newsletter {
        padding: 70px 0 80px;
    }
    .newsletter-form .newsletter-inner input {
        height: 55px;
    }
    .newsletter-two {
        padding: 70px 0 80px;
    }
    .newsletter-content .title {
        font-size: 32px;
        line-height: 42px;
    }
    .newsletter-form {
        margin-left: 0;
        margin: 30px auto 0;
    }
    .newsletter-content {
        text-align: center;
    }
    .newsletter-content-two .title {
        font-size: 34px;
        line-height: 44px;
    }
    .newsletter-content-two p br {
        display: none;
    }
    .newsletter-two .scroll-circle {
        bottom: -40%;
        right: -10%;
    }
    .newsletter-two .scroll-circle img {
        max-width: 380px;
    }
    .newsletter .scroll-circle {
        max-width: 500px;
    }
    .newsletter-form .newsletter-inner input {
        padding: 0 170px 0 40px;
    }
    .newsletter-form .newsletter-inner .newsletter-submit {
        height: 55px;
    }
}

@media (max-width: 768px) {
    .newsletter-two .scroll-circle {
        right: -80%;
    }
    .newsletter .scroll-circle {
        max-width: 400px;
    }
    .newsletter-two p {
        margin-bottom: 50px;
    }
    .newsletter-two .newsletter-content-two .title {
        font-size: 30px;
        line-height: 40px;
    }
}

@media (max-width: 480px) {
    .newsletter-content-two .title,
    .call-to-action .action-content.style-two .title {
        font-size: 28px;
        line-height: 40px;
    }
    .newsletter-content-two .title br,
    .call-to-action .action-content.style-two .title br {
        display: none;
    }
    .newsletter-form-two .newsletter-inner .newsletter-submit {
        margin-top: 20px;
    }
}

@media (max-width: 480px) {
    .newsletter-content .title br {
        display: none;
    }
    .newsletter-form .newsletter-inner input,
    .newsletter-form .newsletter-inner .newsletter-submit {
        padding: 0 20px;
    }
    .newsletter-form-two .newsletter-inner {
        display: block;
    }
}


/*--------------------------------------------------------------
##  Page Banner
--------------------------------------------------------------*/

.page-banner {
    background: #fdf8f8;
    text-align: center;
    height: 550px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    overflow: hidden;
}

.page-banner .page-title {
    font-size: 50px;
    font-weight: 800;
    color: #2b2350;
    position: relative;
    z-index: 2;
    max-width: 820px;
    margin: 30px auto 15px;
}

.page-banner .bradcurmed {
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
    z-index: 2;
}

.page-banner .bradcurmed li {
    display: inline-block;
    position: relative;
    font-size: 18px;
}

.page-banner .bradcurmed li:not(:last-child) {
    margin-right: 30px;
}

.page-banner .bradcurmed li:not(:last-child):before {
    content: '5';
    font-family: eleganticons;
    position: absolute;
    right: -25px;
    top: 0;
}

.page-banner .bradcurmed li a {
    color: #2b2350;
    font-weight: 500;
}

.page-banner .bradcurmed li a:hover {
    color: #fa7070;
}

.page-banner .circle {
    position: absolute;
    top: -51%;
    left: -15%;
    z-index: 1;
}

.page-banner.blog-details-banner {
    height: 700px;
}

.page-banner.blog-details-banner .circle {
    position: absolute;
    top: -39%;
    left: -19%;
    z-index: 1;
}

.page-banner.blog-details-banner .page-title-wrapper {
    margin-top: 60px;
}

.page-banner.blog-details-banner .page-title {
    font-size: 40px;
    line-height: 54px;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 20px;
}

.page-banner.blog-details-banner .post-meta {
    position: relative;
    z-index: 22;
    padding: 0;
}

.page-banner.blog-details-banner .post-meta li {
    display: inline-block;
    margin-right: 30px;
    position: relative;
    text-transform: uppercase;
}

.page-banner.blog-details-banner .post-meta li:not(:last-child):after {
    position: absolute;
    content: '';
    right: -20px;
    top: 7px;
    width: 2px;
    height: 14px;
    background: #9997a3;
}

.page-banner.blog-details-banner .post-meta li a {
    color: #797687;
    text-transform: uppercase;
    font-weight: 500;
    margin-left: 5px;
}

.page-banner.blog-details-banner .post-meta li a:hover {
    color: #fa7070;
}

.page-banner.blog-details-banner .post-meta.color-theme {
    margin-bottom: 10px;
}

.page-banner.blog-details-banner .post-meta.color-theme li a {
    color: #fa7070;
}

.animate-ball {
    position: absolute;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    top: 0;
    left: 0;
}

.animate-ball .ball {
    background: #fdf1f1;
    border-radius: 50%;
    position: absolute;
}

.animate-ball .ball:nth-child(1) {
    height: 50px;
    width: 50px;
    top: 37%;
    left: 10%;
    -webkit-animation: zoommd 1.5s infinite alternate;
    animation: zoommd 1.5s infinite alternate;
}

.animate-ball .ball:nth-child(2) {
    height: 70px;
    width: 70px;
    bottom: 22%;
    right: 8%;
    -webkit-animation: bounce 4s infinite alternate;
    animation: bounce 4s infinite alternate;
}

.animate-ball .ball:nth-child(3) {
    height: 100px;
    width: 100px;
    bottom: 43%;
    right: 22%;
    -webkit-animation: zoommd 1.5s infinite alternate;
    animation: zoommd 1.5s infinite alternate;
}

.animate-ball .ball:nth-child(4) {
    height: 40px;
    width: 40px;
    bottom: 20%;
    right: 32%;
    -webkit-animation: movexy 1.5s infinite alternate;
    animation: movexy 1.5s infinite alternate;
}

.animate-ball .ball:nth-child(5) {
    height: 500px;
    width: 500px;
    top: -75%;
    right: 100px;
    -webkit-animation: wave 3s 0.1s infinite linear;
    animation: wave 3s 0.1s infinite linear;
}

.page-banner-contact {
    height: 690px;
    position: relative;
    overflow: hidden;
    background: #fdf8f8;
}

.page-banner-contact .circle {
    position: absolute;
    top: -40%;
    left: -15%;
}

.page-title-inner {
    position: relative;
    z-index: 2;
}

.animate-element-contact {
    position: relative;
    z-index: 222;
    height: 465px;
    width: 408px;
    bottom: -135px;
}

.animate-element-contact img {
    position: absolute;
    bottom: 0;
}

.animate-element-contact img:nth-child(1) {
    left: 5px;
    bottom: 55px;
}

.animate-element-contact img:nth-child(2) {
    bottom: 41px;
    left: 128px;
}

.animate-element-contact img:nth-child(3) {
    right: 0;
}

.animate-element-contact img:nth-child(4) {
    bottom: 0;
    left: 110px;
}

.page-banner {
    background: #fdf8f8;
    text-align: center;
    height: 550px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    overflow: hidden;
}

.page-banner .page-title {
    font-size: 50px;
    font-weight: 800;
    color: #2b2350;
    position: relative;
    z-index: 2;
    max-width: 820px;
    margin: 30px auto 15px;
}

.page-banner .bradcurmed {
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
    z-index: 2;
}

.page-banner .bradcurmed li {
    display: inline-block;
    position: relative;
    font-size: 18px;
}

.page-banner .bradcurmed li:not(:last-child) {
    margin-right: 30px;
}

.page-banner .bradcurmed li:not(:last-child):before {
    content: '5';
    font-family: eleganticons;
    position: absolute;
    right: -25px;
    top: 0;
}

.page-banner .bradcurmed li a {
    color: #2b2350;
    font-weight: 500;
}

.page-banner .bradcurmed li a:hover {
    color: #fa7070;
}

.page-banner .circle {
    position: absolute;
    top: -51%;
    left: -15%;
    z-index: 1;
}

.page-banner.blog-details-banner {
    height: 700px;
}

.page-banner.blog-details-banner .circle {
    position: absolute;
    top: -39%;
    left: -19%;
    z-index: 1;
}

.page-banner.blog-details-banner .page-title-wrapper {
    margin-top: 60px;
}

.page-banner.blog-details-banner .page-title {
    font-size: 40px;
    line-height: 54px;
    font-weight: 600;
    margin-bottom: 20px;
}

.page-banner.blog-details-banner .post-meta {
    position: relative;
    z-index: 22;
    padding: 0;
}

.page-banner.blog-details-banner .post-meta li {
    display: inline-block;
    margin-right: 30px;
    position: relative;
    text-transform: uppercase;
}

.page-banner.blog-details-banner .post-meta li:not(:last-child):after {
    position: absolute;
    content: '';
    right: -20px;
    top: 7px;
    width: 2px;
    height: 14px;
    background: #9997a3;
}

.page-banner.blog-details-banner .post-meta li a {
    color: #797687;
    text-transform: uppercase;
    font-weight: 500;
    margin-left: 5px;
}

.page-banner.blog-details-banner .post-meta li a:hover {
    color: #fa7070;
}

.page-banner.blog-details-banner .post-meta.color-theme {
    margin-bottom: 10px;
}

.page-banner.blog-details-banner .post-meta.color-theme li a {
    color: #fa7070;
}

.animate-ball {
    position: absolute;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    top: 0;
    left: 0;
}

.animate-ball .ball {
    background: #fdf1f1;
    border-radius: 50%;
    position: absolute;
}

.animate-ball .ball:nth-child(1) {
    height: 50px;
    width: 50px;
    top: 37%;
    left: 10%;
    -webkit-animation: zoommd 1.5s infinite alternate;
    animation: zoommd 1.5s infinite alternate;
}

.animate-ball .ball:nth-child(2) {
    height: 70px;
    width: 70px;
    bottom: 22%;
    right: 8%;
    -webkit-animation: bounce 4s infinite alternate;
    animation: bounce 4s infinite alternate;
}

.animate-ball .ball:nth-child(3) {
    height: 100px;
    width: 100px;
    bottom: 43%;
    right: 22%;
    -webkit-animation: zoommd 1.5s infinite alternate;
    animation: zoommd 1.5s infinite alternate;
}

.animate-ball .ball:nth-child(4) {
    height: 40px;
    width: 40px;
    bottom: 20%;
    right: 32%;
    -webkit-animation: movexy 1.5s infinite alternate;
    animation: movexy 1.5s infinite alternate;
}

.animate-ball .ball:nth-child(5) {
    height: 500px;
    width: 500px;
    top: -75%;
    right: 100px;
    -webkit-animation: wave 3s 0.1s infinite linear;
    animation: wave 3s 0.1s infinite linear;
}

.page-banner-contact {
    height: 700px;
    position: relative;
    overflow: hidden;
    background: #fdf8f8;
    z-index: 2;
}

.page-banner-contact .circle {
    position: absolute;
    top: -40%;
    left: -17%;
}

.page-banner-contact .page-title-wrapper {
    padding: 315px 0 245px;
    position: relative;
}

.page-banner-contact .page-title-wrapper .page-title {
    font-size: 40px;
    line-height: 54px;
}

.page-banner-contact .page-title-wrapper p {
    font-size: 16px;
    line-height: 30px;
}

.page-banner-contact .animate-ball .ball:nth-child(3) {
    bottom: 55%;
    right: 40%;
    height: 70px;
    width: 70px;
}

.page-banner-contact .animate-ball .ball:nth-child(4) {
    bottom: 28%;
    right: 40%;
}

.page-banner-contact .animate-ball .ball:nth-child(5) {
    top: -60%;
}

.animate-element-contact {
    position: relative;
    z-index: 222;
    height: 465px;
    width: 408px;
    bottom: -135px;
}

.animate-element-contact img {
    position: absolute;
    bottom: 0;
}

.animate-element-contact img:nth-child(1) {
    left: 5px;
    bottom: 55px;
}

.animate-element-contact img:nth-child(2) {
    bottom: 41px;
    left: 128px;
}

.animate-element-contact img:nth-child(3) {
    right: 0;
}

.animate-element-contact img:nth-child(4) {
    bottom: 0;
    left: 110px;
}

@media (max-width: 1280px) {
    .animate-element-contact {
        margin-left: -80px;
    }
}

@media (max-width: 1200px) {
    .page-banner {
        height: 400px;
    }
    .page-banner .circle {
        top: -110%;
        left: -35%;
    }
    .animate-ball .ball:nth-child(5) {
        top: -102%;
        right: 0;
    }
    .animate-element-contact {
        margin-left: -80px;
    }
}

@media (max-width: 1024px) {
    .circle {
        display: none;
    }
}

@media (max-width: 991px) {
    .page-banner {
        height: 300px;
    }
    .page-banner .page-banner .circle {
        top: -185%;
        left: -45%;
    }
    .page-banner .page-title {
        font-size: 40px;
        margin: 40px auto 15px;
    }
    .page-banner .animate-ball .ball:nth-child(3) {
        height: 50px;
        width: 50px;
    }
    .page-banner .animate-ball .ball:nth-child(3) {
        height: 40px;
        width: 40px;
    }
    .page-banner .animate-ball .ball:nth-child(5) {
        top: -190px;
        right: 0;
        height: 250px;
        width: 250px;
    }
    .page-banner.blog-details-banner {
        height: 400px;
    }
    .page-banner.blog-details-banner .page-title {
        font-size: 30px;
        line-height: 44px;
    }
    .page-banner.blog-details-banner .circle {
        left: -50%;
    }
    .page-banner-contact {
        height: 800px;
    }
    .page-banner-contact .page-title-wrapper {
        padding: 130px 0 0;
    }
    .animate-element-contact {
        height: 365px;
        margin: 0 auto;
    }
    .page-banner .circle {
        left: -60%;
    }
}

@media (max-width: 768px) {
    .page-banner .page-title {
        font-size: 30px;
        margin: 40px auto 10px;
    }
    .page-banner.blog-details-banner {
        height: 400px;
    }
    .page-banner.blog-details-banner .page-title {
        font-size: 26px;
        line-height: 36px;
    }
    .page-banner.blog-details-banner .circle {
        left: -70%;
    }
    .page-banner-contact .page-title-wrapper {
        padding: 100px 0 0;
    }
    .page-banner-contact .page-title-wrapper .page-title {
        font-size: 32px;
    }
    .page-banner-contact .page-title-wrapper p br {
        display: none;
    }
    .page-banner .circle {
        left: -80%;
    }
}

@media (max-width: 576px) {
    .page-banner .circle {
        left: -120%;
    }
}


/*--------------------------------------------------------------
## About
--------------------------------------------------------------*/

.about {
    padding-top: 120px;
}

.about-two {
    padding-bottom: 120px;
}

.about-content {
    padding-right: 50px;
}

.about-content .section-title {
    margin-bottom: 35px;
}

.about-content p {
    margin-bottom: 40px;
}

.about-content .singiture h4 {
    font-size: 20px;
    margin-bottom: 25px;
}

.about-content-two .section-title {
    margin-bottom: 22px;
}

.about-content-two .section-title .title {
    font-size: 34px;
    line-height: 44px;
    color: #322d49;
    font-weight: 600;
}

.about-content-two .description {
    margin-bottom: 40px;
}

.about-content-two .about-user {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.about-content-two .about-user .avatar {
    width: 60px;
    height: 60px;
    overflow: hidden;
    margin-right: 20px;
}

.about-content-two .about-user .avatar img {
    border-radius: 50%;
}

.about-content-two .about-user .user-info {
    -webkit-box-flex: 2;
    -ms-flex: 2;
    flex: 2;
}

.about-content-two .about-user .user-info .name {
    font-size: 20px;
    font-weight: 500;
    color: #322d49;
    margin: 0;
}

.about-content-two .about-user .user-info .designation {
    font-size: 16px;
    color: #5e5b74;
}

.about-tax {
    padding: 60px 0 240px;
    position: relative;
}

.about-video-wrapper .video-thumbnail {
    position: relative;
}

.about-video-wrapper .video-thumbnail>img {
    border-radius: 10px;
}

.about-video-wrapper .video-thumbnail .dot-shape {
    position: absolute;
    display: block;
    top: -50px;
    left: -60px;
    z-index: -1;
}

.about-tax-content {
    padding-left: 50px;
}

.about-tax-content .section-title {
    margin-bottom: 10px;
}

.about-tax-content p {
    margin-bottom: 30px;
}

.about-tax-content .list-items {
    margin-bottom: 37px;
}

.about-tax-content .list-items li {
    padding-left: 25px;
}

.about-tax-content .list-items li:before {
    content: ';';
    color: #f97bc5;
    top: -1px;
}

.about-marketing {
    background: #f8f9fd;
}

@media (max-width: 991px) {
    .about-marketing {
        padding-top: 80px;
    }
}

.marketing-about-content {
    padding: 107px 0 120px 70px;
}

@media (max-width: 991px) {
    .marketing-about-content {
        padding: 50px 0 80px;
    }
}

.marketing-about-content .section-title {
    margin-bottom: 0;
}

.marketing-about-content .section-title .title {
    margin-bottom: 35px;
}

.feature-lists {
    margin-bottom: 25px;
    padding: 0;
    list-style: none;
}

.feature-lists li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 15px;
}

.feature-lists li i {
    font-size: 14px;
    height: 24px;
    width: 24px;
    text-align: center;
    line-height: 24px;
    background: #f8e9e2;
    color: #fa7070;
    border-radius: 50%;
    margin-right: 10px;
}

.feature-lists li .content {
    -webkit-box-flex: 2;
    -ms-flex: 2;
    flex: 2;
}

.feature-lists li .content h4 {
    font-size: 16px;
    color: #1a133b;
}

.mar-about-image {
    margin-left: -180px;
    margin-top: -20px;
}

@media (max-width: 991px) {
    .mar-about-image {
        margin: 0;
    }
}

@media (max-width: 1200px) {
    .about-content-two .section-title .title {
        font-size: 30px;
        line-height: 40px;
    }
}

@media (max-width: 991px) {
    .about {
        padding-top: 76px;
    }
    .about-content {
        padding-right: 0;
    }
    .about-thumb {
        margin-top: 30px;
    }
    .about-two {
        padding-bottom: 80px;
    }
    .about-content-two {
        margin-top: 40px;
    }
    .about-tax {
        padding: 60px 0 80px;
    }
    .about-tax-content {
        padding-left: 0;
        margin-top: 40px;
    }
}


/*--------------------------------------------------------------
## Service
--------------------------------------------------------------*/

.service {
    padding-top: 120px;
}

.popup-videos {
    position: relative;
    max-width: 470px;
}

.popup-videos .play-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-box-shadow: none;
    box-shadow: none;
}

.service-content {
    padding: 30px 50px 0 50px;
}

.service-content .section-title {
    margin-bottom: 40px;
}

.service-content p {
    font-size: 18px;
    line-height: 34px;
}

.featured-two-service {
    padding: 145px 0 28px;
}

@media (max-width: 991px) {
    .service {
        padding-top: 80px;
    }
    .service-content {
        padding: 0;
        margin-top: 50px;
    }
}


/*--------------------------------------------------------------
## Contact Us
--------------------------------------------------------------*/

.contactus {
    padding: 120px 0;
}

.gmap3-area {
    height: 500px;
}

.contact-infos .contact-info {
    margin-bottom: 30px;
    padding-bottom: 30px;
}

.contact-infos .contact-info:first-child {
    border-bottom: 1px solid #efe7e7;
}

.contact-infos .contact-info .title {
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
}

.contact-infos .contact-info .description {
    font-size: 16px;
    line-height: 28px;
}

.contact-infos .contact-info .info i {
    margin-right: 10px;
}

.contact-infos .contact-info .info.phone {
    color: #fa7070;
}

.saaspik-checkboxes {
    margin-bottom: 30px;
}

.saaspik-checbox-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.saaspik-checbox-wrapper .saaspik-checkbox {
    position: absolute;
    opacity: 0;
    height: 20px;
    width: 16px;
    margin: 0;
}

.saaspik-checbox-wrapper span {
    font-size: 14px;
}

.saaspik-checkbox+label {
    position: relative;
    cursor: pointer;
    padding: 0;
    margin: 0;
    height: 20px;
}

.saaspik-checkbox+label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 18px;
    height: 18px;
    border: 2px solid #aba8a4;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.saaspik-checkbox:hover+label:before {
    border-color: #fa7070;
}

.saaspik-checkbox:checked+label:before {
    border-color: #fa7070;
}

.saaspik-checkbox:checked+label:after {
    content: '';
    position: absolute;
    left: 4px;
    top: 10px;
    background: #fa7070;
    width: 2px;
    height: 2px;
    -webkit-box-shadow: 2px 0 0 #fa7070, 4px 0 0 #fa7070, 4px -2px 0 #fa7070, 4px -4px 0 #fa7070, 4px -6px 0 #fa7070, 4px -8px 0 #fa7070;
    box-shadow: 2px 0 0 #fa7070, 4px 0 0 #fa7070, 4px -2px 0 #fa7070, 4px -4px 0 #fa7070, 4px -6px 0 #fa7070, 4px -8px 0 #fa7070;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.saaspik-radio {
    line-height: 1;
    margin-bottom: 10px;
}

.saaspik-radio input[type='radio'] {
    position: absolute;
    opacity: 0;
    margin: 0;
}

.saaspik-radio input[type='radio']+.radio-label {
    margin: 0;
}

.saaspik-radio input[type='radio']+.radio-label:before {
    content: '';
    background: #f4f4f4;
    border-radius: 100%;
    border: 1px solid #b4b4b4;
    display: inline-block;
    width: 18px;
    height: 18px;
    position: relative;
    top: -0.2em;
    margin-right: 10px;
    vertical-align: top;
    cursor: pointer;
    text-align: center;
    -webkit-transition: all 250ms ease;
    -o-transition: all 250ms ease;
    transition: all 250ms ease;
}

.saaspik-radio input[type='radio']:checked+.radio-label:before {
    background-color: #fa7070;
    -webkit-box-shadow: inset 0 0 0 4px #f4f4f4;
    box-shadow: inset 0 0 0 4px #f4f4f4;
}

.saaspik-radio input[type='radio']:focus+.radio-label:before {
    outline: none;
    border-color: #fa7070;
}

.saaspik-radio input[type='radio']+.radio-label:empty:before {
    margin-right: 0;
}


/*--------------------------------------------------------------
## Portfolio
--------------------------------------------------------------*/

.portfolios {
    padding: 120px 0 40px;
}

.portfolios-two,
.portfolios-three {
    padding: 120px 0;
}

.pixsass-isotope-filter {
    margin-bottom: 50px;
    padding: 0;
    list-style: none;
    text-align: center;
}

.pixsass-isotope-filter li {
    display: inline-block;
    margin-bottom: 10px;
}

.pixsass-isotope-filter li a {
    color: #797687;
    font-size: 16px;
    font-weight: 500;
    padding: 5px 23px;
    border: 1px solid transparent;
    border-radius: 30px;
    display: inline-block;
}

.pixsass-isotope-filter li.current a {
    border-color: #fa7070;
    color: #fa7070;
}

.grid-sizer {
    min-height: 1px;
}

.pixsass-portfolio-items.column-2 {
    width: calc(100% + 4%);
}

.pixsass-portfolio-items.column-2 .pixsass-portfolio-item,
.pixsass-portfolio-items.column-2 .grid-sizer {
    width: calc(50% - 4%);
    margin-right: 4%;
    float: left;
}

.pixsass-portfolio-items.column-3 {
    width: calc(100% + 2.4%);
}

.pixsass-portfolio-items.column-3 .pixsass-portfolio-item,
.pixsass-portfolio-items.column-3 .grid-sizer {
    width: calc(33.33% - 2.4%);
    margin-right: 2.4%;
    float: left;
}

.pixsass-portfolio-items.portfolio-one .pixsass-portfolio-item {
    position: relative;
    margin-bottom: 150px;
    float: left;
}

.pixsass-portfolio-items.portfolio-one .pixsass-portfolio-item .portfolio-info {
    max-width: 420px;
    background: #fff;
    padding: 30px 40px;
    -webkit-box-shadow: 0px 20px 40px 0px rgba(79, 35, 35, 0.1);
    box-shadow: 0px 20px 40px 0px rgba(79, 35, 35, 0.1);
    position: absolute;
    width: 100%;
    bottom: -70px;
    left: 40px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.pixsass-portfolio-items.portfolio-one .pixsass-portfolio-item .portfolio-info h3 {
    font-size: 24px;
    margin: 0;
    line-height: 32px;
}

.pixsass-portfolio-items.portfolio-one .pixsass-portfolio-item .portfolio-info h3 a {
    color: #2b2350;
}

.pixsass-portfolio-items.portfolio-one .pixsass-portfolio-item .portfolio-info h3 a:hover {
    color: #fa7070;
}

.pixsass-portfolio-items.portfolio-one .pixsass-portfolio-item .portfolio-info span {
    color: #797687;
    font-size: 16px;
}

.pixsass-portfolio-items.portfolio-one .pixsass-portfolio-item:hover .portfolio-info {
    -webkit-box-shadow: none;
    box-shadow: none;
    bottom: 40px;
}

.pixsass-portfolio-items.portfolio-two .pixsass-portfolio-item {
    position: relative;
    margin-bottom: 60px;
}

.pixsass-portfolio-items.portfolio-two .pixsass-portfolio-item .pixsass-isotope-grid__img {
    position: relative;
    overflow: hidden;
}

.pixsass-portfolio-items.portfolio-two .pixsass-portfolio-item .pixsass-isotope-grid__img:before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    opacity: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    z-index: 2;
}

.pixsass-portfolio-items.portfolio-two .pixsass-portfolio-item .pixsass-isotope-grid__img img {
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.pixsass-portfolio-items.portfolio-two .pixsass-portfolio-item .pixsass-isotope-grid__img:hover:before {
    opacity: 1;
}

.pixsass-portfolio-items.portfolio-two .pixsass-portfolio-item .pixsass-isotope-grid__img:hover img {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}

.pixsass-portfolio-items.portfolio-two .pixsass-portfolio-item .portfolio-info {
    padding: 30px 40px 0;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.pixsass-portfolio-items.portfolio-two .pixsass-portfolio-item .portfolio-info h3 {
    font-size: 24px;
    margin: 0;
    line-height: 32px;
}

.pixsass-portfolio-items.portfolio-two .pixsass-portfolio-item .portfolio-info h3 a {
    color: #2b2350;
}

.pixsass-portfolio-items.portfolio-two .pixsass-portfolio-item .portfolio-info h3 a:hover {
    color: #fa7070;
}

.pixsass-portfolio-items.portfolio-two .pixsass-portfolio-item .portfolio-info span {
    color: #797687;
    font-size: 16px;
}

.pixsass-portfolio-items.portfolio-two .pixsass-portfolio-item:hover .portfolio-info {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.pixsass-portfolio-items.portfolio-three .pixsass-portfolio-item {
    position: relative;
    margin-bottom: 2%;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
}

.pixsass-portfolio-items.portfolio-three .pixsass-portfolio-item img {
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.pixsass-portfolio-items.portfolio-three .pixsass-portfolio-item:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: -o-linear-gradient(210deg, #fa9770 0%, #fa7070 100%);
    background-image: linear-gradient(-120deg, #fa9770 0%, #fa7070 100%);
    opacity: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.pixsass-portfolio-items.portfolio-three .pixsass-portfolio-item .portfolio-info {
    padding: 35px 40px;
    -webkit-box-shadow: 0px 20px 40px 0px rgba(79, 35, 35, 0.1);
    box-shadow: 0px 20px 40px 0px rgba(79, 35, 35, 0.1);
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.pixsass-portfolio-items.portfolio-three .pixsass-portfolio-item .portfolio-info h3 {
    font-size: 24px;
    margin-bottom: 4px;
    line-height: 32px;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-transition-delay: 0.1s;
    -o-transition-delay: 0.1s;
    transition-delay: 0.1s;
}

.pixsass-portfolio-items.portfolio-three .pixsass-portfolio-item .portfolio-info h3 a {
    color: #fff;
}

.pixsass-portfolio-items.portfolio-three .pixsass-portfolio-item .portfolio-info span {
    color: #fff;
    font-size: 14px;
    display: inline-block;
    text-transform: uppercase;
    margin-bottom: 15px;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.pixsass-portfolio-items.portfolio-three .pixsass-portfolio-item .portfolio-info p {
    color: #fff;
    margin: 0;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-transition-delay: 0.3s;
    -o-transition-delay: 0.3s;
    transition-delay: 0.3s;
}

.pixsass-portfolio-items.portfolio-three .pixsass-portfolio-item:hover:before {
    opacity: 0.9;
    z-index: 3;
}

.pixsass-portfolio-items.portfolio-three .pixsass-portfolio-item:hover img {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}

.pixsass-portfolio-items.portfolio-three .pixsass-portfolio-item:hover .portfolio-info {
    -webkit-box-shadow: none;
    box-shadow: none;
    z-index: 4;
}

.pixsass-portfolio-items.portfolio-three .pixsass-portfolio-item:hover .portfolio-info h3,
.pixsass-portfolio-items.portfolio-three .pixsass-portfolio-item:hover .portfolio-info span,
.pixsass-portfolio-items.portfolio-three .pixsass-portfolio-item:hover .portfolio-info p {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
}

.portfolio-single {
    padding: 116px 0 100px;
}

.port-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 60px;
}

.port-header .portfolio-title {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.port-header .portfolio-title .title {
    max-width: 510px;
    font-size: 40px;
    line-height: 50px;
    font-weight: 500;
}

.port-header .share-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.port-header .share-link .footer-social-link {
    margin: 0;
}

.port-header .share-link li a {
    background: transparent;
}

.portfolio-content img {
    margin-bottom: 70px;
}

.portfolio-content .content-inner {
    padding-right: 65px;
    border-right: 1px solid #efe7e7;
}

.portfolio-content .content-inner .sub-title {
    font-size: 22px;
    font-weight: 500;
    line-height: 34px;
    margin-bottom: 30px;
}

.portfolio-content .content-inner p {
    font-size: 18px;
    line-height: 30px;
}

.portfolio-content .portfolio-info {
    padding-left: 55px;
}

.portfolio-content .portfolio-info .info {
    margin: 0;
    padding: 0;
    list-style: none;
}

.portfolio-content .portfolio-info .info li {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 45px;
    color: #2b2350;
}

.portfolio-content .portfolio-info .info li span {
    display: block;
    font-size: 16px;
    font-weight: 400;
    color: #797687;
    margin-top: 5px;
}

.portfolio-nav {
    margin: 0;
    padding: 0;
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-top: 100px;
}

.portfolio-nav li {
    font-size: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: end;
}

.portfolio-nav li i {
    font-size: 30px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.portfolio-nav li a {
    color: #797687;
}

.portfolio-nav li.prev i {
    margin-right: 10px;
}

.portfolio-nav li.next i {
    margin-left: 10px;
}

.portfolio-nav li:hover i,
.portfolio-nav li:hover a {
    color: #fa7070;
}

.related-portfolio {
    padding-top: 138px;
}

.related-portfolio .portfolio-item .feature-image {
    position: relative;
}

.related-portfolio .portfolio-item .feature-image img {
    width: 100%;
}

.related-portfolio .portfolio-item .feature-image a {
    display: block;
    position: relative;
}

.related-portfolio .portfolio-item .feature-image a:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
    opacity: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.related-portfolio .portfolio-item .feature-image:hover a:before {
    opacity: 1;
}

.related-portfolio .portfolio-item .port-info {
    padding: 25px 40px 0;
}

.related-portfolio .portfolio-item .port-info h3 {
    font-size: 24px;
    margin-bottom: 3px;
    line-height: 32px;
}

.related-portfolio .portfolio-item .port-info h3 a {
    color: #2b2350;
}

.related-portfolio .portfolio-item .port-info h3 a:hover {
    color: #fa7070;
}

#related-portfolio .swiper-pagination {
    position: relative;
    margin-top: 55px;
}

#related-portfolio .swiper-pagination .swiper-pagination-bullet {
    height: 12px;
    width: 12px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    margin-right: 5px;
    position: relative;
    opacity: 1;
    background: transparent;
    outline: 0;
}

#related-portfolio .swiper-pagination .swiper-pagination-bullet:before {
    content: '';
    position: absolute;
    height: 12px;
    width: 12px;
    background: #e5d6d6;
    z-index: 2;
    top: 0;
    left: 0;
    border-radius: 50%;
    left: -2px;
    top: -2px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-transform: scale(0.7);
    -ms-transform: scale(0.7);
    transform: scale(0.7);
}

#related-portfolio .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
    opacity: 1;
}

#related-portfolio .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active:before {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    background: #fa7070;
    -webkit-box-shadow: 0px 10px 14px 0px rgba(79, 35, 35, 0.3);
    box-shadow: 0px 10px 14px 0px rgba(79, 35, 35, 0.3);
}

@media (max-width: 991px) {
    .portfolios {
        padding: 80px 0 50px;
    }
    .pixsass-isotope-filter {
        margin-bottom: 40px;
    }
    .portfolios-two,
    .portfolios-three {
        padding: 80px 0;
    }
    .pixsass-portfolio-items.portfolio-one .pixsass-portfolio-item {
        margin-bottom: 120px;
    }
    .pixsass-portfolio-items.portfolio-one .pixsass-portfolio-item .portfolio-info {
        max-width: 290px;
        left: 20px;
    }
    .pixsass-portfolio-items.column-3 {
        width: calc(100% + 3%);
    }
    .pixsass-portfolio-items.column-3 .pixsass-portfolio-item,
    .pixsass-portfolio-items.column-3 .grid-sizer {
        width: calc(50% - 3%);
        margin-right: 3%;
        margin-bottom: 3%;
    }
    .portfolio-single {
        padding: 76px 0 80px;
    }
    .port-header {
        display: block;
    }
    .port-header .portfolio-title .title {
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 20px;
    }
}

@media (max-width: 768px) {
    .pixsass-portfolio-items.portfolio-one .pixsass-portfolio-item .portfolio-info {
        max-width: 60%;
        left: 20px;
    }
    .pixsass-portfolio-items.column-2,
    .pixsass-portfolio-items.column-3 {
        width: 100%;
    }
    .pixsass-portfolio-items.column-2 .pixsass-portfolio-item,
    .pixsass-portfolio-items.column-2 .grid-sizer,
    .pixsass-portfolio-items.column-3 .pixsass-portfolio-item,
    .pixsass-portfolio-items.column-3 .grid-sizer {
        width: 100%;
        margin-right: 0;
        margin-bottom: 30px;
    }
    .pixsass-portfolio-items.column-2 .pixsass-portfolio-item img,
    .pixsass-portfolio-items.column-2 .grid-sizer img,
    .pixsass-portfolio-items.column-3 .pixsass-portfolio-item img,
    .pixsass-portfolio-items.column-3 .grid-sizer img {
        width: 100%;
    }
    .pixsass-portfolio-items.column-2 .pixsass-portfolio-item {
        margin-bottom: 120px;
    }
    .portfolio-content img {
        margin-bottom: 30px;
    }
    .portfolio-content .content-inner {
        padding-right: 0;
        border-right: 0;
    }
    .related-portfolio {
        padding-top: 60px;
    }
    .portfolio-content .portfolio-info {
        padding-left: 0;
        padding-top: 30px;
    }
    .portfolio-nav {
        margin-top: 30px;
    }
}

@media (max-width: 576px) {
    .pixsass-portfolio-items.column-2 .pixsass-portfolio-item {
        margin-bottom: 80px;
    }
    .pixsass-portfolio-items.portfolio-one .pixsass-portfolio-item .portfolio-info {
        max-width: 80%;
        padding: 20px;
        bottom: -50px;
    }
    .pixsass-portfolio-items.portfolio-one .pixsass-portfolio-item .portfolio-info h3 {
        font-size: 20px;
        line-height: 30px;
    }
}


/*--------------------------------------------------------------
## Download
--------------------------------------------------------------*/

.download {
    padding: 120px 0;
    overflow: hidden;
}

.download-wrapper {
    padding-top: 115px;
}

.download-wrapper .title {
    font-size: 34px;
    font-weight: 700;
    margin-bottom: 25px;
}

.download-wrapper p {
    margin-bottom: 40px;
}

.download-feature-image {
    position: relative;
}

.download-feature-image .image-one {
    margin-left: 185px;
    z-index: 22;
    position: relative;
}

.download-feature-image .image-two {
    position: absolute;
    right: 10px;
    top: -20px;
}

.download-feature-image svg {
    position: absolute;
    z-index: -1;
    top: 43px;
    right: 0;
}

.app-btn-wrapper .app-btn:not(:last-child) {
    margin-right: 20px;
}


/**
Download Two
 */

.download-two .container-wrap {
    overflow: hidden;
}

.download-wrapper-two {
    padding: 130px 0;
}

.download-wrapper-two .title {
    font-size: 60px;
    line-height: 1.2;
    font-weight: 700;
}

@media (max-width: 768px) {
    .download-wrapper-two .title {
        font-size: 40px;
    }
}

.download-wrapper-two p {
    margin-bottom: 30px;
    color: #76747f;
}

.download-feature-image-two {
    position: relative;
    height: 100%;
}

@media (max-width: 991px) {
    .download-feature-image-two {
        display: none;
    }
}

.download-feature-image-two img {
    position: absolute;
    bottom: -160px;
}

.download-feature-image-two img.image-one {
    left: 0;
    z-index: 2;
}

.download-feature-image-two img.image-two {
    left: 215px;
    bottom: -240px;
}

.section-animate-element>div {
    position: absolute;
}

.section-animate-element .leaf-top {
    top: -20px;
    left: 40px;
}

.section-animate-element .leaf-bottom {
    right: 15px;
    bottom: -20px;
}

.section-animate-element .ball {
    left: 5%;
    top: 50%;
}

@media (max-width: 991px) {
    .section-animate-element .ball {
        display: none;
    }
}

.section-animate-element .triangle {
    right: 8%;
    top: 90px;
}


/*
* Travel Download
*/

.travel-download {
    padding-bottom: 85px;
}

.travel-download-content .section-title {
    margin-bottom: 40px;
}

.travel-parallax-image-two {
    position: relative;
}

@media (min-width: 768px) {
    .travel-parallax-image-two .image-wrapper {
        margin-right: -100px;
    }
}

.travel-parallax-image-two .image-wrapper img {
    position: relative;
    z-index: 2;
}

.travel-parallax-image-two .circle-shape {
    position: absolute;
    top: 60px;
    left: 20%;
    border-radius: 50%;
    width: 420px;
    height: 420px;
    background: #f66e93;
    display: block;
}

@media (max-width: 991px) {
    .travel-parallax-image-two .circle-shape {
        height: 320px;
        width: 320px;
    }
}

@media (max-width: 576px) {
    .travel-parallax-image-two .circle-shape {
        height: 250px;
        width: 250px;
    }
}

@media (max-width: 420px) {
    .travel-parallax-image-two .circle-shape {
        height: 200px;
        width: 200px;
    }
}

@media (max-width: 1200px) {
    .download-feature-image .image-one {
        margin-left: 0;
    }
}

@media (max-width: 991px) {
    .download-feature-image svg {
        right: auto;
        left: 0;
    }
    .download-feature-image .image-one {
        margin-left: 0;
    }
    .download-feature-image .image-two {
        right: 25%;
    }
    .download {
        padding: 100px 0;
    }
    .signup-section {
        padding: 70px 0;
    }
}

@media (max-width: 768px) {
    .download-feature-image .image-two {
        left: 150px;
        right: auto;
    }
}

@media (max-width: 576px) {
    .download-feature-image svg {
        top: -50px;
    }
    .download-feature-image .image-one {
        margin-left: 0;
        max-width: 200px;
    }
    .download-feature-image .image-two {
        right: auto;
        top: -18px;
        max-width: 270px;
        left: 25%;
    }
    .download-wrapper {
        padding-top: 50px;
    }
}

@media (max-width: 480px) {
    .download-feature-image .image-two {
        right: auto;
        left: 15%;
    }
    .app-btn-wrapper .app-btn:not(:last-child) {
        margin-right: 10px;
        margin-bottom: 10px;
    }
}


/*--------------------------------------------------------------
## Interface
--------------------------------------------------------------*/

.interface {
    padding: 105px 0 60px;
    overflow: hidden;
}

.interface-image-wrapper {
    height: 600px;
}

.interface-image-wrapper .image-one {
    margin-left: -60px;
    margin-top: 178px;
}

.interface-image-wrapper .image-two {
    position: absolute;
    top: 0;
    left: 160px;
}

.interface-image-wrapper.style-two .image-one {
    position: absolute;
    bottom: 0;
    left: -20px;
    margin: 0;
    z-index: 22;
}

.interface-image-wrapper.style-two .image-two {
    position: static;
    margin-left: 135px;
}

.interface-content .interface-title h2 {
    font-size: 34px;
    line-height: 50px;
    margin-bottom: 20px;
}

.interface-content .interface-title p {
    margin-bottom: 30px;
}

.interface-content .list-items {
    margin-bottom: 30px;
}

.interface-content .list-items li {
    line-height: 40px;
    font-size: 18px;
}

.interface-content .list-items li:before {
    content: 'R';
    color: #13bf24;
}

.svgbg-one {
    position: absolute;
    top: 55px;
    left: 50px;
    z-index: -1;
    -webkit-animation: wave 3s 0.1s infinite linear;
    animation: wave 3s 0.1s infinite linear;
}

.svgbg-two {
    position: absolute;
    top: -70px;
    left: -37%;
    z-index: -1;
    -webkit-animation: wave 3s 0.1s infinite linear;
    animation: wave 3s 0.1s infinite linear;
}

.interface-toparea {
    padding-bottom: 200px;
}

.border-wrap {
    position: absolute;
    left: 50%;
    top: 50%;
    max-width: 775px;
    -webkit-transform: translate(-17%, -54%);
    -ms-transform: translate(-17%, -54%);
    transform: translate(-17%, -54%);
    width: 100%;
    z-index: -1;
}

.border-wrap .ball {
    position: absolute;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background: #fa7070;
    top: 49%;
    left: 45%;
    z-index: 22;
}

#animate-border {
    max-width: 775px;
    z-index: -1;
    opacity: 0.5;
}

.path {
    stroke-dasharray: 6;
    -webkit-animation: dash 30s linear infinite;
    animation: dash 30s linear infinite;
}

@-webkit-keyframes dash {
    /*   to {
	  stroke-dashoffset: 1000;
	} */
    0% {
        stroke-dashoffset: 1000;
    }
}

@keyframes dash {
    /*   to {
	  stroke-dashoffset: 1000;
	} */
    0% {
        stroke-dashoffset: 1000;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .interface-image-wrapper .image-two {
        max-width: 300px;
        left: 150px;
    }
    #animate-border {
        max-width: 650px;
    }
    .md-brn br {
        display: none;
    }
    .interface {
        padding: 88px 0 37px;
    }
}

@media (max-width: 992px) {
    .interface {
        padding: 62px 0 80px;
    }
    .interface-toparea {
        padding-bottom: 100px;
    }
    .border-wrap {
        display: none;
    }
    .interface-image-wrapper .image-one {
        margin-left: -30px;
    }
    .interface-image-wrapper .image-two {
        left: 190px;
    }
    .svgbg-one {
        left: 50px;
    }
}

@media (max-width: 576px) {
    .interface-image-wrapper .image-two {
        max-width: 300px;
        left: 180px;
    }
    .interface-content .interface-title p br {
        display: none;
    }
    .interface-image-wrapper.style-two .image-two {
        margin-left: 100px;
    }
}

@media (max-width: 420px) {
    .interface-image-wrapper .image-one {
        z-index: 22;
        position: relative;
    }
    .interface-image-wrapper .image-two {
        max-width: 300px;
        left: 70px;
    }
    .interface-image-wrapper.style-two .image-one {
        max-width: 300px;
        left: 0;
    }
    .interface-image-wrapper.style-two .image-two {
        margin-left: 30px;
    }
}


/*--------------------------------------------------------------
## Call Back
--------------------------------------------------------------*/

.callback {
    background: #fcfafb;
    position: relative;
    padding-bottom: 65px;
}

.callback:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 0;
    border-top: 115px solid transparent;
    border-left: 1900px solid #fff;
    border-bottom: 150px solid #fff;
}

.callback .particale-element {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.callback .particale-element li {
    position: absolute;
}

.callback .particale-element li:nth-child(1) {
    top: -20%;
    left: 40px;
    -webkit-animation: jumping 5s infinite linear;
    animation: jumping 5s infinite linear;
}

.callback .particale-element li:nth-child(2) {
    top: 12%;
    left: 30%;
    background: #f7cb61;
    height: 15px;
    width: 15px;
    border-radius: 30px;
    -webkit-animation: animationFramesLeft 20s infinite linear;
    animation: animationFramesLeft 20s infinite linear;
}

.callback .particale-element li:nth-child(3) {
    left: 8%;
    top: 35%;
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-right: 20px solid #b29ff7;
    -webkit-animation: animationFramesTwo 20s infinite linear;
    animation: animationFramesTwo 20s infinite linear;
}

.callback .particale-element li:nth-child(4) {
    bottom: 38%;
    left: 13%;
    background: #43d254;
    height: 15px;
    width: 15px;
    border-radius: 30px;
    -webkit-animation: animationFramesRight 20s infinite linear;
    animation: animationFramesRight 20s infinite linear;
}

.callback .particale-element li:nth-child(5) {
    bottom: 0%;
    left: 11%;
    background: #fcf2f9;
    height: 300px;
    width: 300px;
    border-radius: 50%;
    -webkit-animation: wave 3s infinite linear;
    animation: wave 3s infinite linear;
}

.callback .particale-element li:nth-child(6) {
    bottom: 9%;
    left: 41%;
    z-index: 1;
    -webkit-animation: wave 6s infinite linear;
    animation: wave 6s infinite linear;
}

.callback .particale-element li:nth-child(7) {
    right: 12%;
    top: -19%;
    background: #f9f3f7;
    height: 600px;
    width: 600px;
    border-radius: 50%;
    -webkit-animation: wave 8s infinite linear;
    animation: wave 8s infinite linear;
}

.callback .particale-element li:nth-child(8) {
    right: 9%;
    top: -22%;
    background: rgba(83, 81, 186, 0.039);
    height: 360px;
    width: 360px;
    border-radius: 50%;
    -webkit-animation: wave 6s infinite linear;
    animation: wave 6s infinite linear;
}

.call-back-content {
    padding: 190px 0 190px;
}

.call-back-content p {
    margin-bottom: 40px;
}

.call-back-forms {
    border-radius: 10px;
    background: #fff;
    -webkit-box-shadow: 8.682px 49.24px 80px 0px rgba(48, 11, 33, 0.1);
    box-shadow: 8.682px 49.24px 80px 0px rgba(48, 11, 33, 0.1);
    padding: 60px 50px;
    max-width: 570px;
    border-top: 5px solid #f97bc5;
    -webkit-transform: translateY(-60px);
    -ms-transform: translateY(-60px);
    transform: translateY(-60px);
    z-index: 2;
    position: relative;
}

.call-back-forms .title {
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 35px;
}

.call-back-forms select {
    padding: 0 20px;
    outline: 0;
}

.call-back-forms label {
    font-weight: 500;
}

.call-back-forms input,
.call-back-forms select {
    background: #f9f5f7;
    border: 2px solid #f9f5f7;
    height: 56px;
    width: 100%;
    border-radius: 30px;
    margin-bottom: 20px;
}

.call-back-forms input:focus,
.call-back-forms select:focus {
    background: transparent;
    border-color: #efbbd9;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.call-back-forms button {
    margin-top: 20px;
}

@media (max-width: 768px) {
    .call-back-content {
        padding: 100px 0;
    }
    .callback .particale-element li:nth-child(6) {
        bottom: 70px;
        left: 25%;
    }
}


/*--------------------------------------------------------------
## Bookings Tabs
--------------------------------------------------------------*/

.bookings-tabs {
    border-radius: 4px;
    background-color: white;
    -webkit-box-shadow: 0px 40px 70px 0px rgba(54, 33, 5, 0.08);
    box-shadow: 0px 40px 70px 0px rgba(54, 33, 5, 0.08);
}

.bookings-tabs #pix-tabs-nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
}

.bookings-tabs #pix-tabs-nav li {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    background: #fff6ec;
    margin-right: 0;
}

.bookings-tabs #pix-tabs-nav li a {
    display: block;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
    color: #a18868;
    margin: 0;
    border-top: 4px solid transparent;
}

.bookings-tabs #pix-tabs-nav li a:hover {
    color: #fbab41;
    border-color: #fbab41;
}

.bookings-tabs #pix-tabs-nav li.active a {
    color: #fbab41;
    border-color: #fbab41;
}

.booking-wrapper {
    background-color: white;
    padding: 25px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
    z-index: 999;
}

.booking-wrapper .booking-info {
    width: calc(20.4% - 2%);
    margin-right: 2%;
}

.booking-wrapper .booking-info input {
    width: 100%;
    padding: 11px;
    border: 1px solid #ddd;
    outline: 0;
    border-radius: 3px;
    color: #999;
    font-weight: 500;
    font-size: 14px;
}

.booking-wrapper .booking-info input::-webkit-input-placeholder {
    color: #999;
}

.booking-wrapper .booking-info input:-ms-input-placeholder {
    color: #999;
}

.booking-wrapper .booking-info input::-ms-input-placeholder {
    color: #999;
}

.booking-wrapper .booking-info input::-moz-placeholder {
    color: #999;
}

.booking-wrapper .booking-info input::placeholder {
    color: #999;
}

.booking-wrapper .booking-info:last-child {
    margin-right: 0;
}

.booking-wrapper .booking-info .saaspik-btn {
    display: block;
    width: 100%;
}

.booking-wrapper .booking-info .saaspik-select ul {
    padding: 20px;
    -webkit-box-shadow: 0px 20px 20px 0px rgba(153, 153, 153, 0.1);
    box-shadow: 0px 20px 20px 0px rgba(153, 153, 153, 0.1);
}

.booking-wrapper .booking-info .saaspik-select ul li {
    padding: 0;
}

.booking-wrapper .booking-info .saaspik-select ul li:hover {
    color: #8e42f7;
    background: transparent;
}


/* Language Dropdown */

.saaspik-dropdown {
    display: inline-block;
    position: relative;
    border: 1px solid #dddddd;
    border-radius: 4px;
    padding: 10px 20px;
    width: 100%;
    border-radius: 3px;
}

.saaspik-dropdown ul {
    opacity: 0;
    -webkit-transition: opacity 0.3s;
    -o-transition: opacity 0.3s;
    transition: opacity 0.3s;
    margin: 0;
    padding: 0;
    list-style: none;
}

.saaspik-dropdown>ul {
    position: absolute;
    background: #fff;
    left: 0;
    top: 50px;
    border-radius: 3px;
    background-clip: padding-box;
    -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    width: 100%;
}

.saaspik-dropdown>ul li {
    white-space: nowrap;
    position: relative;
    z-index: 2;
    padding: 3px 10px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.saaspik-dropdown>ul li:first-child {
    border-radius: 5px 5px 0 0;
    background-clip: padding-box;
}

.saaspik-dropdown>ul li:last-child {
    border-radius: 0 0 5px 5px;
    background-clip: padding-box;
    border-bottom: none;
}

.saaspik-dropdown>ul li:hover {
    background: #e0e6eb;
}

.saaspik-dropdown>label {
    display: inline-block;
    vertical-align: -9px;
    overflow: hidden;
    cursor: pointer;
}

.saaspik-dropdown>label span {
    white-space: nowrap;
    opacity: 1;
    -webkit-transition: opacity 0.3s;
    -o-transition: opacity 0.3s;
    transition: opacity 0.3s;
    color: #333;
}

.saaspik-dropdown>label i {
    position: absolute;
    right: 5px;
    top: 50%;
    line-height: 30px;
    width: 30px;
    text-align: center;
    color: #333;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 14px;
}

.saaspik-dropdown label {
    margin: 0;
}

.saaspik-dropdown input {
    display: none;
}

.saaspik-dropdown input:checked~ul {
    opacity: 1;
}

.saaspik-dropdown input:checked~ul li {
    cursor: pointer;
}

.saaspik-select {
    position: relative;
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 325px;
    color: #cccccc;
    vertical-align: middle;
    text-align: left;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
}

.saaspik-select .placeholder {
    position: relative;
    display: block;
    z-index: 1;
    padding: 11px 20px;
    border-radius: 2px;
    cursor: pointer;
    font-size: 14px;
    border: 1px solid #ede9e4;
    color: #626c7b;
    font-weight: 500;
}

.saaspik-select .placeholder:hover {
    color: #8e42f7;
}

.saaspik-select .placeholder:after {
    position: absolute;
    right: 1em;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-family: 'themify';
    content: '';
    z-index: 10;
}

.saaspik-select.is-open .placeholder:after {
    content: '';
}

.saaspik-select.is-open ul {
    display: block;
}

.saaspik-select.select--white .placeholder {
    background: #fff;
    color: #999;
}

.saaspik-select.select--white .placeholder:hover {
    background: #fafafa;
}

.saaspik-select ul {
    display: none;
    position: absolute;
    overflow: hidden;
    width: 100%;
    background: #fff;
    border-radius: 2px;
    top: 100%;
    left: 0;
    list-style: none;
    margin: 5px 0 0 0;
    padding: 0;
    z-index: 100;
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    z-index: 999;
}

.saaspik-select ul li {
    display: block;
    text-align: left;
    padding: 10px 15px;
    color: #999;
    cursor: pointer;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.saaspik-select ul li:hover {
    background: #8e42f7;
    color: #fff;
}


/*--------------------------------------------------------------
## Job Tabs
--------------------------------------------------------------*/

.job-bord-tabs {
    padding: 100px 0 90px;
}

@media (max-width: 991px) {
    .job-bord-tabs {
        padding: 60px 0 50px;
    }
}

.job-bord-tabs #pix-tabs-nav li:not(:last-child) {
    margin-right: 13px;
}

.job-bord-tabs #pix-tabs-nav li a {
    background: rgba(253, 77, 92, 0.102);
    color: #fd4d5c;
    border-radius: 4px;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
    padding: 6px 24px;
}

.job-bord-tabs #pix-tabs-nav li a.color--one {
    background: rgba(22, 185, 172, 0.102);
    color: #16b9ac;
}

.job-bord-tabs #pix-tabs-nav li a.color--two {
    background: rgba(240, 174, 18, 0.102);
    color: #f0ae12;
}

.job-bord-tabs #pix-tabs-nav li a.color--three {
    background: rgba(105, 46, 254, 0.102);
    color: #692efe;
}

.job-bord-tabs #pix-tabs-nav li a.color--four {
    background: rgba(26, 179, 11, 0.102);
    color: #1ab30b;
}

.job-bord-tabs #pix-tabs-nav li a.color--five {
    background: rgba(51, 70, 254, 0.102);
    color: #3346fe;
}

.job-bord-tabs #pix-tabs-nav li a i {
    margin-right: 5px;
    font-size: 16px;
    display: inline-block;
    vertical-align: -3px;
}

.job-bord-tabs #pix-tabs-nav li a:hover {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.job-bord-tabs #pix-tabs-nav li.active a,
.job-bord-tabs #pix-tabs-nav li.active:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    background: #fd4d5c;
    color: #fff;
}

.job-bord-tabs #pix-tabs-nav li.active a.color--one {
    background: #16b9ac;
}

.job-bord-tabs #pix-tabs-nav li.active a.color--two {
    background: #f0ae12;
}

.job-bord-tabs #pix-tabs-nav li.active a.color--three {
    background: #692efe;
}

.job-bord-tabs #pix-tabs-nav li.active a.color--four {
    background: #1ab30b;
}

.job-bord-tabs #pix-tabs-nav li.active a.color--five {
    background: #3346fe;
}

.job-board-tabs-content .job {
    background: #fff;
    -webkit-box-shadow: 0px 4px 6px 0px rgba(10, 1, 47, 0.08), 0px -4px 0px 0px rgba(105, 46, 254, 0.004);
    box-shadow: 0px 4px 6px 0px rgba(10, 1, 47, 0.08), 0px -4px 0px 0px rgba(105, 46, 254, 0.004);
    border-radius: 6px;
    border-top: 4px solid #fd4d5c;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    margin-bottom: 30px;
}

.job-board-tabs-content .job__logo {
    padding: 32px 0;
    text-align: center;
}

.job-board-tabs-content .job__logo .logo-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 85px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.job-board-tabs-content .job__logo .logo-container a {
    display: block;
}

.job-board-tabs-content .job__logo .logo-container img {
    background-color: transparent !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.job-board-tabs-content .job__description {
    padding: 16px 30px 43px;
    position: relative;
    text-align: left;
}

.job-board-tabs-content .job__description:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    height: 1px;
    width: 90%;
    background: #f0eef8;
}

.job-board-tabs-content .job__title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 17px;
}

.job-board-tabs-content .job__title a {
    color: #322d49;
}

.job-board-tabs-content .job__title a:hover {
    color: #fd4d5c;
}

.job-board-tabs-content .job__info {
    color: #322d49;
    font-size: 15px;
    margin: 0;
}

.job-board-tabs-content .job__location p {
    font-size: 13px;
    margin: 0;
}

.job-board-tabs-content .job__location p i {
    color: #1ab30b;
    font-size: 16px;
    margin-right: 5px;
    display: inline-block;
    vertical-align: -2px;
}

.job-board-tabs-content .job__sallery {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 17px 30px 14px;
    border-top: 2px dashed #e3e0ed;
}

.job-board-tabs-content .job__sallery .sallery {
    margin: 0;
    font-size: 20px;
    color: #fd4d5c;
}

.job-board-tabs-content .job__time p {
    margin: 0;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
}

.job-board-tabs-content .job__time p i {
    margin-right: 5px;
    font-size: 14px;
    vertical-align: -1px;
}

.job-board-tabs-content .job:hover {
    -webkit-box-shadow: 0px 40px 50px 0px rgba(10, 1, 47, 0.14), 0px -4px 0px 0px rgba(253, 77, 92, 0.004);
    box-shadow: 0px 40px 50px 0px rgba(10, 1, 47, 0.14), 0px -4px 0px 0px rgba(253, 77, 92, 0.004);
}

.job-board-tabs-content .job__color--one {
    border-top-color: #692efe;
}

.job-board-tabs-content .job__color--one .job__title a:hover {
    color: #692efe;
}

.job-board-tabs-content .job__color--one .job__sallery .sallery {
    color: #692efe;
}

.job-board-tabs-content .job__color--two {
    border-top-color: #16b9ac;
}

.job-board-tabs-content .job__color--two .job__title a:hover {
    color: #16b9ac;
}

.job-board-tabs-content .job__color--two .job__sallery .sallery {
    color: #16b9ac;
}

.job-board-tabs-content .job__color--three {
    border-top-color: #fcbd27;
}

.job-board-tabs-content .job__color--three .job__title a:hover {
    color: #fcbd27;
}

.job-board-tabs-content .job__color--three .job__sallery .sallery {
    color: #fcbd27;
}


/*--------------------------------------------------------------
## Job List
--------------------------------------------------------------*/

.joblists {
    background: #f9f8fc;
    padding: 110px 0 96px;
    background-size: cover;
    background-position: center center;
}

@media (max-width: 991px) {
    .joblists {
        padding: 70px 0 55px;
    }
}

.job__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 6px;
    background: #fff;
    -webkit-box-shadow: 0px 4px 6px 0px rgba(10, 1, 47, 0.06);
    box-shadow: 0px 4px 6px 0px rgba(10, 1, 47, 0.06);
    margin-bottom: 10px;
    padding: 19px 0;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

@media (max-width: 768px) {
    .job__list {
        display: block;
        text-align: center;
    }
}

.job__list .job-info {
    width: 60%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0 30px;
}

@media (max-width: 991px) {
    .job__list .job-info {
        width: 54%;
    }
}

@media (max-width: 768px) {
    .job__list .job-info {
        width: 357px;
        margin: 0 auto 15px;
    }
}

.job__list .job-info .company-logo {
    width: 40px;
    margin-right: 20px;
}

.job__list .job-info .info {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.job__list .job-info .info .job-title {
    font-size: 20px;
    font-weight: 600;
    margin: 0;
}

.job__list .job-info .info .job-title a {
    color: #2b2350;
}

.job__list .job-info .info .job-title a:hover {
    color: #fd4d5c;
}

.job__list .job-info .info p {
    margin: 0;
}

.job__list .job-time {
    width: 24%;
}

@media (max-width: 991px) {
    .job__list .job-time {
        width: 16%;
    }
}

@media (max-width: 768px) {
    .job__list .job-time {
        width: 100%;
        margin-bottom: 15px;
    }
}

.job__list .job-location {
    width: 16%;
    padding-right: 40px;
    text-align: right;
}

@media (max-width: 991px) {
    .job__list .job-location {
        width: 30%;
    }
}

@media (max-width: 768px) {
    .job__list .job-location {
        width: 100%;
        text-align: center;
        padding-right: 0;
    }
}

.job__list:hover {
    -webkit-box-shadow: 0px 40px 30px 0px rgba(10, 1, 47, 0.1);
    box-shadow: 0px 40px 30px 0px rgba(10, 1, 47, 0.1);
    -webkit-transform: translateX(10px);
    -ms-transform: translateX(10px);
    transform: translateX(10px);
}

.job-pagination-wrapper {
    margin-top: 22px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

@media (max-width: 420px) {
    .job-pagination-wrapper {
        display: block;
    }
}

.job-pagination-wrapper .showing-result .show-result {
    font-size: 14px;
    color: #75737e;
}

.job-pagination-wrapper .showing-result .show-result span {
    color: #fd4d5c;
}

.job-pagination-wrapper .job-pagination {
    margin: 0;
    padding: 0;
    list-style: none;
}

.job-pagination-wrapper .job-pagination li {
    display: inline-block;
}

.job-pagination-wrapper .job-pagination li:not(:last-child) {
    margin-right: 15px;
}

.job-pagination-wrapper .job-pagination li a {
    font-size: 14px;
    font-weight: 500;
    color: #96959e;
    display: inline-block;
}

.job-pagination-wrapper .job-pagination li a:hover {
    color: #fd4d5c;
}

.job-pagination-wrapper .job-pagination li.active a {
    color: #fd4d5c;
}


/*--------------------------------------------------------------
## Support
--------------------------------------------------------------*/

.support-section {
    padding: 120px 0 100px;
}

.ml--70 {
    margin-left: -70px;
}

@media (max-width: 767px) {
    .ml--70 {
        margin-left: 0;
        margin-bottom: 40px;
    }
}

.mt-120 {
    margin-top: 118px;
}

.pr-90 {
    padding-right: 90px;
}

.mt-17 {
    margin-top: 17px;
}

.support-content .section-title {
    margin: 0;
}

.support-content .section-title .title {
    margin-bottom: 25px;
}

.support-content .section-title p {
    color: #6d7682;
    margin-bottom: 24px;
}

.support-content .section-title p span {
    color: #14aeee;
}

.support-content .section-title .list-items {
    margin-bottom: 48px;
}

.support-content .section-title .subtitle {
    font-size: 18px;
    color: #2c234d;
}


/*--------------------------------------------------------------
## Domain
--------------------------------------------------------------*/

.domain {
    padding: 110px 0 120px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.domain-heading {
    margin-bottom: 60px;
}

.domain-heading .sub-title {
    -webkit-box-shadow: 2px 4px 0 rgba(14, 14, 55, 0.06);
    box-shadow: 2px 4px 0 rgba(14, 14, 55, 0.06);
    border-radius: 30px;
    background: #fff;
    display: inline-block;
    font-size: 16px;
    padding: 10px 50px;
    font-weight: 300;
    margin-bottom: 27px;
}

.domain-heading .sub-title span {
    color: #14aeee;
    font-weight: 600;
}

.domain-heading .title {
    font-size: 36px;
    line-height: 50px;
    color: #2c234d;
    font-weight: 500;
    margin-bottom: 20px;
}

.domain-heading p {
    color: #6d7682;
}

.domain-search-form-wrapper .domain-search-form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-shadow: 0 2px 4px 0 rgba(14, 14, 55, 0.06);
    box-shadow: 0 2px 4px 0 rgba(14, 14, 55, 0.06);
    background: #fff;
    border-radius: 40px;
    max-width: 670px;
    margin: 0 auto 40px;
}

@media (max-width: 480px) {
    .domain-search-form-wrapper .domain-search-form {
        display: block;
    }
}

.domain-search-form-wrapper .domain-search-form .form-input {
    -webkit-box-flex: 2;
    -ms-flex: 2;
    flex: 2;
}

.domain-search-form-wrapper .domain-search-form .form-input input {
    background: transparent;
    border: 0;
    margin: 0;
    height: 65px;
}

.domain-search-form-wrapper .domain-search-form select {
    background: transparent;
    border: 0;
    margin: 0;
    max-width: 100px;
}

@media (max-width: 480px) {
    .domain-search-form-wrapper .domain-search-form select {
        max-width: 100%;
    }
}

.domain-search-form-wrapper .domain-search-form input:focus,
.domain-search-form-wrapper .domain-search-form select:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.domain-search-form-wrapper .domain-search-form .form-button {
    background: #14aeee;
    border: 0;
    max-width: 150px;
    width: 100%;
    border-radius: 50px;
    padding: 14px;
    color: #fff;
    height: 65px;
}

@media (max-width: 480px) {
    .domain-search-form-wrapper .domain-search-form .form-button {
        max-width: 100%;
    }
}

.domain-price {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center;
}

.domain-price li {
    font-weight: 600;
    font-size: 16px;
    color: #2c234d;
    display: inline-block;
}

.domain-price li:not(:last-child) {
    margin-right: 30px;
}

.domain-price li span {
    font-weight: 300;
}


/*--------------------------------------------------------------
  ## Tabs
  --------------------------------------------------------------*/

.app-tabs .container-wrap {
    padding: 280px 0 175px;
}

@media (max-width: 991px) {
    .app-tabs .container-wrap {
        padding: 130px 0 175px;
    }
}

.tabs-three #pix-tabs-nav {
    text-align: left;
    width: 300px;
}

@media (max-width: 991px) {
    .tabs-three #pix-tabs-nav {
        margin-bottom: 60px;
    }
}

.tabs-three #pix-tabs-nav li {
    display: block;
    margin-right: 0;
}

.tabs-three #pix-tabs-nav li a {
    -webkit-box-shadow: 0px 4px 6px 0px rgba(1, 51, 32, 0.06);
    box-shadow: 0px 4px 6px 0px rgba(1, 51, 32, 0.06);
    background: #fff;
    color: #76747f;
    display: block;
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 16px;
}

.tabs-three #pix-tabs-nav li a i {
    margin-right: 5px;
    vertical-align: -1px;
}

.tabs-three #pix-tabs-nav li.active a {
    color: #12bf7c;
    -webkit-box-shadow: 0px 20px 40px 0px rgba(1, 51, 32, 0.1);
    box-shadow: 0px 20px 40px 0px rgba(1, 51, 32, 0.1);
}

.download-tab-content {
    padding-top: 30px;
}

.download-tab-content .section-title {
    margin-bottom: 40px;
}

.tabs-content-wrapper {
    position: relative;
}

.tabs-content-wrapper .job-board-tabs-content {
    position: relative;
    z-index: 2;
}

.tabs-content-wrapper .tab-bg-shape-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    width: 100%;
}

@media (max-width: 768px) {
    .tabs-content-wrapper .tab-bg-shape-wrapper {
        display: none;
    }
}

.tabs-content-wrapper .tab-bg-shape-wrapper .dot,
.tabs-content-wrapper .tab-bg-shape-wrapper svg {
    position: absolute;
}

.tabs-content-wrapper .tab-bg-shape-wrapper .dot {
    top: -35px;
    height: 160px;
    width: 180px;
    left: 160px;
}

.tabs-content-wrapper .tab-bg-shape-wrapper svg {
    right: -60px;
    top: -45px;
}

.marketing {
    padding-bottom: 120px;
}

@media (max-width: 991px) {
    .marketing {
        padding-bottom: 80px;
    }
}

.marketing-tabs #pix-tabs-nav {
    text-align: left;
    margin-bottom: 30px;
}

.marketing-tabs #pix-tabs-nav li a {
    background: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 2px solid #edecf1;
    color: #615f6b;
    border-radius: 6px;
}

.marketing-tabs #pix-tabs-nav li a i {
    margin-right: 5px;
}

.marketing-tabs #pix-tabs-nav li.active a {
    background: #f98e47;
    border-color: #f98e47;
    color: #fff;
}

.marketing-tabs-wrapper {
    padding: 60px 100px;
    background: #f5f6f9;
    border-radius: 20px;
}

@media (max-width: 991px) {
    .marketing-tabs-wrapper {
        padding: 50px;
    }
}

.marketing-tabs-wrapper .marketing-tab-contents {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

@media (max-width: 991px) {
    .marketing-tabs-wrapper .marketing-tab-contents {
        display: block;
        text-align: center;
    }
}

.marketing-tabs-wrapper .marketing-tab-contents img {
    background: transparent !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.marketing-tabs-wrapper .marketing-tab-contents .tab-image {
    max-width: 355px;
}

@media (max-width: 991px) {
    .marketing-tabs-wrapper .marketing-tab-contents .tab-image {
        margin: 0 auto 40px;
    }
}

.marketing-tabs-wrapper .marketing-tab-contents .marketing-content {
    -webkit-box-flex: 2;
    -ms-flex: 2;
    flex: 2;
    text-align: left;
    padding-left: 100px;
}

@media (max-width: 991px) {
    .marketing-tabs-wrapper .marketing-tab-contents .marketing-content {
        padding-left: 0;
    }
}

.marketing-tabs-wrapper .marketing-tab-contents .marketing-content .title {
    font-size: 24px;
    font-weight: 600;
    color: #1a133b;
    margin-bottom: 26px;
}

.marketing-tabs-wrapper .marketing-tab-contents .marketing-content p {
    color: #615f6b;
}

.marketing-tabs-wrapper .marketing-tab-contents .feature-items {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 45px;
    margin-top: 40px;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.marketing-tabs-wrapper .marketing-tab-contents .feature-items .feature {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

@media (max-width: 768px) {
    .marketing-tabs-wrapper .marketing-tab-contents .feature-items .feature {
        margin-bottom: 20px;
    }
}

.marketing-tabs-wrapper .marketing-tab-contents .feature-items .feature .icon {
    width: 50px;
    margin-right: 20px;
}

.marketing-tabs-wrapper .marketing-tab-contents .feature-items .feature .feature-content {
    -webkit-box-flex: 2;
    -ms-flex: 2;
    flex: 2;
}

.marketing-tabs-wrapper .marketing-tab-contents .feature-items .feature .feature-content h3 {
    font-size: 16px;
    line-height: 26px;
    font-weight: 500;
    color: #1a133b;
}

.marketing-tabs-wrapper .marketing-tab-contents .pix-btn {
    border: 2px solid #f98e47;
    color: #f98e47;
    border-radius: 6px;
}

.marketing-tabs-wrapper .marketing-tab-contents .pix-btn:hover {
    color: #fff;
    background: #f98e47;
    -webkit-box-shadow: none;
    box-shadow: none;
}


/* Travel Tabs */

.travel-layout {
    padding-top: 100px;
}

#pix-tabs-content.travel-tabs-content {
    position: relative;
    z-index: 33;
}

#pix-tabs-content.travel-tabs-content .content img {
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.tab-bg-shape-wrapper-two {
    position: absolute;
    left: 0;
    top: -10px !important;
}

.layout-content .section-title {
    margin-bottom: 40px;
}

.layout-content #pix-tabs-nav .layout-box {
    padding: 19px 25px 19px 30px;
    border: 2px solid #f2f0f5;
    border-radius: 10px;
    margin-bottom: 10px;
    text-align: left;
    margin-right: 0;
    -webkit-transition: all 0.3s all ease-in-out;
    -o-transition: all 0.3s all ease-in-out;
    transition: all 0.3s all ease-in-out;
}

.layout-content #pix-tabs-nav .layout-box a {
    padding: 0;
    margin: 0;
    background: transparent;
}

.layout-content #pix-tabs-nav .layout-box.active a,
.layout-content #pix-tabs-nav .layout-box:hover a {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.layout-content #pix-tabs-nav .layout-box.active {
    background: #f4f2f7;
    border-color: #f4f2f7;
}

.layout-content #pix-tabs-nav .layout-box__title {
    font-size: 18px;
    margin: 0;
    font-weight: 600;
}

.layout-content #pix-tabs-nav .layout-box__title i {
    font-size: 20px;
    color: #68666c;
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle;
}

.layout-content #pix-tabs-nav .layout-box__description {
    margin-top: 15px;
    padding-left: 34px;
    margin: 10px 0 0;
}


/*--------------------------------------------------------------
  ## Skill
  --------------------------------------------------------------*/

.our-skrill {
    padding-top: 185px;
}

@media (max-width: 991px) {
    .our-skrill {
        padding-top: 100px;
    }
}

.teams-six {
    position: relative;
    overflow: hidden;
}

.teams-six .right-shape {
    position: absolute;
    right: -200px;
    top: 250px;
}

.skill-wrapper .skill-value {
    display: inline-block;
    float: right;
    text-align: right;
    color: #1a133b;
}

.skill-wrapper .skill:not(:last-child) {
    margin-bottom: 40px;
}

.skill-wrapper .skill-label,
.skill-wrapper .skill-value {
    font-size: 16px;
    color: #1a133b;
    font-weight: 500;
}

.skill-wrapper .skill-value {
    font-style: italic;
}

.skill-wrapper .skill .line {
    background-color: #eaecf2;
    position: relative;
    width: 100%;
    height: 6px;
    margin: 10px 0 0 0;
    border-radius: 6px;
}

.skill-wrapper .skill .line .active-line {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 0;
    -webkit-transition: width 1.5s ease;
    -o-transition: width 1.5s ease;
    transition: width 1.5s ease;
    background-color: #fa7070;
    border-radius: 6px;
}

.skill-wrapper .skill.color--two .line .active-line {
    background-color: #6b64fe;
}

.skill-wrapper .skill.color--three .line .active-line {
    background-color: #43bdab;
}

.app-image-wrapper {
    text-align: right;
}

@media (max-width: 991px) {
    .app-image-wrapper {
        text-align: center;
        margin-top: 40px;
    }
}


/*--------------------------------------------------------------
## Promo Box
--------------------------------------------------------------*/

.promo-area {
    padding: 120px 0 90px;
}

@media (max-width: 991px) {
    .promo-area {
        padding: 80px 0 50px;
    }
}

.promo {
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    max-width: 450px;
    margin: 0 auto 30px;
}

.promo img {
    width: 100%;
}

.promo__content {
    position: absolute;
    top: 47px;
    left: 40px;
}

.promo__title {
    color: #fff;
    margin-bottom: 5px;
}

.promo__description {
    color: #fff;
    font-weight: 500;
}

.promo__btn {
    position: absolute;
    left: 40px;
    bottom: 40px;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-size: 15px;
    padding: 11px 21px;
    background: #fff;
    color: #0d031f;
    border: 2px solid #fff;
    font-weight: 500;
}

.promo__btn i {
    font-size: 20px;
    vertical-align: middle;
}

.promo__btn:hover {
    background: transparent;
    border-color: rgba(255, 255, 255, 0.302);
}


/*--------------------------------------------------------------
## Image Content
--------------------------------------------------------------*/

.travel-image-content-area {
    padding: 140px 0 75px;
}

@media (max-width: 991px) {
    .travel-image-content-area {
        padding: 80px 0 40px;
    }
}

.travel-image-content {
    padding-top: 12px;
}

.travel-image-content .lead {
    font-size: 20px;
    font-weight: 400;
    color: #0d031f;
    margin-bottom: 20px;
}

.travel-image-content .description {
    margin: 0;
    margin-top: -20px;
}

.travel-parallax-image {
    position: relative;
}

.travel-parallax-image .travel-image-one {
    text-align: center;
}

.travel-parallax-image .travel-image-two {
    position: absolute;
    left: -65px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

@media (max-width: 991px) {
    .travel-parallax-image .travel-image-two {
        max-width: 220px;
    }
}

@media (max-width: 767px) {
    .travel-parallax-image .travel-image-two {
        left: 0;
    }
}

.shape-circle-bg {
    position: absolute;
    border-radius: 50%;
    background-color: #ff9f65;
    left: 50%;
    top: 46%;
    width: 420px;
    height: 420px;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: -1;
}

@media (max-width: 991px) {
    .shape-circle-bg {
        height: 320px;
        width: 320px;
    }
}


/*--------------------------------------------------------------
## Traking
--------------------------------------------------------------*/

.traking {
    padding: 117px 0 80px;
}

@media (max-width: 991px) {
    .traking {
        padding-top: 80px;
    }
}

.traking .button-container {
    margin-top: 50px;
}

.traking__item {
    padding: 0 15px;
}

.traking__item img {
    -webkit-box-shadow: 0px 30px 70px 0px rgba(12, 8, 44, 0.1);
    box-shadow: 0px 30px 70px 0px rgba(12, 8, 44, 0.1);
    border-radius: 10px;
    margin-bottom: 30px;
}

.traking--images {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

@media (max-width: 1200px) {
    .traking--images .traking__item {
        width: 25% !important;
    }
}

@media (max-width: 767px) {
    .traking--images .traking__item {
        width: 50% !important;
    }
}

@media (max-width: 380px) {
    .traking--images .traking__item {
        width: 100% !important;
    }
    .traking--images .traking__item img {
        width: 100%;
    }
}

.traking__item--width-one {
    width: 300px;
}

.traking__item--width-two {
    width: 230px;
}

.traking__item--width-three {
    width: 270px;
}

.traking__item--width-four {
    width: 200px;
}


/*--------------------------------------------------------------
  ##  Blog
  --------------------------------------------------------------*/

#blog-grid {
    padding: 72px 0 90px;
}

#blog-grid .feature-image a {
    display: block;
    position: relative;
    overflow: hidden;
}

#blog-grid .feature-image a img {
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    width: 100%;
}

#blog-grid .feature-image a:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    opacity: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    z-index: 22;
}

#blog-grid .feature-image a:hover:before {
    opacity: 1;
}

#blog-grid .feature-image a:hover img {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
}

.blog-post {
    border-radius: 6px;
    background-color: white;
    -webkit-box-shadow: 0px 6px 14px 0px rgba(43, 35, 79, 0.1);
    box-shadow: 0px 6px 14px 0px rgba(43, 35, 79, 0.1);
    overflow: hidden;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    margin-bottom: 30px;
}

.blog-post:hover {
    -webkit-box-shadow: 0px 30px 60px 0px rgba(43, 35, 79, 0.12);
    box-shadow: 0px 30px 60px 0px rgba(43, 35, 79, 0.12);
}

.blog-post.style-three {
    border-radius: 20px;
    -webkit-box-shadow: 0px 2px 4px 0px rgba(14, 3, 52, 0.08);
    box-shadow: 0px 2px 4px 0px rgba(14, 3, 52, 0.08);
    overflow: hidden;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

@media (max-width: 768px) {
    .blog-post.style-three img {
        width: 100%;
    }
}

.blog-post.style-three .blog-content {
    padding: 22px 40px 30px;
}

.blog-post.style-three .blog-content .post-meta li a {
    font-size: 14px;
    text-transform: capitalize;
}

.blog-post.style-three .blog-content .entry-title {
    margin: 0 0 10px;
}

.blog-post.style-three .blog-content .entry-title a {
    color: #1a133b;
}

.blog-post.style-three .blog-content .entry-title a:hover {
    color: #f98e47;
}

.blog-post.style-three .blog-content .read-more {
    font-size: 35px;
    color: #aeaeb5;
}

.blog-post.style-three .blog-content .read-more:hover {
    color: #f98e47;
}

.blog-post.style-three:hover {
    -webkit-box-shadow: 0px 20px 50px 0px rgba(14, 3, 52, 0.1);
    box-shadow: 0px 20px 50px 0px rgba(14, 3, 52, 0.1);
}

.blog-content {
    padding: 22px 45px 30px;
    background: #fff;
}

.blog-content .post-meta {
    margin: 0;
    padding: 0;
    list-style: none;
}

.blog-content .post-meta li {
    display: inline-block;
    margin-right: 30px;
    position: relative;
}

.blog-content .post-meta li:not(:last-child):after {
    position: absolute;
    content: '';
    right: -18px;
    top: 7px;
    width: 2px;
    height: 14px;
    background: #9997a3;
}

.blog-content .post-meta li i {
    margin-right: 5px;
    color: #fa7070;
}

.blog-content .post-meta li a {
    color: #797687;
    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase;
}

.blog-content .post-meta li a:hover {
    color: #fa7070;
}

.blog-content .entry-title {
    font-size: 20px;
    font-weight: 600;
    margin: 10px 0 32px;
    line-height: 30px;
}

.blog-content .entry-title a {
    color: #222;
}

.blog-content .entry-title a:hover {
    color: #fa7070;
}

.blog-content .read-more-ntn {
    font-size: 12px;
    font-weight: 600;
    color: #fa7070;
    text-transform: uppercase;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.blog-content .read-more-ntn i {
    font-size: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 5px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.blog-content .read-more-ntn:hover {
    color: #f83f3f;
}

.blog-content .read-more-ntn:hover i {
    margin-left: 8px;
}

.color-two .blog-content .post-meta li a:hover {
    color: #7052fb;
}

.color-two .blog-content .entry-title a:hover {
    color: #7052fb;
}

.post-author {
    font-size: 16px;
    font-weight: 500;
    color: #656177;
}

.post-author img {
    border-radius: 50%;
    margin-right: 20px;
}

.post-author:hover {
    color: #7052fb;
}

.blog-post-archive {
    padding: 120px 0;
}

.post-wrapper {
    max-width: 670px;
}

.post-wrapper .categories {
    position: absolute;
    top: 40px;
    right: 0;
}

.post-wrapper .category-item {
    font-size: 16px;
    font-weight: 700;
    background: #f8f6f2;
    padding: 9px 17px;
    color: #827f79;
    margin-left: 5px;
}

.post-wrapper .category-item:hover {
    background: #2b2350;
    color: #fff;
}

.post-wrapper .post {
    margin-bottom: 60px;
}

.post-wrapper .post:last-child .post {
    margin-bottom: 0;
}

.post .feature-image {
    border-radius: 6px;
    overflow: hidden;
}

.post .blog-content {
    padding: 28px 0 40px;
    position: relative;
    border-bottom: 1px solid #e6e5e0;
}

.post .blog-content .post-meta li a {
    color: #797687;
    font-size: 15px;
    margin-right: 3px;
}

.post .blog-content .post-meta li a:hover {
    color: #fa7070;
}

.post .blog-content .entry-title {
    margin: 14px 0 22px;
    font-size: 30px;
    line-height: 1.2;
}

.post .blog-content p {
    margin-bottom: 35px;
}

.post .blog-content .read-more {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    color: #2b2350;
    position: relative;
}

.post .blog-content .read-more i {
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    vertical-align: middle;
    font-size: 16px;
}

.post .blog-content .read-more:after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 1px;
    background: #fa7070;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.post .blog-content .read-more:hover {
    color: #fa7070;
}

.post .blog-content .read-more:hover i {
    margin-left: 3px;
    color: #fa7070;
}

.post .blog-content .read-more:hover:after {
    width: 100%;
}

.post .author {
    position: absolute;
    right: 0;
    bottom: 40px;
}

.post .author img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin-right: 15px;
}

.post .author span {
    font-size: 16px;
    font-weight: 500;
}

.post .author a {
    font-size: 16px;
    font-weight: 500;
    color: #2b2350;
}

.post .author a:hover {
    color: #fa7070;
}

.post.video-post .feature-image {
    position: relative;
}

.post.video-post .feature-image .video-btn {
    height: 80px;
    width: 80px;
    background: #fff;
    border-radius: 50%;
    line-height: 80px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}

.post.video-post .feature-image .video-btn:before,
.post.video-post .feature-image .video-btn:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border: 1px solid #fff;
    border-radius: 50%;
    -webkit-animation: pulse 2s linear infinite;
    animation: pulse 2s linear infinite;
}

.post.video-post .feature-image .video-btn:after {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
}

.post.video-post .feature-image .video-btn i {
    color: #2b2350;
    font-size: 16px;
    font-size: 40px;
    line-height: 2.05;
}

.post.link-post .blog-content {
    background: #fdf8f8;
    padding: 50px 50px;
    border-bottom: 0;
    position: relative;
    overflow: hidden;
    border-radius: 6px;
}

.post.link-post .blog-content:before,
.post.link-post .blog-content:after {
    content: '';
    font-family: 'ElegantIcons';
    position: absolute;
}

.post.link-post .blog-content:before {
    left: 28px;
    top: 24px;
    color: #f7eded;
    font-size: 50px;
    z-index: 1;
}

.post.link-post .blog-content:after {
    right: 0;
    bottom: -3px;
    font-size: 100px;
    color: #f7eded;
}

.post.link-post .blog-content p {
    font-size: 20px;
    font-weight: 0;
    position: relative;
    z-index: 2;
    margin-bottom: 0;
    line-height: 34px;
}

.post.link-post .blog-content p a {
    color: #656177;
    font-weight: 500;
}

.post.link-post .blog-content p a:hover {
    color: #fa7070;
}

.blog-post-two {
    margin-bottom: 30px;
}

.blog-post-two .feature-image {
    border-radius: 6px;
    overflow: hidden;
}

.blog-post-two .feature-image img {
    border-radius: 6px;
    width: 100%;
}

.blog-post-two .blog-content {
    padding: 22px 0 0;
    background: transparent;
}

.blog-post-two .blog-content .post-meta li a {
    text-transform: capitalize;
}

.blog-post-two .blog-content .entry-title {
    font-size: 24px;
    margin: 5px 0 15px;
}

.blog-post-two .blog-content p {
    margin-bottom: 30px;
}

.blog-post-two .blog-content .read-more {
    border: 2px solid #7052fb;
    border-radius: 30px;
    padding: 7px 25px;
    font-weight: 500;
    display: inline-block;
    color: #7052fb;
}

.blog-post-two .blog-content .read-more:hover {
    background: #7052fb;
    color: #fff;
    -webkit-box-shadow: 0px 20px 30px 0px rgba(75, 42, 222, 0.2);
    box-shadow: 0px 20px 30px 0px rgba(75, 42, 222, 0.2);
}

.blog-grid-two {
    padding: 90px 0;
}

.author img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin-right: 15px;
}

.author span {
    font-size: 16px;
    font-weight: 500;
}

.author a {
    font-size: 16px;
    font-weight: 500;
    color: #2b2350;
}

.author a:hover {
    color: #fa7070;
}

.quote-post {
    position: relative;
    background: #fdf8f8;
    padding: 50px;
    border-bottom: 0;
    overflow: hidden;
    border-radius: 6px;
}

.quote-post:before {
    content: '';
    position: absolute;
    left: 28px;
    top: -20px;
    background-image: url(../../media/blog/quote2.png);
    width: 140px;
    height: 80px;
    z-index: 1;
}

.quote-post p {
    font-size: 20px;
    font-weight: 400;
    line-height: 34px;
    margin-bottom: 50px;
    position: relative;
    z-index: 2;
    color: #656177;
}

.quote-post span {
    font-size: 16px;
    font-weight: 500;
    color: #2b2350;
}

.quote-post .quote {
    position: absolute;
    right: 50px;
    bottom: 35px;
}

.post-post-grid {
    border-radius: 6px;
    -webkit-box-shadow: 0px 6px 14px 0px rgba(43, 35, 79, 0.1);
    box-shadow: 0px 6px 14px 0px rgba(43, 35, 79, 0.1);
    overflow: hidden;
    margin-bottom: 40px;
}

.post-post-grid .feature-image img {
    width: 100%;
}

.post-post-grid .blog-content {
    padding: 22px 40px 30px;
}

.post-post-grid .blog-content .entry-title {
    margin: 8px 0 37px;
    line-height: 30px;
}

.post-navigation {
    margin: 30px 0 0;
    padding: 0;
    list-style: none;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.post-navigation li {
    display: inline-block;
    height: 50px;
    width: 50px;
    line-height: 50px;
    font-size: 18px;
    margin-right: 10px;
    text-align: center;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    color: #797687;
    border-radius: 50%;
    border: 1px solid #e5dada;
}

.post-navigation li a {
    display: block;
    color: #797687;
}

.post-navigation li.prev {
    line-height: 54px;
    font-size: 24px;
}

.post-navigation li.next {
    line-height: 54px;
    font-size: 24px;
}

.post-navigation li:hover {
    border-color: #fa7070;
    background: #fa7070;
    -webkit-box-shadow: 0px 20px 30px 0px rgba(176, 70, 70, 0.3);
    box-shadow: 0px 20px 30px 0px rgba(176, 70, 70, 0.3);
}

.post-navigation li:hover a {
    color: #fff;
}

.post-navigation li.active {
    background: #fa7070;
    color: #fff;
    -webkit-box-shadow: 0px 20px 30px 0px rgba(176, 70, 70, 0.3);
    box-shadow: 0px 20px 30px 0px rgba(176, 70, 70, 0.3);
}

.blog-single {
    padding-top: 120px;
}

.blog-single .post {
    margin-bottom: 20px;
}

.blog-single .post .blog-content {
    padding: 40px 0 20px;
}

.blog-single .post .blog-content p {
    margin-bottom: 45px;
}

.blog-single .post .blog-content h3 {
    margin-top: 42px;
    font-size: 34px;
    font-weight: 500;
    margin-bottom: 20px;
}

.blog-single blockquote {
    margin-bottom: 42px;
    display: block;
}

.blog-single .tagcloud span {
    color: #2b2350;
    display: inline-block;
    margin-right: 15px;
    font-size: 20px;
    font-weight: 500;
}

.blog-single .tagcloud a {
    line-height: 25px;
}

.blog-share {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.blog-share .share-title p {
    color: #2b2350;
    display: inline-block;
    margin: 0;
    font-weight: 500;
    font-size: 20px;
}

.share-link {
    margin: 0;
    padding: 0;
    list-style: none;
}

.share-link li {
    display: inline-block;
    margin-right: 5px;
}

.share-link li:last-child {
    margin-right: 0;
}

.share-link li a {
    display: block;
    height: 44px;
    width: 44px;
    text-align: center;
    line-height: 45px;
    color: #aba8a4;
    border-radius: 50%;
    font-size: 14px;
    border: 1px solid #e5dada;
}

.share-link li a:hover {
    background: #fa7070;
    color: #fff;
    -webkit-box-shadow: 0px 20px 30px 0px rgba(176, 70, 70, 0.3);
    box-shadow: 0px 20px 30px 0px rgba(176, 70, 70, 0.3);
    border-color: #fa7070;
}

.pixsass_post_author_box {
    background: #fdf8f8;
    padding: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: 80px;
    border-radius: 6px;
}

.pixsass_post_author_box .profile_image {
    width: 80px;
    margin-right: 20px;
    border-radius: 50%;
}

.pixsass_post_author_box .profile_image img {
    border-radius: 50%;
}

.pixsass_post_author_box .profile_content {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.pixsass_post_author_box .profile_content .profile_name {
    font-size: 20px;
    line-height: 28px;
    color: #2b2350;
    margin: 0;
}

.pixsass_post_author_box .profile_content .author-job {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 15px;
    display: inline-block;
}

.pixsass_post_author_box .profile_content p {
    font-size: 18px;
    color: #797687;
    margin: 0;
    font-weight: 500;
}

.comment-area {
    padding: 110px 0 120px;
}

.comment-area .coment-title {
    font-size: 24px;
    font-weight: 600;
    color: #2b2350;
    margin-bottom: 70px;
}

.comment-list {
    margin: 0;
    padding: 0;
    list-style: none;
}

.comment-list .comment .comment-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 40px;
}

.comment-list .comment .comment-body .comment-author {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin-right: 30px;
}

.comment-list .comment .comment-body .comment-author img {
    border-radius: 50%;
}

.comment-list .comment .comment-body .comment-content {
    border-bottom: 1px solid #e6e5e0;
    padding-bottom: 30px;
    position: relative;
}

.comment-list .comment .comment-body .comment-content h4 {
    margin-top: 0;
    margin-bottom: 7px;
    font-size: 16px;
}

.comment-list .comment .comment-body .comment-content h4 a {
    color: #2b2350;
}

.comment-list .comment .comment-body .comment-content h4 a:hover {
    color: #fa7070;
}

.comment-list .comment .comment-body .comment-content .comment-reply-link {
    color: #2b2350;
    font-size: 15px;
    font-weight: 500;
}

.comment-list .comment .comment-body .comment-content .comment-reply-link i {
    margin-right: 6px;
    font-size: 16px;
}

.comment-list .comment .comment-body .comment-content .comment-reply-link:hover {
    color: #fa7070;
}

.comment-list .comment .comment-body .comment-content .comment-metadata {
    margin-bottom: 10px;
}

.comment-list .comment .comment-body .comment-content .comment-metadata a {
    font-size: 16px;
    color: #918f9c;
    font-weight: 400;
    line-height: 28px;
}

.comment-list .comment .comment-body .comment-content .comment-metadata a:hover {
    color: #fa7070;
}

.comment-list .comment .comment-body.menu-comments .comment-author {
    width: 100px;
    height: 100px;
}

.comment-list .comment .comment-body.menu-comments .comment-author img {
    width: 100%;
}

.comment-list .children {
    margin: 0;
    padding-left: 150px;
    list-style: none;
}

.comment-list .children .comment .comment-body .comment-author {
    width: 50px;
    height: 50px;
}

.comment-respond .comment-reply-title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 42px;
}

.comment-form .comment-form-author,
.comment-form .comment-form-email {
    width: 50%;
    float: left;
    margin-bottom: 30px;
}

.comment-form .comment-form-author {
    padding-right: 15px;
}

.comment-form .comment-form-email {
    padding-left: 15px;
}

.comment-form input,
.comment-form textarea {
    background: #fff;
    border: 0;
    font-size: 16px;
    padding: 15px 30px;
    background: #fdfafa;
    border: 1px solid #efe7e7;
    margin: 0;
}

.comment-form input::-webkit-input-placeholder,
.comment-form textarea::-webkit-input-placeholder {
    color: #a7a5b1;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.comment-form input::-moz-placeholder,
.comment-form textarea::-moz-placeholder {
    color: #a7a5b1;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.comment-form input:-ms-input-placeholder,
.comment-form textarea:-ms-input-placeholder {
    color: #a7a5b1;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.comment-form input::-ms-input-placeholder,
.comment-form textarea::-ms-input-placeholder {
    color: #a7a5b1;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.comment-form input::placeholder,
.comment-form textarea::placeholder {
    color: #a7a5b1;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.comment-form input:focus,
.comment-form textarea:focus {
    background-color: white;
    -webkit-box-shadow: 0px 20px 40px 0px rgba(79, 35, 35, 0.1);
    box-shadow: 0px 20px 40px 0px rgba(79, 35, 35, 0.1);
}

.comment-form .comment-form-comment {
    margin-bottom: 21px;
}

.comment-form textarea {
    height: 200px;
    border-radius: 20px;
}

.form-footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.form-footer input {
    width: 15px;
    margin-right: 10px;
}

.form-footer .condition {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.form-footer .condition span {
    font-size: 14px;
}

.form-footer .submit-btn {
    padding: 8px 45px;
}

.circle-blog {
    position: absolute;
    top: -11%;
    left: -11%;
    z-index: -1;
}

@media (max-width: 991px) {
    #blog-grid {
        padding: 19px 0 50px;
    }
    .blog-post-archive {
        padding: 80px 0 70px;
    }
    .blog-single {
        padding-top: 80px;
    }
    .sidebar {
        margin-top: 40px;
    }
    .comment-area {
        padding: 70px 0 80px;
    }
    .form-footer {
        display: block;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }
    .form-footer input {
        width: 15px;
        margin-right: 10px;
    }
    .form-footer .condition {
        margin-bottom: 30px;
    }
    .form-footer .condition span {
        font-size: 14px;
    }
    .form-footer .submit-btn {
        padding: 8px 45px;
    }
    .blog-grid-two {
        padding-top: 0;
        padding-bottom: 50px;
    }
}

@media (max-width: 768px) {
    .form-footer .condition {
        -webkit-box-align: normal;
        -ms-flex-align: normal;
        align-items: normal;
    }
    .form-footer .condition span {
        margin-top: -6px;
    }
    .circle-blog {
        display: none;
    }
}

@media (max-width: 576px) {
    .comment-list .children {
        padding-left: 95px;
    }
    .comment-list .children .comment .comment-body .comment-author {
        margin-right: 15px;
    }
    .countup .scroll-circle {
        display: none;
    }
}

@media (max-width: 480px) {
    .comment-form .comment-form-author,
    .comment-form .comment-form-email {
        width: 100%;
        padding: 0;
    }
}


/*--------------------------------------------------------------
  ##  Widget
  --------------------------------------------------------------*/

.search-form-widget {
    position: relative;
}

.search-form-widget label {
    margin: 0;
    display: block;
}

.search-form-widget .search-field {
    background: transparent;
    border: 0;
    padding: 19px 10px 19px 30px;
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    border-radius: 45px;
    width: 100%;
    border: 1px solid #efe7e7;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.search-form-widget .search-field::-webkit-input-placeholder {
    color: #797687;
}

.search-form-widget .search-field::-moz-placeholder {
    color: #797687;
}

.search-form-widget .search-field:-ms-input-placeholder {
    color: #797687;
}

.search-form-widget .search-field::-ms-input-placeholder {
    color: #797687;
}

.search-form-widget .search-field::placeholder {
    color: #797687;
}

.search-form-widget .search-field:focus,
.search-form-widget .search-field:hover {
    -webkit-box-shadow: 0px 20px 40px 0px rgba(79, 35, 35, 0.1);
    box-shadow: 0px 20px 40px 0px rgba(79, 35, 35, 0.1);
}

.search-form-widget button {
    bottom: 0;
    border: 0;
    background-color: transparent;
    color: #9e9c97;
    outline: 0;
    position: absolute;
    top: 5px;
    right: 20px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 20px;
    color: #fa7070;
    -webkit-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
}

.search-form-widget button:hover {
    color: #fa7070;
}

.widget:not(:last-child) {
    margin-bottom: 50px;
}

.widget .widget-title {
    font-size: 20px;
    font-weight: 600;
    border-bottom: 1px solid #ebe3e3;
    padding-bottom: 13px;
    margin-bottom: 25px;
}

.widget ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.widget ul li a {
    font-size: 16px;
    line-height: 40px;
    color: #797687;
    font-weight: 500;
}

.widget ul li a:hover {
    color: #fa7070;
}

.widget .social-share-link {
    margin: 0;
    padding: 0;
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.widget .social-share-link li a {
    display: block;
    height: 35px;
    width: 35px;
    line-height: 35px;
    background: #fa7070;
    color: #fff;
    border-radius: 50%;
    text-align: center;
    margin-right: 10px;
    font-size: 14px;
}

.widget .social-share-link li a:hover {
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px);
    color: #fff;
}

.widget .social-share-link li a.share_facebook {
    background: #4267b2;
}

.widget .social-share-link li a.share_twitter {
    background: #1da1f2;
}

.widget .social-share-link li a.share_pinterest {
    background: #e60023;
}

.widget .social-share-link li a.share_linkedin {
    background: #0073b1;
}

.footer-marketing .widget .footer-title {
    font-size: 16px;
    font-weight: 600;
    color: #1a133b;
    margin-bottom: 16px;
}

.footer-marketing .widget .social-share-link li a {
    height: 40px;
    width: 40px;
    line-height: 40px;
    font-size: 14px;
}

.footer-marketing .widget .social-share-link li a:hover {
    -webkit-transform: translate(0);
    -ms-transform: translate(0);
    transform: translate(0);
}

.footer-marketing .widget .social-share-link li a.share_facebook:hover {
    -webkit-box-shadow: 0px 10px 20px 0px rgba(66, 103, 178, 0.3);
    box-shadow: 0px 10px 20px 0px rgba(66, 103, 178, 0.3);
}

.footer-marketing .widget .social-share-link li a.share_twitter:hover {
    -webkit-box-shadow: 0px 10px 20px 0px rgba(9, 111, 150, 0.3);
    box-shadow: 0px 10px 20px 0px rgba(9, 111, 150, 0.3);
}

.footer-marketing .widget .social-share-link li a.share_pinterest:hover {
    -webkit-box-shadow: 0px 10px 20px 0px rgba(230, 0, 35, 0.3);
    box-shadow: 0px 10px 20px 0px rgba(230, 0, 35, 0.3);
}

.footer-marketing .widget .social-share-link li a.share_linkedin:hover {
    -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 115, 177, 0.3);
    box-shadow: 0px 10px 20px 0px rgba(0, 115, 177, 0.3);
}


/* Recent Post */

.gp-posts-widget-wrapper .post-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.gp-posts-widget-wrapper .post-item:not(:last-child) {
    margin-bottom: 30px;
}

.gp-posts-widget-wrapper .post-item .post-widget-thumbnail {
    border-radius: 5px;
    overflow: hidden;
    width: 100px;
    margin-right: 30px;
}

.gp-posts-widget-wrapper .post-item .post-widget-info {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.gp-posts-widget-wrapper .post-item .post-widget-info .post-widget-title {
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
}

.gp-posts-widget-wrapper .post-item .post-widget-info .post-widget-title a {
    color: #2b2350;
}

.gp-posts-widget-wrapper .post-item .post-widget-info .post-widget-title a:hover {
    color: #fa7070;
}

.gp-posts-widget-wrapper .post-item .post-widget-info .post-date {
    color: #797687;
    font-size: 15px;
    line-height: 28px;
    font-weight: 500;
}


/* Tags */

.tagcloud a {
    font-size: 14px;
    background: #f3ecec;
    color: #797687;
    padding: 6px 21px;
    font-weight: 500;
    margin-bottom: 10px;
    margin-right: 4px;
    display: inline-block;
    border-radius: 30px;
}

.tagcloud a:hover {
    background: #fa7070;
    color: #fff;
    -webkit-box-shadow: 0px 20px 30px 0px rgba(176, 70, 70, 0.3);
    box-shadow: 0px 20px 30px 0px rgba(176, 70, 70, 0.3);
    position: relative;
    z-index: 2;
}


/* Comments */

.comment-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 30px;
}

.comment-list .icon {
    height: 70px;
    width: 70px;
    line-height: 77px;
    text-align: center;
    color: #2b2350;
    background: #f3ecec;
    border-radius: 50%;
    color: #797687;
    margin-right: 30px;
    font-size: 20px;
}

.comment-list .comment-content {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.comment-list .comment-content h3 {
    color: #2b2350;
    font-size: 16px;
    margin-bottom: 10px;
}

.comment-list .comment-content h3 span {
    color: #797687;
    font-weight: 400;
}

.comment-list .comment-content p {
    line-height: 26px;
    margin-bottom: 24px;
    font-weight: 500;
}

.comment-list .comment-content p a {
    color: #797687;
}

.comment-list .comment-content p a:hover {
    color: #fa7070;
}

@media (max-width: 768px) {
    .search-form-widget {
        margin-top: 80px;
    }
    .search-form-widget label {
        width: 100%;
    }
}


/*--------------------------------------------------------------
  ##  Sign
  --------------------------------------------------------------*/

.signin {
    height: 1050px;
    position: relative;
    overflow: hidden;
}

.signin .circle {
    position: absolute;
    left: -17%;
    top: -33%;
}

.signin .animate-ball .ball {
    background: #fff7f8;
}

.signin .animate-ball .ball:nth-child(1) {
    top: 22%;
    left: -30%;
}

.signin .animate-ball .ball:nth-child(2) {
    height: 60px;
    width: 60px;
}

.signin .animate-ball .ball:nth-child(3) {
    bottom: 110px;
    right: auto;
    left: -20%;
    height: 80px;
    width: 80px;
}

.signin .animate-ball .ball:nth-child(4) {
    bottom: 30%;
    right: auto;
    left: -41%;
}

.signin .animate-ball .ball:nth-child(5) {
    top: auto;
    right: 100px;
    bottom: -430px;
}

.signin-from-wrapper {
    height: 1050px;
    position: relative;
    z-index: 22;
}

.signin-from-wrapper .signin-from-inner {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    padding-right: 100px;
    position: relative;
    z-index: 222;
}

.signin-from-wrapper .signin-from-inner .title {
    font-size: 36px;
}

.signin-from-wrapper .signin-from-inner input:focus {
    -webkit-box-shadow: 0px 20px 40px 0px rgba(79, 35, 35, 0.1);
    box-shadow: 0px 20px 40px 0px rgba(79, 35, 35, 0.1);
    background: #fff;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.signin-from-wrapper .signin-from-inner button {
    border: 0;
    padding: 11px 67px;
    outline: 0;
    margin-bottom: 33px;
    margin-top: 55px;
}

.signin-from-wrapper .signin-from-inner p {
    margin-top: 10px;
    margin-bottom: 38px;
    font-size: 18px;
}

.signin-from-wrapper .signin-from-inner p a {
    color: #fa7070;
}

.signin-banner {
    background: #fef8f8;
    position: absolute;
    right: 0;
    height: 100%;
    max-width: 48%;
    width: 100%;
    top: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.signin-banner .image-two {
    margin-left: -35px;
    margin-top: -140px;
}

.signin-banner.signup-banner {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.signin-banner.signup-banner .image-one,
.signin-banner.signup-banner .image-two {
    position: absolute;
    bottom: 0;
    margin: 0;
}

.signin-banner.signup-banner .image-one {
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    margin: 0;
}

.signin-banner.signup-banner .animate-image-inner {
    position: absolute;
    width: 100%;
    height: 450px;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.condition {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.condition .styled-checkbox {
    position: absolute;
    opacity: 0;
    height: 20px;
    width: 16px;
    margin: 0;
}

.condition span {
    font-size: 14px;
}

.styled-checkbox+label {
    position: relative;
    cursor: pointer;
    padding: 0;
    margin: 0;
    height: 20px;
}


.styled-checkbox+label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 16px;
    height: 16px;
    border: 2px solid #aba8a4;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.styled-checkbox:hover+label:before {
    border-color: #fa7070;
}

.styled-checkbox:checked+label:before {
    border-color: #fa7070;
}
 .bannerid{
    background: linear-gradient(to bottom, #ffffff, #add8e6);
 }
 .bannerIdup{
    background: linear-gradient(to bottom,#add8e6, #ffffff);
 }
 .iems-circle{
    height: 21px;
    width: 21px;
    border: 2px;
    border-style: solid;
    border-color: #0073b1;
    border-radius: 100px;
 }
 .bannerDownBox{
    background-color: #0073b1;
    height: 80px;
    width: 1000px;
    display: flex;
    justify-content: end;
    margin-left:370px;
    transform: skew(-40deg);
    
 }
 .fitImage{
    width: 95%;
    height: 1100px;
 }
.bars{
    height: 20px;
    width: 1px;
    border-style: solid;
    border-color: #0073b1;
   
}
 
 .IEMSbutton{
    border: #13bf24;
    padding-right: 30px;
    padding-left: 30px;
    width: fit-content;
    border-radius: 10px;
    margin: 10px;
    border-color: #0073b1;
    
 }
 .Imagebox{
    height: 250px;
    width: 300px;
    border-color: #0073b1;
    border-style: solid;
    border-radius: 10px;
 }
.SmallImageBox{
    height: 100px;
    width: 200px;
    justify-content: center;
    border-image-slice: 1;
}

.danger{
   
    margin-left: -200px;
}

@keyframes slideUp {
    from {
      transform: translateY(50px);
      opacity: 1; /* Start from fully invisible */
    }
    to {
      transform: translateY(0);
      opacity: 1; /* End fully visible */
    }
  }
  
  .blockAnimation {
    opacity: 0; /* Start hidden */
    transform: translateY(50px); /* Start below the viewport */
    transition: opacity 0.5s ease-out; /* Smooth transition for opacity */
  }
  
  .blockAnimation.appear {
    animation: slideUp 1s ease-out forwards; /* Apply slideUp animation */
  }
  
  @keyframes slideFromCenter {
    from {
      transform: translateX(-50vw); /* Start from the middle of the viewport */
      opacity: 0; /* Start invisible */
    }
    to {
      transform: translateX(0); /* Move to final position */
      opacity: 1; /* End fully visible */
    }
  }
  
  .ImageBoxSmall {
    opacity: 0; /* Start hidden */
    transform: translateX(-50vw); /* Start from the middle horizontally */
    transition: opacity 0.5s ease-out; /* Smooth transition for opacity */
  }
  
  .ImageBoxSmall.appear {
    animation: slideFromCenter 1s ease-out forwards; /* Apply slideFromCenter animation */
  }
  
  .animationTextSmall{
    font-size: 30px;
  }
  
@media (min-width: 1400px) and (max-width: 1920px) {
    .bannerDownBox {
        height: 80px;
        width: 1000px;
        display: flex;
        justify-content: end;
        margin-left:1000px;
        transform: skew(-40deg);
    }
    .SmallImageBox{
        height: 150px;
        width: 250px;
        justify-content: center;
        border-image-slice: 1;
    }
       
      
}

 @media (max-width: 991px) {
      .display-4 {
      font-size: 4rem; 
    }
     .fs-4{
        font-size: 1rem; 
     }
     .bannerDownBox{
        height: 60px;
        width: 800px;
        display: flex;
        justify-content: end;
        margin-left:200px;
        transform: skew(-40deg);
     }
     .textWarp{
        padding-right: 200px;
     }
     .Imagebox{
    height: 200px;
    width: 230px;
    border-style: solid;
    border-radius: 10px;
     }
     .iems-circle{
        height: 15px;
        width: 15px;
        border-style: solid;
        border-color: #0073b1;
        border-radius: 100px;
     }
     /* .subscol {
      background-color: #12bf7c;
      } */
  }


  @media (max-width: 768px) {
    .display-4 {
    font-size: 2rem; 
  }
   .fs-4{
      font-size: 1rem; 
   }
   .bannerDownBox{
      height: 60px;
      width: 800px;
      display: flex;
      justify-content: end;
      margin-left:60px;
      transform: skew(-40deg);
   }
   .textWarp{
    padding-right: 150px;
   }
 .IemsImagebox{
    display: flex;
    flex-direction: column;
    gap: 5px;
 }
.fs-6{
    display: hidden;
}
 
}

  
@media (max-width:768px) {
    .display-4 {
    font-size: 2rem; 
  }
   .fs-4{
      font-size: 1rem; 
   }
   .fitImage{
    width: 100%;
    height: 500px;
 }
   .textWarp{
    padding-right: 100px;
   }
 .IemsImagebox{
    display: flex;
    flex-direction: column;
    gap: 5px;
 }
 .animationTextSmall{
    font-size: 14px;
 }
 .iemsFeature{
    align-items: center;
    justify-content: center;
    padding: 10px;
 }
 .small{
    gap: 200px;
 }
}
 .textWarp{
    transform: skew(40deg);
    padding-right: 300px;
 }
.styled-checkbox:checked+label:after {
    content: '';
    position: absolute;
    left: 3px;
    top: 9px;
    background: #fa7070;
    width: 2px;
    height: 2px;
    -webkit-box-shadow: 2px 0 0 #fa7070, 4px 0 0 #fa7070, 4px -2px 0 #fa7070, 4px -4px 0 #fa7070, 4px -6px 0 #fa7070, 4px -8px 0 #fa7070;
    box-shadow: 2px 0 0 #fa7070, 4px 0 0 #fa7070, 4px -2px 0 #fa7070, 4px -4px 0 #fa7070, 4px -6px 0 #fa7070, 4px -8px 0 #fa7070;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.forget-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-top: 5px;
}

.forget-link .forget {
    font-size: 14px;
    color: #9694a2;
}

.forget-link .forget:hover {
    color: #fa7070;
}

.singup-social {
    margin-bottom: 25px;
    padding: 0;
    list-style: none;
}

.singup-social li {
    display: inline-block;
    margin-bottom: 10px;
}

.singup-social li:not(:last-child) {
    margin-right: 4px;
}

.singup-social li a {
    display: inline-block;
    padding: 11px 35px;
    color: #fff;
    border-radius: 30px;
    font-size: 16px;
    width: 180px;
    text-align: center;
}

.singup-social li a i {
    margin-right: 10px;
}

.singup-social li a:hover {
    opacity: 0.8;
}

.singup-social li:nth-child(1) a {
    background: #3b5998;
}

.singup-social li:nth-child(2) a {
    background: #dd4b39;
}

.singup-social li:nth-child(3) a {
    background: #55acee;
}


@media (max-width: 991px) {
    .signin-from-wrapper .signin-from-inner {
        -webkit-transform: translateY(-47%);
        -ms-transform: translateY(-47%);
        transform: translateY(-47%);
    }
    .signin-from-wrapper .signin-from-inner .title {
        font-size: 30px;
    }
    .signup input {
        margin-bottom: 20px;
    }
}

@media (max-width: 991px) {
    .signin {
        height: auto;
    }
    .signin-from-wrapper {
        height: auto;
    }
    .signin-from-wrapper .signin-from-inner {
        position: relative;
        height: auto;
        z-index: 22;
        padding: 150px 0 36px;
        top: 0;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }
    .signin-from-wrapper .signin-from-inner .title {
        font-size: 30px;
    }
    .signin-banner {
        position: relative;
        max-width: 100%;
        z-index: 222;
        padding: 80px 0;
    }
    .signin-banner .image-one {
        max-width: 200px;
    }
    .signin-banner .image-two {
        max-width: 80px;
        margin-left: -20px;
        margin-top: -50px;
    }
    .signin-banner.signup-banner {
        height: 350px;
    }
    .signin-banner.signup-banner .animate-image-inner {
        height: 200px;
    }
    .signin-banner.signup-banner .animate-image-inner .image-two img {
        width: 60px;
    }
}

@media (max-width: 768px) {
    .signin-from-wrapper .signin-from-inner {
        padding: 100px 0 5px;
    }
    .signin-banner.signup-banner {
        padding: 50px 0;
        height: 300px;
    }
}


/*--------------------------------------------------------------
  ##  Error
  --------------------------------------------------------------*/

.error-page {
    background: #fef9f9;
    padding: 185px 0 165px;
}

.error-content-wrapper img {
    margin-bottom: 30px;
}

.error-content-wrapper .error-title {
    font-size: 40px;
    font-weight: 600;
    line-height: 1.3;
}

.error-content-wrapper p {
    font-size: 20px;
    margin-bottom: 47px;
}

.error-content-wrapper .pix-btn {
    padding: 11px 67px;
}

@media (max-width: 991px) {
    .error-page {
        background: #fef9f9;
        padding: 130px 0 80px;
    }
    .error-content-wrapper .error-title {
        font-size: 35px;
        font-weight: 600;
        line-height: 1.3;
    }
    .error-content-wrapper p {
        font-size: 16px;
    }
}


/*--------------------------------------------------------------
  ##  Footer
  --------------------------------------------------------------*/

#footer {
    background: #fdf8f8;
}

#footer .footer-inner {
    padding: 116px 0 62px;
}

#footer .widget.footer-widget .widget-title {
    color: #2b2350;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 38px;
    border-bottom: 0;
    padding-bottom: 0;
}

#footer .widget.footer-widget p {
    margin-bottom: 40px;
}

#footer .footer-menu {
    margin-bottom: 40px;
    padding: 0;
    list-style: none;
}

#footer .footer-menu li:not(:last-child) {
    margin-bottom: 10px;
}

#footer .footer-menu li a {
    display: block;
    color: #797687;
    font-weight: 400;
    line-height: 30px;
}

#footer .footer-menu li a:hover {
    color: #fa7070;
}

#footer .footer-logo {
    display: block;
    margin-bottom: 30px;
}

#footer .email-info {
    display: block;
    color: #fa7070;
    margin-bottom: 20px;
}

#footer .email-info i {
    margin-right: 10px;
}

#footer .widget-contact .widget-contact-info {
    margin: 0;
    padding: 0;
    list-style: none;
    max-width: 240px;
}

#footer .widget-contact .widget-contact-info li {
    color: #b5b3be;
    font-weight: 300;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
    margin-bottom: 10px;
}

#footer .widget-contact .widget-contact-info li i {
    margin-right: 10px;
    color: #12bf7c;
}

#footer .copyright-text {
    margin-bottom: 50px;
}

#footer .copyright-text a {
    color: #fa7070;
}

#footer .footer-social-link {
    margin: 0;
    padding: 0;
    list-style: none;
}

#footer .footer-social-link li {
    display: inline-block;
}

#footer .footer-social-link li:not(:last-child) {
    margin-right: 10px;
}

#footer .footer-social-link li a {
    height: 45px;
    width: 45px;
    line-height: 45px;
    border: 1px solid #e6dbdb;
    display: block;
    border-radius: 50%;
    color: #9694a1;
    text-align: center;
    font-size: 16px;
}

#footer .footer-social-link li a:hover {
    background: #fa7070;
    border-color: #fa7070;
    color: #fff;
    -webkit-box-shadow: 0px 20px 30px 0px rgba(176, 70, 70, 0.3);
    box-shadow: 0px 20px 30px 0px rgba(176, 70, 70, 0.3);
}

#footer .site-info {
    padding: 36px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-top: 1px solid #e4d9d9;
    position: relative;
    z-index: 3;
}

#footer .site-info p {
    margin: 0;
}

#footer .site-info p a {
    color: #fa7070;
    font-weight: 500;
}

#footer .site-info p a:hover {
    color: #f83f3f;
}

#footer.footer-two {
    background: #f8f7fc;
}

#footer.footer-two .footer-menu li a:hover {
    color: #7052fb;
}

#footer.footer-two .footer-social-link li a:hover {
    background: #7052fb;
    border-color: #7052fb;
    -webkit-box-shadow: 0px 20px 30px 0px rgba(90, 70, 176, 0.3);
    box-shadow: 0px 20px 30px 0px rgba(90, 70, 176, 0.3);
}

#footer.footer-two .site-info {
    border-color: #dad8e3;
}

#footer.footer-two .site-info p a {
    color: #7052fb;
}

#footer.footer-two .site-info p a:hover {
    color: #4720fa;
}

#footer.footer-two .footer-social-link li a {
    border-color: #dad8e3;
}

#footer.footer-two .site-info-menu li a:hover {
    color: #7052fb;
}

#footer.footer-three {
    position: relative;
}

#footer.footer-three .footer-inner {
    position: relative;
    z-index: 3;
}

#footer.footer-three .map-bg {
    text-align: center;
    position: absolute;
    top: 30px;
    left: 0;
    z-index: 0;
    width: 100%;
}

#footer.footer-four .site-info {
    background: #f8f2f2;
    border-top: 1px solid #e4d9d9;
    padding: 25px 0;
}

#footer.footer-four .site-info .site-info-inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

#footer.footer-five {
    background: #000000;
}

#footer.footer-five .widget.footer-widget .widget-title {
    color: #fff;
}

#footer.footer-five .widget p {
    color: #a39eb9;
    font-size: 13px;
}

#footer.footer-five .footer-menu li a {
    color: #a39eb9;
}

#footer.footer-five .footer-menu li a:hover {
    color: #ffffff;
}

#footer.footer-five .footer-social-link li a {
    border-color: #615a7e;
    color: #a39eb9;
}

#footer.footer-five .footer-social-link li a:hover {
    background: #ffffff;
    -webkit-box-shadow: 0px 20px 30px 0px rgba(90, 70, 176, 0.3);
    box-shadow: 0px 20px 30px 0px rgba(90, 70, 176, 0.3);
    border-color: #070707;
    color: rgb(0, 0, 0);
}

#footer.footer-five .site-info-menu li a {
    color: #9c99a7;
}

#footer.footer-five .site-info-menu li a:hover {
    color: #7052fb;
}

#footer.footer-five .site-info {
    /* border-color: #14aeee; */
    border-color: #3d355b;
}

#footer.footer-five .site-info p {
    color: #9c99a7;
}

#footer.footer-five .site-info p a {
    color: #14aeee;
}

#footer.footer-tax-service {
    background: #f2f7fe;
    position: relative;
}

#footer.footer-tax-service .footer-inner {
    padding: 116px 0 175px;
}

#footer.footer-tax-service .email-info {
    color: #f97bc5;
    margin-bottom: 20px;
}

#footer.footer-tax-service .copyright-text a {
    color: #f97bc5;
}

#footer.footer-tax-service .footer-social-link li a {
    border-color: #d6dee9;
    color: #9198a4;
}

#footer.footer-tax-service .footer-social-link li a:hover {
    background: #f97bc5;
    -webkit-box-shadow: 0px 20px 30px 0px rgba(235, 56, 160, 0.3);
    box-shadow: 0px 20px 30px 0px rgba(235, 56, 160, 0.3);
    color: #fff;
}

#footer.footer-tax-service .footer-menu li a {
    color: #ffffff;
}

#footer.footer-tax-service .footer-menu li a:hover {
    color: #ffffff;
}

#footer.footer-tax-service .footer-bottom-element .shape-element {
    margin: 0;
    padding: 0;
    list-style: none;
    width: 100%;
}

#footer.footer-tax-service .footer-bottom-element .shape-element li {
    position: absolute;
    bottom: 0;
}

#footer.footer-tax-service .footer-bottom-element .shape-element li:nth-child(2) {
    bottom: 0;
    left: 70px;
}

#footer.footer-tax-service .footer-bottom-element .shape-element li:nth-child(3) {
    bottom: 0;
    left: 20px;
}

#footer.footer-tax-service .footer-bottom-element .shape-element li:nth-child(4) {
    bottom: 20px;
    left: 40%;
}

#footer.footer-tax-service .footer-bottom-element .shape-element li:nth-child(5) {
    bottom: 0;
    right: 120px;
}

#footer.footer-tax-service .footer-bottom-element .shape-element li:nth-child(6) {
    bottom: 0;
    left: 200px;
}

#footer.footer-job-board {
    background-size: cover;
    background-position: center center;
}

#footer.footer-job-board .footer-inner {
    padding: 115px 0 85px;
}

@media (max-width: 991px) {
    #footer.footer-job-board .footer-inner {
        padding: 75px 0 45px;
    }
}

#footer.footer-job-board .widget.footer-widget .widget-title {
    color: #fff;
}

#footer.footer-job-board .footer-social-link {
    margin-bottom: 22px;
}

#footer.footer-job-board .footer-social-link li a {
    background: rgba(255, 255, 255, 0.078);
    border: 0;
    color: #fff;
}

#footer.footer-job-board .footer-social-link li a:hover {
    background: #fff;
    -webkit-box-shadow: 0px 10px 20px 0px rgba(12, 118, 142, 0.2);
    box-shadow: 0px 10px 20px 0px rgba(12, 118, 142, 0.2);
    color: #fd4d5c;
}

#footer.footer-job-board .footer-menu li:not(:last-child) {
    margin-bottom: 5px;
}

#footer.footer-job-board .footer-menu li a {
    color: #fff;
    font-size: 14px;
    font-weight: 300;
}

#footer.footer-job-board .footer-menu li a:hover {
    color: #fd4d5c;
}

#footer.footer-job-board .copyright-text {
    color: rgba(255, 255, 255, 0.502);
}

#footer.footer-job-board .copyright-text a {
    color: #fd4d5c;
}

#footer.footer-app {
    padding-bottom: 50px;
}

#footer.footer-app .widget.footer-widget .widget-title {
    color: #fff;
}

#footer.footer-app .widget.widget-about {
    max-width: 240px;
}

#footer.footer-app .widget.widget-about p {
    color: #b5b3be;
    margin-bottom: 26px;
}

#footer.footer-app .widget.widget-about .footer-title {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px;
}

#footer.footer-app .footer-menu li:not(:last-child) {
    margin-bottom: 5px;
}

#footer.footer-app .footer-menu li a {
    color: #b5b3be;
}

#footer.footer-app .footer-menu li a:hover {
    color: #12bf7c;
}

#footer.footer-app .site-info {
    display: block;
    border-top-color: #2f3255;
    padding: 25px 0;
}

#footer.footer-app .site-info p {
    color: #9997a2;
}

#footer.footer-app .site-info p a {
    color: #12bf7c;
}

#footer.footer-marketing {
    background: #f8f9fd;
    position: relative;
}

#footer.footer-marketing .footer-inner {
    padding: 116px 0 112px;
    position: relative;
    z-index: 222;
}

#footer.footer-marketing .widget.footer-widget .widget-title {
    color: #1a133b;
}

#footer.footer-marketing .footer-menu li:not(:last-child) {
    margin-bottom: 5px;
}

#footer.footer-marketing .footer-menu li a {
    color: #615f6b;
    font-size: 14px;
}

#footer.footer-marketing .footer-menu li a:hover {
    color: #f98e47;
}

#footer.footer-marketing .footer-bottom-shape {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: 1;
}

#footer.footer-hosting {
    background: #f2f7fe;
    position: relative;
}

#footer.footer-hosting .footer-inner {
    padding: 116px 0 112px;
    position: relative;
    z-index: 222;
}

#footer.footer-hosting .widget.footer-widget .widget-title {
    color: #1a133b;
}

#footer.footer-hosting .footer-menu li:not(:last-child) {
    margin-bottom: 5px;
}

#footer.footer-hosting .footer-menu li a {
    color: #615f6b;
    font-size: 14px;
}

#footer.footer-hosting .footer-menu li a:hover {
    color: #14aeee;
}

#footer.footer-hosting .footer-bottom-shape {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: 1;
}

#footer.footer-travel {
    background: #f9f7fc;
    padding: 135px 0 100px;
}

@media (max-width: 991px) {
    #footer.footer-travel {
        padding: 95px 0 60px;
    }
}

#footer.footer-travel .footer-widget {
    margin-bottom: 30px;
}

#footer.footer-travel .widget-title {
    font-size: 18px;
    font-weight: 600;
    color: #0c082c;
    margin-bottom: 30px;
}

#footer.footer-travel .travel-footer-about-widget {
    margin-bottom: 30px;
}

#footer.footer-travel .travel-footer-about-widget .footer-subtitle {
    font-family: 'Satisfy', cursive;
    font-size: 20px;
    font-weight: 400;
    color: #7052fb;
    margin: 0;
}

#footer.footer-travel .travel-footer-about-widget .footer-title {
    font-size: 40px;
    font-weight: 700;
    color: #0c082c;
    margin: 0;
}

@media (max-width: 1200px) {
    #footer.footer-travel .travel-footer-about-widget .footer-title {
        font-size: 30px;
    }
}

#footer.footer-travel .travel-contact-info {
    margin: 0;
    padding: 0;
    list-style: none;
}

#footer.footer-travel .travel-contact-info li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 15px;
    margin-bottom: 10px;
}

#footer.footer-travel .travel-contact-info li i {
    color: #7052fb;
    margin-right: 10px;
    line-height: 1;
    font-size: 18px;
}

#footer.footer-travel .travel-contact-info li p {
    margin-top: -5px;
    margin-bottom: 0;
}

#footer.footer-travel .travel-footer-menu {
    margin: 0;
    padding: 0;
    list-style: none;
}

#footer.footer-travel .travel-footer-menu li {
    display: block;
    margin-bottom: 3px;
}

#footer.footer-travel .travel-footer-menu li a {
    color: #68666c;
    font-size: 16px;
}

#footer.footer-travel .travel-footer-menu li a:hover {
    color: #7052fb;
}

.site-info-menu {
    margin: 0;
    padding: 0;
    list-style: none;
}

.site-info-menu li {
    display: inline-block;
}

.site-info-menu li a {
    display: inline-block;
    color: #797687;
}

.site-info-menu li a:hover {
    color: #fa7070;
}

@media (max-width: 991px) {
    #footer .footer-inner {
        padding: 76px 0 22px;
    }
    #footer .site-info {
        padding: 21px 0;
    }
    #footer .widget.footer-widget {
        margin-bottom: 30px;
    }
    #footer .widget.footer-widget .widget-title {
        margin-bottom: 25px;
    }
}

@media (max-width: 768px) {
    #footer .footer-menu {
        margin-bottom: 40px;
    }
    #footer .site-info {
        padding: 21px 0;
        display: block;
        text-align: center;
    }
    #footer.footer-four .copyright {
        margin-bottom: 20px;
    }
    #footer.footer-four .site-info .site-info-inner {
        display: block;
    }
}


/* ITInfrastructure css */

.icon-container {
    width: 50px;
    height: 50px;
    background-color: #e6e6ff;
    border-radius: 50%;
    
  }
  div p{
    font-size: 14px;
  }

  .description-container {
   border-left: 2px solid #5a5a9d;
    padding-left: 8px;
    gap: 20px;
    width: 100%;
    height:65px;
    display: flex;
    flex-direction: column;
  }

  .description-container-two {
   border-left: 2px solid #5a5a9d;
    padding-left: 8px;
    gap: 20px;
    width: 100%;
    height:65px;
    display: flex;
    flex-direction: column;
  }
  .description-container-three{
    border-left: 2px solid #5a5a9d;
    padding-left: 8px;
    gap: 20px;
    width: 100%;
    height:65px;
    display: flex;
    flex-direction: column;
  }

 
  .description-item-marker {
  left: -16px;
  top: 0;
  width: 16px;
  height: 16px;
  background-color: #ffff;
  border-radius: 50%;
  border: 2px solid #5a5a9d;
  }

   @media (max-width: 1192px) {
    .description-container {
  
    height:70px;
    
  }
  .description-container-three{
   
    height:65px;
    
  }
  }

  @media (max-width: 992px) {
    .description-container {
  
    height:75px;
    
  }
  .description-container-two{
    height: 75px;
  }
  .description-container-three{
   
    height:60px;
    
  }
  div p{
    font-size: 12px;
  }
  }


  @media (max-width: 768px) {
    .description-container,
    .description-container-two {
      gap: 15px;
      height: 75px;
    }
    .icon-container {
      width: 40px;
      height: 40px;
    }
    h3.fs-5 {
      font-size: 1.2rem;
    }
   
  } 
  @media (max-width: 760px) {
    .description-container,.description-container-two {
  
    height:60px;
    
  }
  }
 

  @media (max-width: 409px) {
    .description-container-two {
  
    height:68px;
    
  }
  .description-container {
    height: 85px;  
  }
  .description-container div p{
    font-size: 12px;
  }
  }

  @media (max-width:363px) {
    .description-container-three{
        height: 75px;

    }
    
  }

  @media (max-width:320px) {
    .description-container-two div p{
        font-size: 10px !important;
      

    }
    
  }

  @media (max-width: 765px){
    .marginAdj {
        margin: auto;
    }
    .PowerCoolingPic{
        display: block;
    }
  }
.PowerCoolingPic{
    display: hidden;
}