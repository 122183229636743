$white : #ffffff;
$mid-white : #fafafa;
$gray-white : #eceff1;
$blueish-white : #e3f2fd;

$dark : #212121;
$light-dark : #333333;
$deep-dark: #000000;

$blue : #2196f3;
$light-blue: #64b5f6;
$dark-blue : #1e88e5;